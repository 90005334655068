import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import DailyDetailTableOne from "./DailyDetailTableOne";
import DailyDetailTableTwo from "./DailyDetailTableTwo";
import { RouterString } from "../../../global/common/constants";
import NumberFormat from "react-number-format";
import { accountingOverviewDataAPI } from "../../../network/api";
import { useNavigate } from "react-router-dom";
import { localConstants, Error } from "../../../global/common/constants";
import { useMounted } from "../../../global/function/useMounted";
import BaseCircularProgress from "../../../global/component/basic/BaseCircularProgress";

// 画面レイアウトスタイル
const styles = (theme) => ({
  contentFont: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#002239",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
  },
  bottomButton: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "700",
    height: "48px",
    marginBottom: "20px",
  },
});

//　画面入り口
function DailyDetail(props) {
  const { classes, setSelectedTab,
    accountingData,
    setAccountingData } = props;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMounted();
  // 画面初期化データ取得
  useEffect(() => {
    if (!accountingData?.storeId) {
      window.history.back()
      return
    }
    if (!accountingData.nyukinki) {
      getAccountingOverviewData()
    }
  }, []);
  // 概要データ取得
  const getAccountingOverviewData = () => {
    setIsLoading(true)
    // 概要データ取得APIを呼び出す
    let param_overview = {
      businessDay: localStorage.getItem(localConstants.BusinessDay),
      storeId: accountingData?.storeId
    }
    accountingOverviewDataAPI(param_overview).then((res) => {
      if (!isMounted()) return
      let resData = res.data
      if (res.status >= 400 && res.status < 500) {
        alert(Error.E0003);
      } else if (res.status === 200) {
        setAccountingData({
          ...accountingData,
          nyukinki: resData?.nyukinki,
          pos: resData?.pos,
          errMsg: resData?.errMsg
        })
      }
      setIsLoading(false)
    }).catch((err) => {
      setIsLoading(false)
      if (!isMounted()) return
    })
  }

  function toCompare() {
    window.history.back()
  }
  function getSumBusinessDayCash() {
    var sumBusinessDayCash = 0
    if (accountingData.nyukinki?.overviewData != null) {
      if (accountingData.nyukinki.overviewData.overViewTableOne != null) {
        sumBusinessDayCash += Number(accountingData.nyukinki.overviewData.overViewTableOne.principalChange)
      }
      for (var idx = 0; idx < accountingData.nyukinki.overviewData.overViewTableTwo.length; idx++) {
        if (accountingData.nyukinki.overviewData.overViewTableTwo[idx].cashTotal != null) {
          sumBusinessDayCash += Number(accountingData.nyukinki.overviewData.overViewTableTwo[idx].cashTotal)
        }
        // if (accountingData.nyukinki.overviewData.overViewTableTwo[idx].kabusoku != null) {
        //   sumBusinessDayCash += Number(accountingData.nyukinki.overviewData.overViewTableTwo[idx].kabusoku)
        // }
      }
      sumBusinessDayCash -= Number(accountingData.nyukinki.overviewData.originalAmountOfMoney)
      const overviewData = accountingData.nyukinki.overviewData
      overviewData.overViewTableOne.businessDayCash = sumBusinessDayCash
      if (sumBusinessDayCash != accountingData.nyukinki.overviewData.overViewTableOne.businessDayCash) {
        setAccountingData({ ...accountingData, nyukinki: { ...accountingData.nyukinki, overviewData: overviewData } })
      }
    }
    return sumBusinessDayCash
  }

  function getDepositAmount() {
    var sumDepositAmount = 0
    if (accountingData.nyukinki.overviewData != null) {
      var tmpBusinessDayCash = getSumBusinessDayCash()
      sumDepositAmount = tmpBusinessDayCash + Number(accountingData.nyukinki.overviewData.overViewTableOne.cashAdjustment)
      // for (var idx = 0; idx < accountingData.nyukinki.overviewData.overViewTableTwo.length; idx++) {
      //   if (accountingData.nyukinki.overviewData.overViewTableTwo[idx].kabusoku != null) {
      //     sumDepositAmount += Number(accountingData.nyukinki.overviewData.overViewTableTwo[idx].kabusoku)
      //   }
      // }
      const overviewData = accountingData.nyukinki.overviewData
      overviewData.overViewTableOne.depositAmount = sumDepositAmount
      if (sumDepositAmount != accountingData.nyukinki.overviewData.overViewTableOne.depositAmount) {
        setAccountingData({ ...accountingData, nyukinki: { ...accountingData.nyukinki, overviewData: overviewData } })
      }
    }
    return sumDepositAmount
  }
  return (
    <Box>
      {isLoading && <BaseCircularProgress />}
      <div
        style={{
          height: "5%",
          marginLeft: "2.308%",
          marginTop: "2.308%",
          fontFamily: "Noto Sans JP",
          fontStyle: "Bold",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "150%",
          color: "#162337",
        }}
      >
        {accountingData?.storeName}の日報内訳
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            width: "23.077%",
            height: "3.571%",
            marginLeft: "2.308%",
          }}
        >
          <div>
            <Typography className={classes.contentFont}>営業日現金</Typography>
          </div>
          <div>
            <Typography
              className={classes.contentFont}
              style={{
                fontFamily: "Noto Sans JP",
                fontSize: "28px",
                lineHeight: "100%",
                borderBottom: "1px solid #CCCCCC",
                paddingBottom: "3px",
                textAlign: "right",
              }}
            >
              <NumberFormat
                value={
                  getSumBusinessDayCash()
                }
                displayType={"text"}
                thousandSeparator={true}
              />
              <span style={{ fontSize: "16px" }}>{`\u00A0\u00A0円`}</span>
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: "23.077%",
            height: "3.571%",
            marginLeft: "2.308%",
          }}
        >
          <div>
            <Typography className={classes.contentFont}>入金額</Typography>
          </div>
          <div>
            <Typography
              className={classes.contentFont}
              style={{
                fontFamily: "Noto Sans JP",
                fontSize: "28px",
                lineHeight: "100%",
                borderBottom: "1px solid #CCCCCC",
                paddingBottom: "3px",
                textAlign: "right",
              }}
            >
              <NumberFormat
                value={
                  accountingData.nyukinki?.overviewData != null
                    ? getDepositAmount()
                    : 0
                }
                displayType={"text"}
                thousandSeparator={true}
              />
              <span style={{ fontSize: "16px" }}>{`\u00A0\u00A0円`}</span>
            </Typography>
          </div>
        </div>
      </div>

      {/*  入金調整額テーブル１ */}
      {accountingData.nyukinki?.overviewData && (
        <div
          style={{
            width: "95.385%",
            height: "20.238%",
            marginLeft: "2.308%",
            marginTop: "24px",
          }}
        >
          <DailyDetailTableOne
            accountingData={
              accountingData
            }
            setAccountingData={setAccountingData}

          ></DailyDetailTableOne>
        </div>
      )}

      {/*  入金調整額テーブル2 */}
      {accountingData.nyukinki?.overviewData && (
        <div style={{ marginLeft: "2.308%", marginTop: "2.308%" }}>
          <DailyDetailTableTwo
            editBtnFlg={false}
            accountingData={
              accountingData
            }
            setAccountingData={setAccountingData}
          ></DailyDetailTableTwo>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
        <Button
          variant="outlined"
          onClick={toCompare}
          color="orange"
          className={classes.bottomButton}
        >
          貸借の比較へ戻る
        </Button>
      </div>
    </Box>
  );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(DailyDetail);
