import React, { useEffect, useState } from "react";
import { Box, Typography, Alert } from "@mui/material";
import { withStyles } from "@mui/styles";
import { RouterString } from "../../../global/common/constants";
import NumberFormat from "react-number-format";
import TenantCreditTableOne from "./TenantCreditTableOne";
import TenantCreditTableTwo from "./TenantCreditTableTwo";
import { useNavigate } from "react-router-dom";
import { getTenantcreditMasterAPI } from "../../../network/api";
import { useMounted } from "../../../global/function/useMounted";
import BaseCircularProgress from "../../../global/component/basic/BaseCircularProgress";
import { Error } from "../../../global/common/constants";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// 画面レイアウトスタイル
const styles = (theme) => ({
  contentFont: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#002239",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
  },
  alert: {
    backgroundColor: "#FACCD3",
    color: "#E60024",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "16px",
    marginLeft: "2.308%",
    marginTop: "2.308%",
  },
});

//　画面入り口
function TenantCredit(props) {
  const { classes, setSelectedTab, storeData, setStoreData, submitDisabled, setSubmitDisabled } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [tenantCreditMaster, setTenantCreditMaster] = useState([]);
  const isMounted = useMounted()
  const [brandError, setBrandError] = useState(false);
  const [compareE0041Flg, setCompareE0041Flg] = useState(true);
  const [eMsg, setEMsg] = useState("");

  const navigate = useNavigate();
  const getTenantCreditMasterData = () => {
    setIsLoading(true);
    getTenantcreditMasterAPI().then((res) => {
      if (!isMounted()) return
      let resData = res.data
      if (res.status >= 400 && res.status < 500) {
        alert(Error.E0003);
      } else if (res.status == 200) {
        setTenantCreditMaster(resData)
      }
      setIsLoading(false)
    }).catch((err) => {
      if (!isMounted()) return
      setIsLoading(false)
    })

  }
  // 画面初期化データ取得
  useEffect(() => {
    getTenantCreditMasterData()
    if (!storeData.nyukinki?.reportData) {
      navigate(RouterString.Report)
    }
    setSelectedTab(RouterString.TenantCredit);
  }, [setSelectedTab]);

  useEffect(() => {
    setCompareE0041Flg(true)
    setBrandError(false)
    storeData.nyukinki?.overviewData?.overViewTableTwo?.forEach((tmp, index) => {
      if (tmp.creditCard.totalSettlementAmount !== "0" && tmp.creditCard.totalSettlementAmount !== null && tmp.creditCard.totalSettlementAmount !== "") {
        if (!tmp.creditCard?.rowsData) {
          setCompareE0041Flg(false)
        } else {
          let sumSettlementAmount = 0
          tmp.creditCard?.rowsData?.forEach((rTmp, rIndex) => {
            if (rTmp?.tradingHour == null || rTmp?.settlementAmount == null || rTmp?.settlementAmount == "") {

            } else {
              sumSettlementAmount += Number(rTmp.settlementAmount)
            }
            if (rTmp?.leaseCategory == null || rTmp.leaseCategory == "") {
              setBrandError(true)
            }
          })
          if (sumSettlementAmount != Number(tmp.creditCard.totalSettlementAmount)) {
            setCompareE0041Flg(false)
          }
        }
      }
    })
  }, [storeData]);

  useEffect(() => {
    if (brandError && !compareE0041Flg) {
      setEMsg((Error.E0040 + '\n' + Error.E0041))
    }
    else if (!compareE0041Flg) {
      setEMsg(Error.E0041)
    }
    else if (brandError) {
      setEMsg(Error.E0040)
    }
    else {
      setEMsg("")
    }
  }, [brandError, compareE0041Flg]);
  return (
    <Box>
      {isLoading && <BaseCircularProgress />}
      <div
        style={{
          height: "5%",
          marginLeft: "2.308%",
          marginTop: "2.308%",
          fontFamily: "Noto Sans JP",
          fontStyle: "Bold",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "150%",
          color: "#162337",
        }}
      >
        テナントクレジット
      </div>
      <Alert severity="error" className={classes.alert} style={{ whiteSpace: 'pre-wrap', display: eMsg != "" ? "" : "none" }}>
        {eMsg}
      </Alert>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            width: "23.077%",
            height: "3.571%",
            marginLeft: "2.308%",
          }}
        >
          <div>
            <Typography className={classes.contentFont}>営業日現金</Typography>
          </div>
          <div>
            <Typography
              className={classes.contentFont}
              style={{
                fontFamily: "Noto Sans JP",
                fontSize: "28px",
                lineHeight: "100%",
                borderBottom: "1px solid #CCCCCC",
                paddingBottom: "3px",
                textAlign: "right",
              }}
            >
              <NumberFormat
                value={
                  storeData.nyukinki?.overviewData ? storeData.nyukinki?.overviewData.overViewTableOne.businessDayCash : 0
                }
                displayType={"text"}
                thousandSeparator={true}
              />
              <span style={{ fontSize: "16px" }}>{`\u00A0\u00A0円`}</span>
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: "23.077%",
            height: "3.571%",
            marginLeft: "2.308%",
          }}
        >
          <div>
            <Typography className={classes.contentFont}>入金額</Typography>
          </div>
          <div>
            <Typography
              className={classes.contentFont}
              style={{
                fontFamily: "Noto Sans JP",
                fontSize: "28px",
                lineHeight: "100%",
                borderBottom: "1px solid #CCCCCC",
                paddingBottom: "3px",
                textAlign: "right",
              }}
            >
              <NumberFormat
                value={
                  storeData.nyukinki?.overviewData ? storeData.nyukinki?.overviewData.overViewTableOne.depositAmount : 0
                }
                displayType={"text"}
                thousandSeparator={true}
              />
              <span style={{ fontSize: "16px" }}>{`\u00A0\u00A0円`}</span>
            </Typography>
          </div>
        </div>
      </div>

      {/*  入金調整額テーブル１ */}
      {storeData.nyukinki?.overviewData?.overViewTableOne && (
        <div
          style={{
            width: "95.385%",
            height: "20.238%",
            marginLeft: "2.308%",
            marginTop: "24px",
          }}
        >
          <TenantCreditTableOne
            storeData={storeData}
          />
        </div>
      )}

      {/*  入金調整額テーブル2 */}
      {storeData.nyukinki?.overviewData?.overViewTableTwo && (
        <div style={{ marginLeft: "2.308%", marginTop: "2.308%" }}>
          <TenantCreditTableTwo
            storeData={storeData}
            setStoreData={setStoreData}
            tenantCreditMaster={tenantCreditMaster}
            submitDisabled={submitDisabled}
            setSubmitDisabled={setSubmitDisabled} />
        </div>
      )}
    </Box>
  );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(TenantCredit);
