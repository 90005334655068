import React, { useEffect, useState, Fragment } from "react";
import {
    Box, Typography, Button
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { withStyles } from "@mui/styles";
import { RouterString, DialogType } from "../../../global/common/constants";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import moment from "moment";
import locale from "antd/es/date-picker/locale/ja_JP";
import usePagination from "./Pagniation";
import { useNavigate } from "react-router-dom";
import { Error, localConstants } from "../../../global/common/constants";
import { DatePicker, Modal } from "antd";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { getReportAPI, getOverviewAPI } from "../../../network/api"
import { useMounted } from "../../../global/function/useMounted";
import { objectToString, stringToObejct } from "../../../global/function/JsonUtil"
import BaseCircularProgress from "../../../global/component/basic/BaseCircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// 画面スタイル
const styles = (theme) => ({
    title: {
        textAlign: "center",
        fontFamily: "Noto Sans",
        color: "#162337",
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "700",
    },
    dialogPaper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: 640,
        marginTop: 88,
    },
    dialogContent: {
        textAlign: "center",
        fontFamily: "Noto Sans",
        color: "#162337",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400",
    },
    dateContent: {
        height: '70.176%',
        textAlign: "left",
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '16px',
        color: '#162337',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%'
    },
    dailyConfirm: {

        width: '100%',
        paddingTop: '8px', paddingBottom: '8px',
        border: '1px solid #EF7A00',
        color: '#EF7A00',
        fontFamily: 'Noto Sans',
        fontStyle: 'regular',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '100%'
    },
    dailyUpdate: {
        width: '100%',
        paddingTop: '8px', paddingBottom: '8px',
        border: '1px solid #162337',
        backgroundColor: '#162337',
        color: '#FFFFFF',
        fontFamily: 'Noto Sans',
        fontStyle: 'regular',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '100%',
        "&:enabled:hover": {
            backgroundColor: "#162337",
            color: "#fff",
        },
    },
    dailyCommit: {

        width: '100%',
        paddingTop: '8px', paddingBottom: '8px',
        border: '1px solid #EF7A00',
        backgroundColor: '#EF7A00',
        color: '#FFFFFF',
        fontFamily: 'Noto Sans',
        fontStyle: 'regular',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '100%',
        "&:enabled:hover": {
            backgroundColor: "#EF7A00",
            color: "#fff",
        },
    },
    ul: {
        '& .Mui-selected': {
            color: '#162337',
        },
    }
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#162337',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '16px',
        borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'Bold', fontSize: '16px', fontWeight: '400', lineHeight: '150%', paddingTop: '12px', paddingBottom: '12px', paddingLeft: '8px', paddingRight: '8px', width: '16.230%'

    },
}));

// 日報画面入り口
function Report(props) {
    const {
        classes,
        setSelectedTab,
        storeData,
        setStoreData,
        setIsCircularLoading
    } = props
    let [page, setPage] = useState(1);
    const PER_PAGE = 7;
    const [isLoading, setIsLoading] = useState(false);
    const [reportData, setReportData] = useState();
    const [count, setCount] = useState(0);
    const paginData = usePagination(reportData ?? [], PER_PAGE);
    const navigate = useNavigate()
    const isMounted = useMounted()
    const [errorDialogVisable, setErrorDialogVisable] = useState(false);
    const [errorMessage, setErrorMessage] = useState(Error.E0003);

    const handleChange = (e, p) => {
        setPage(p);
        paginData.jump(p);
    };
    // 概要データ取得APIを呼び出す
    const jumpToOverView = (v) => {
        // if (v.approvalStatus == "0") { // rujinji
        //     console.log("rujinji")
        //     getOverViewData(v.approvalStatus, v.businessDay, v.errMsg)
        // } else {
        if (localStorage.getItem(v.businessDay + "-storeId=" + localStorage.getItem(localConstants.StoreId))) { //  local
            console.log("local")
            let obj = stringToObejct(localStorage.getItem(v.businessDay + "-storeId=" + localStorage.getItem(localConstants.StoreId)))
            setStoreData({
                ...obj, nyukinki: {
                    ...obj.nyukinki,
                    reportData: { businessDay: v.businessDay, errMsg: v.errMsg, approvalStatus: v.approvalStatus }
                }
            })
            navigate(RouterString.Overview)
        } else {
            getOverViewData(v.approvalStatus, v.businessDay, v.errMsg)

        }
        // }

    };
    const getOverViewData = (approvalStatus, businessDay, errMsg) => {
        setIsCircularLoading(true);
        // 概要データ取得APIを呼び出す
        let param = {
            businessDay: businessDay,
            approvalStatus: approvalStatus,
            storeId: localStorage.getItem(localConstants.StoreId),
        }
        getOverviewAPI(param).then((res) => {
            if (!isMounted()) return
            let resData = res.data
            if (res.status >= 400 && res.status < 500) {
                // if (res.status == 404) {
                //     setErrorMessage(Error.E0015)
                //     setErrorDialogVisable(true)
                // } else {
                //     setErrorMessage(Error.E0003)
                //     setErrorDialogVisable(true)
                // }
                setErrorMessage(resData.detail)
                setErrorDialogVisable(true)
            } else if (res.status == 200) {
                setStoreData({
                    ...storeData,
                    nyukinki: {
                        overviewData: resData?.nyukinki?.overviewData,
                        reportData: { businessDay: businessDay, errMsg: errMsg, approvalStatus: approvalStatus }
                    },
                    editPart: {
                        overviewData: JSON.parse(JSON.stringify(resData?.nyukinki?.overviewData))
                    },
                    pos: resData?.pos

                })
                navigate(RouterString.Overview)
            }
            setIsCircularLoading(false)
        }).catch((err) => {
            setIsCircularLoading(false)
            if (!isMounted()) return
        })

    };
    const handleSearchByDate = (date) => {
        let tmpWeek = 0
        var dateFormat = moment(new Date(date)).format("YYYY-MM-DD")
        for (let index = 0; index < reportData?.length; index += 1) {
            if (reportData[index]?.businessDay === dateFormat) {
                tmpWeek = parseInt(index / 7) + 1;
                break
            }
        }
        setPage(tmpWeek);
        paginData.jump(tmpWeek);
    };

    const disabledDate = (current) => {
        // Can not select somedays
        if (reportData?.length > 0) {
            return current && (current > new Date(new Date().getTime() - 24 * 60 * 60 * 1000) || current < new Date(reportData[reportData?.length - 1]?.businessDay))
        } else {
            return current
        }
    };
    const getReport = () => {
        setIsLoading(true);
        let param = {
            storeId: localStorage.getItem(localConstants.StoreId),
        }
        console.log(param)
        getReportAPI(param).then((res) => {
            if (!isMounted()) return
            console.log(res)
            let resData = res.data
            if (res.status >= 400 && res.status < 500) {
                alert(resData.detail);
            } else if (res.status == 200) {
                setReportData(resData)
                setCount(Math.ceil(resData.length / PER_PAGE))
            }
            setIsLoading(false)
        }).catch((err) => {
            if (!isMounted()) return
            setIsLoading(false)
        })

    }
    const closeAlertDialog = () => {
        setErrorDialogVisable(false)
    }
    useEffect(() => {
        setSelectedTab(RouterString.Report)
        if (localStorage.getItem(localConstants.StoreId)) {
            getReport()
        }

    }, [setSelectedTab, storeData, localStorage.getItem(localConstants.StoreId)])
    return (
        <div>
            {isLoading && <BaseCircularProgress />}
            <Dialog
                open={errorDialogVisable}
                scroll="paper"
                classes={{
                    paper: classes.dialogPaper,
                }}
            >
                <DialogTitle>
                    <div className={classes.title}>{errorMessage}</div>
                    <IconButton
                        aria-label="close"
                        onClick={closeAlertDialog}
                        size="large"
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={closeAlertDialog}
                        color="orange"
                        style={{
                            fontSize: 14,
                            width: "112px",
                            marginRight: "15px",
                        }}
                    >
                        キャンセル
                    </Button>
                </DialogActions>
                <div
                    style={{
                        height: "8px",
                    }}
                >
                </div>
            </Dialog>
            <div style={{ display: "flex", marginLeft: '2.308%', marginRight: '24px', marginTop: '2.308%' }}>

                <Typography style={{
                    width: '9.980%',
                    paddingTop: '0.5%',
                    fontFamily: 'Noto Sans', fontStyle: 'Bold', color: "#162337", fontWeight: '700', lineHeight: '150%',
                    fontSize: '24px'
                }} >
                    日報一覧
                </Typography>

                <Typography style={{
                    float: 'left',
                    paddingLeft: '69.255%',
                    paddingTop: '1.279%',
                    paddingBottom: '1.279%',
                    width: '72.147%',
                    fontFamily: 'Noto Sans', fontStyle: 'Regular', color: "#162337", fontWeight: '400', lineHeight: '100%',
                    fontSize: '14px'
                }} >
                    移動
                </Typography>
                <DatePicker
                    allowClear={false}
                    showToday={false}
                    locale={locale}
                    suffixIcon={<CalendarTodayIcon />}
                    inputReadOnly
                    format="YYYY/MM/DD"
                    disabledDate={disabledDate}
                    style={{
                        marginLeft: '0.813%',
                        backgroundColor: '#FFFFFF',
                        height: '40px',
                        fontFamily: 'Noto Sans',
                        fontStyle: 'normal',
                        fontSize: '16px',
                        fontWeight: '400',
                        lineHeight: '150%',
                        width: "17.503%"
                    }}
                    onChange={(value) => {
                        handleSearchByDate(moment(value).format("YYYY/MM/DD"))
                    }}
                    size="large"
                    placeholder="営業日"
                />
            </div>
            <div style={{ marginLeft: '2.308%', marginRight: '2.308%', marginTop: '1.828%' }}>
                <TableContainer component={Paper} style={{ borderRadius: '0px' }}>
                    <Table aria-label="" style={{ borderRadius: '0px' }}>
                        <TableHead >
                            <TableRow>
                                <StyledTableCell align='center' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'Bold', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '55px', paddingRight: '55px', width: '16.138%' }}>
                                    営業日
                                </StyledTableCell>
                                <StyledTableCell align='center' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'Bold', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '38px', paddingRight: '38px', width: '16.138%' }}>
                                    ステータス
                                </StyledTableCell>
                                <StyledTableCell align='center' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'Bold', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', paddingTop: '8px', paddingBottom: '8px', paddingLeft: '197px', paddingRight: '197px', width: '45.184%' }}>
                                    エラー
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ fontFamily: 'Noto Sans', fontStyle: 'Bold', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', width: '22.592%' }}>
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginData.currentData().map((v, index) => {
                                return (
                                    <TableRow key={index}>
                                        <StyledTableCell align='left' >
                                            {moment(v.businessDay).format("YYYY/MM/DD")}
                                        </StyledTableCell>
                                        {v.approvalStatus === "0" ? <StyledTableCell align='left' style={{ color: "#E60024", }}>
                                            未提出
                                        </StyledTableCell> : v.approvalStatus === "1" ?
                                            <StyledTableCell align='left' style={{ color: "#E60024", }}>
                                                未承認
                                            </StyledTableCell> : <StyledTableCell align='left' style={{ color: "#162337" }}>
                                                承認済
                                            </StyledTableCell>}
                                        <StyledTableCell key={index} align='left' style={{ color: '#E60024' }}>
                                            {v.errMsg?.length > 0 && v.errMsg.map((eMsg, eIndex) => (
                                                <div key={eIndex}>{eMsg}</div>
                                            ))}
                                        </StyledTableCell>

                                        {v.approvalStatus === "0" ?
                                            <StyledTableCell align="center" style={{ paddingLeft: '40px', paddingRight: '40px', paddingTop: '7px', paddingBottom: '7px' }} >
                                                <Button className={classes.dailyCommit} onClick={() => {
                                                    jumpToOverView(v)
                                                }} >
                                                    日報を作成する
                                                </Button>
                                            </StyledTableCell> : v.approvalStatus === "1" ? <StyledTableCell align="center" style={{ paddingLeft: '40px', paddingRight: '40px', paddingTop: '7px', paddingBottom: '7px' }} >
                                                <Button className={classes.dailyUpdate} onClick={() => {
                                                    jumpToOverView(v)
                                                }} >
                                                    日報を修正する
                                                </Button>
                                            </StyledTableCell> :
                                                <StyledTableCell align="center" style={{ paddingLeft: '40px', paddingRight: '40px', paddingTop: '7px', paddingBottom: '7px' }} >
                                                    <Button className={classes.dailyConfirm} onClick={() => {
                                                        jumpToOverView(v)
                                                    }} >
                                                        日報を確認する
                                                    </Button>
                                                </StyledTableCell>
                                        }
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <Pagination
                    count={count}
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handleChange}
                    color="orange"
                    classes={{ ul: classes.ul }}
                    style={{ marginTop: '2.597%' }}
                />
            </div>
        </div >

    )
}


Report.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Report);