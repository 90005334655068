import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import { withStyles } from "@mui/styles";
import NumberFormat from "react-number-format";
import NumberFormatCustom from "../../../global/component/basic/NumberFormatCustom";

import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import BasicTextfield from "../../../global/component/basic/BasicTextfield";
import { set } from "date-fns";


// 画面スタイル
const styles = (theme) => ({
    tableTwoInput: {
        padding: '8px',
        textAlign: "center",
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        color: "#162337",
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '150%',

    },
    inputContent: {
        padding: '8px',
        textAlign: "right",
        color: '#162337',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        ineHeight: '100%'
    },
    inputContentEx: {
        padding: '8px',
        textAlign: "right",
        color: 'red',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        ineHeight: '100%'
    },
    negativeNumber: {
        borderRight: '1px solid #CCCCCC',
        textAlign: "right",
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingRight: '8px',
        color: 'red',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        ineHeight: '150%'
    },
    positiveNumber: {
        borderRight: '1px solid #CCCCCC',
        textAlign: "right",
        paddingTop: '8px',
        paddingRight: '8px',
        paddingBottom: '8px',
        color: '#162337',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        ineHeight: '150%'
    },
    ul: {
        '& .Mui-selected': {
            color: '#162337',
        },
    }
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#162337',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        // borderLeft: '1px solid #CCCCCC',
        fontSize: '16px',
        backgroundColor: '#FFFFFF',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },


}));

//　画面入り口
function OverviewTableTwo(props) {

    const { classes, editBtnFlg, setRejiErrFlg, storeData, setStoreData, submitDisabled, setSubmitDisabled, setIsCircularLoading, isRefreshData } = props;
    const [tmpBtnFlg, setTmpBtnFlg] = useState(0);

    useEffect(() => {
        setCount(Math.ceil(storeData.nyukinki.overviewData.overViewTableTwo.length / 10));
        setEditCount(Math.ceil(storeData.editPart.overviewData.overViewTableTwo.length / 10));
        setPage(1)
        setEditPage(1)
    }, [editBtnFlg]);

    useEffect(() => {
        setPage(1)
        setEditPage(1)
    }, [isRefreshData]);

    useEffect(() => {
        // 店舗で「iD」と「QUICPay」を合算して、「QP / iD」に登録する
        setCount(Math.ceil(storeData.nyukinki.overviewData.overViewTableTwo.length / 10));
        setEditCount(Math.ceil(storeData.editPart.overviewData.overViewTableTwo.length / 10));
    }, [storeData]);

    const addTableTwoData = () => {
        setIsCircularLoading(true)
        var tmpArr = []
        var tmpMap = {}
        for (var i = 0; i < storeData.editPart.overviewData.overViewTableTwoSubHeader.length; i++) {
            tmpMap = {
                "key": storeData.editPart.overviewData.overViewTableTwoSubHeader[i],
                "value": null
            }
            tmpArr.push(tmpMap)
        }
        var tmpArrIC = []
        var tmpMapIC = {}
        for (var i = 0; i < storeData?.editPart?.overviewData?.incomeHeader?.length; i++) {
            tmpMapIC = {
                "key": storeData.editPart.overviewData.incomeHeader,
                "value": null
            }
            tmpArrIC.push(tmpMapIC)

        }
        var tmpArrKR = []
        // var tmpMapKR = {}

        // tmpMapKR = {
        //     "key": "小口立替金",
        //     "value": null
        // }
        // tmpArrKR.push(tmpMapKR)

        const overviewData = storeData.editPart?.overviewData
        overviewData.overViewTableTwo.push({
            rejino: null,
            rejinoFlg: "1",
            cashTotal: "0",
            kabusoku: "0",
            genkingai: [
                {
                    "goukei": "0",
                    "meisai": tmpArr
                }],
            creditCard: {
                "totalSettlementAmount": 0,
                "totalSettlementAmountEntered": 0,
                "rowsData": []
            },
            offPayment: {
                "totalSettlementAmount": 0,
                "totalSettlementAmountEntered": 0,
                "rowsData": []
            },
            income: [
                {
                    "goukei": "0",
                    "meisai": tmpArrIC

                }],
            koguchiReorient: [
                {
                    "goukei": "0",
                    "meisai": tmpArrKR

                }]
        })
        setStoreData({ ...storeData, editPart: { ...storeData.editPart, overviewData: overviewData } })
        setEditCount(Math.ceil(storeData.editPart.overviewData.overViewTableTwo.length / 10))
        setIsCircularLoading(false)
        setSubmitDisabled(true)
        setEditPage(Math.ceil(storeData.editPart.overviewData.overViewTableTwo.length / 10))
    }

    const handleRejinoChange = (value, index) => {
        // const overviewData = storeData.nyukinki.overviewData
        const overviewData = storeData.editPart.overviewData
        if (value != null) {
            overviewData.overViewTableTwo[index].rejino = value;
        }
        // for (var idx = 0; idx < overviewData?.overViewTableTwo?.length; idx++) {
        //     if (parseInt(overviewData.overViewTableTwo[idx].rejino) == parseInt(value)) {
        //         setRejiErrFlg(true)
        //     }
        // }
        setStoreData({ ...storeData, editPart: { ...storeData.editPart, overviewData: overviewData } })

        setSubmitDisabled(true)
    }

    const handleCashTotalChange = (value, index) => {
        const overviewData = storeData.editPart.overviewData
        overviewData.overViewTableTwo[index].cashTotal = value;
        dataSumRow[0].sumcashTotal = '0'
        // for (var idx = 0; idx < overviewData.overViewTableTwo.length; idx++) {
        //     dataSumRow[0].sumcashTotal = (Number(dataSumRow[0].sumcashTotal) + Number(overviewData.overViewTableTwo[idx].cashTotal)).toString()
        // }
        setStoreData({ ...storeData, editPart: { ...storeData.editPart, overviewData: overviewData } })
        setSubmitDisabled(true)
    }

    const handleKabusokuChange = (value, index) => {
        const overviewData = storeData.editPart.overviewData
        overviewData.overViewTableTwo[index].kabusoku = value;
        dataSumRow[0].sumkabusoku = '0'
        // for (var idx = 0; idx < overviewData.overViewTableTwo.length; idx++) {
        //     dataSumRow[0].sumkabusoku = (Number(dataSumRow[0].sumkabusoku) + Number(overviewData.overViewTableTwo[idx].kabusoku)).toString()
        // }
        setStoreData({ ...storeData, editPart: { ...storeData.editPart, overviewData: overviewData } })
        setSubmitDisabled(true)
    }

    const handleDetailChange = (value, index, yIndex) => {
        const overviewData = storeData.editPart.overviewData
        overviewData.overViewTableTwo[index].genkingai[0].goukei = (Number(overviewData.overViewTableTwo[index].genkingai[0].goukei) - Number(overviewData.overViewTableTwo[index].genkingai[0].meisai[yIndex].value)).toString();
        overviewData.overViewTableTwo[index].genkingai[0].meisai[yIndex].value = value;
        overviewData.overViewTableTwo[index].genkingai[0].goukei = (Number(overviewData.overViewTableTwo[index].genkingai[0].goukei) + Number(value)).toString();

        // for (var idx = 0; idx < dataSumRow[0].summeisai.length; idx++) {
        //     dataSumRow[0].summeisai[idx].value = '0'
        //     for (var yIdx = 0; yIdx < overviewData.overViewTableTwo.length; yIdx++) {
        //         dataSumRow[0].summeisai[idx].value = (Number(dataSumRow[0].summeisai[idx].value) + Number(overviewData.overViewTableTwo[yIdx].genkingai[0].meisai[idx].value)).toString()
        //     }
        // }
        // dataSumRow[0].sumgoukei = '0'
        // for (var zIdx = 0; zIdx < overviewData.overViewTableTwo.length; zIdx++) {
        //     dataSumRow[0].sumgoukei = (Number(dataSumRow[0].sumgoukei) + Number(overviewData.overViewTableTwo[zIdx].genkingai[0].goukei)).toString()

        // }
        setStoreData({ ...storeData, editPart: { ...storeData.editPart, overviewData: overviewData } })
        setSubmitDisabled(true)
    }

    const handleShrink = (type) => {
        setTmpBtnFlg(type)
    }

    var dataSumRow = []
    var tmpSumMap = {
        "sumcashTotal": "0",
        "sumkabusoku": "0",
        "sumgoukei": "0",
        "summeisai": null,
    }
    var tmpMeisaiArr = []
    if (storeData.nyukinki.overviewData.overViewTableTwo.length > 0) {
        for (var zIdx = 0; zIdx < storeData.nyukinki.overviewData.overViewTableTwo[0].genkingai[0].meisai.length; zIdx++) {
            var tmpMeisaiMap = { "sumValue": "0" }
            for (var yIdx = 0; yIdx < storeData.nyukinki.overviewData.overViewTableTwo.length; yIdx++) {
                tmpMeisaiMap["sumValue"] = (Number(tmpMeisaiMap["sumValue"]) + Number(storeData.nyukinki.overviewData.overViewTableTwo[yIdx].genkingai[0].meisai[zIdx].value)).toString()
            }
            tmpMeisaiArr.push(tmpMeisaiMap)
        }

        tmpSumMap.summeisai = tmpMeisaiArr
        tmpSumMap.sumcashTotal = '0'
        tmpSumMap.sumkabusoku = '0'
        tmpSumMap.sumgoukei = '0'
        for (var idx = 0; idx < storeData.nyukinki.overviewData.overViewTableTwo.length; idx++) {
            tmpSumMap.sumcashTotal = (Number(tmpSumMap.sumcashTotal) + Number(storeData.nyukinki.overviewData.overViewTableTwo[idx].cashTotal)).toString()
            tmpSumMap.sumkabusoku = (Number(tmpSumMap.sumkabusoku) + Number(storeData.nyukinki.overviewData.overViewTableTwo[idx].kabusoku)).toString()
            tmpSumMap.sumgoukei = (Number(tmpSumMap.sumgoukei) + Number(storeData.nyukinki.overviewData.overViewTableTwo[idx].genkingai[0].goukei)).toString()
        }
    }
    else {
        for (var arrIndex = 0; arrIndex < storeData.nyukinki.overviewData.overViewTableTwoSubHeader.length; arrIndex++) {
            var tmpMeisaiMap = {}
            tmpMeisaiMap["sumValue"] = "0"

            tmpMeisaiArr.push(tmpMeisaiMap)
        }
        tmpSumMap.summeisai = tmpMeisaiArr
        tmpSumMap.sumcashTotal = '0'
        tmpSumMap.sumkabusoku = '0'
        tmpSumMap.sumgoukei = '0'
    }
    dataSumRow.push(tmpSumMap)



    var editDataSumRow = []
    var editTmpSumMap = {
        "sumcashTotal": "0",
        "sumkabusoku": "0",
        "sumgoukei": "0",
        "summeisai": null,
    }
    var editTmpMeisaiArr = []
    if (storeData?.editPart?.overviewData?.overViewTableTwo?.length > 0) {
        for (var zIdx = 0; zIdx < storeData.editPart.overviewData.overViewTableTwo[0].genkingai[0].meisai.length; zIdx++) {
            var tmpMeisaiMap = { "sumValue": "0" }
            for (var yIdx = 0; yIdx < storeData.editPart.overviewData.overViewTableTwo.length; yIdx++) {
                tmpMeisaiMap["sumValue"] = (Number(tmpMeisaiMap["sumValue"]) + Number(storeData.editPart.overviewData.overViewTableTwo[yIdx].genkingai[0].meisai[zIdx].value)).toString()
            }
            editTmpMeisaiArr.push(tmpMeisaiMap)
        }

        editTmpSumMap.summeisai = editTmpMeisaiArr
        editTmpSumMap.sumcashTotal = '0'
        editTmpSumMap.sumkabusoku = '0'
        editTmpSumMap.sumgoukei = '0'
        for (var idx = 0; idx < storeData.editPart.overviewData.overViewTableTwo.length; idx++) {
            editTmpSumMap.sumcashTotal = (Number(editTmpSumMap.sumcashTotal) + Number(storeData.editPart.overviewData.overViewTableTwo[idx].cashTotal)).toString()
            editTmpSumMap.sumkabusoku = (Number(editTmpSumMap.sumkabusoku) + Number(storeData.editPart.overviewData.overViewTableTwo[idx].kabusoku)).toString()
            editTmpSumMap.sumgoukei = (Number(editTmpSumMap.sumgoukei) + Number(storeData.editPart.overviewData.overViewTableTwo[idx].genkingai[0].goukei)).toString()
        }
    }
    else {
        for (var arrIndex = 0; arrIndex < storeData.editPart.overviewData.overViewTableTwoSubHeader.length; arrIndex++) {
            var editTmpMeisaiMap = {}
            editTmpMeisaiMap["sumValue"] = "0"

            editTmpMeisaiArr.push(editTmpMeisaiMap)
        }
        editTmpSumMap.summeisai = editTmpMeisaiArr
        editTmpSumMap.sumcashTotal = '0'
        editTmpSumMap.sumkabusoku = '0'
        editTmpSumMap.sumgoukei = '0'
    }
    editDataSumRow.push(editTmpSumMap)

    var widthNum = tmpBtnFlg === 0 ? (storeData.nyukinki.overviewData.overViewTableTwoSubHeader.length * 141 + 1600) / 1040 * 100.000 : 1600 / 1040 * 100.000
    var widthPercent = widthNum.toFixed(3) + '%'

    //when nyukinki.length != pos.length
    var matchingPos = JSON.parse(JSON.stringify(storeData?.pos));
    var nyukinkiRejinoList = []
    var posRejinoList = []
    var tmpIdx = []
    storeData?.nyukinki?.overviewData?.overViewTableTwo.map((nyukinkiItem, nyukinkiIndex) => {
        nyukinkiRejinoList.push((parseInt(nyukinkiItem.rejino)).toString())
    })
    matchingPos.overviewData?.overViewTableTwo.map((posItem, nyukinkiIndex) => {
        posRejinoList.push(posItem.rejino)
    })
    // posRejinoList.map((rejino, index) => {
    //     if (nyukinkiRejinoList.indexOf(rejino) == -1) {
    //         tmpIdx.push(index)
    //     }
    // })
    // if (tmpIdx.length > 0) {
    //     tmpIdx.map((tpIdx, zindex) => {
    //         if (posRejinoList.length > tpIdx + 1) {
    //             moveArray(matchingPos.overviewData?.overViewTableTwo, tpIdx, matchingPos.overviewData?.overViewTableTwo.length)
    //             matchingPos.overviewData?.overViewTableTwo.splice(tpIdx, 1);
    //             // matchingPos.overviewData?.overViewTableTwo.splice(tpIdx, 0, { "rejino": posRejinoList[tpIdx] });
    //         }
    //     })
    // }

    // function moveArray(arr, sourceIndex, targetIndex) {
    //     arr[sourceIndex] = arr.splice(targetIndex, 1, arr[sourceIndex])[0];
    // }

    const [currentPage, setPage] = useState(1);
    const [count, setCount] = useState(Math.ceil(storeData.nyukinki.overviewData.overViewTableTwo.length / 10));
    const handleChange = (e, p) => {
        setPage(p);
    };

    const [currentEditPage, setEditPage] = useState(1);
    const [editCount, setEditCount] = useState(Math.ceil(storeData.editPart.overviewData.overViewTableTwo.length / 10));
    const handleEditChange = (e, p) => {
        setEditPage(p);
    };
    let matchingCoupon = false

    return (
        editBtnFlg ?
            <div style={{ width: '100%' }}>
                <Table aria-label="OverviewTableTwo" style={{ width: widthPercent, borderRadius: '0px', height: '100%', tableLayout: 'fixed', }}>
                    <TableHead >
                        <TableRow >
                            <StyledTableCell align='center' style={{ borderLeft: '1px solid #CCCCCC', width: '180px', tableLayout: 'fixed', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingRight: '8px', paddingBottom: '7px' }}
                            >
                                レジNo.
                            </StyledTableCell>
                            < StyledTableCell className={classes.col1} colSpan={tmpBtnFlg === 1 ? 3 : storeData.nyukinki.overviewData.overViewTableTwoSubHeader.length + 3} align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingBottom: '7px' }}
                            >
                                商品売上
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        <TableRow >
                            <StyledTableCell style={{ borderLeft: '1px solid #CCCCCC', borderRight: '1px solid #CCCCCC', padding: '8px', fontSize: '16px' }}></StyledTableCell>
                            <StyledTableCell align='left' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                現金計
                            </StyledTableCell>
                            <StyledTableCell align='left' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                過不足
                            </StyledTableCell>
                            <StyledTableCell align='left' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                <span style={{ float: 'left' }}>現金外計</span>
                                {tmpBtnFlg === 1 ? <AddBoxIcon style={{ marginBottom: '-7px', marginTop: '-9px', width: "24px", Color: '#162337', height: '24px', paddingTop: '0px', marginLeft: '31.572%', cursor: "pointer" }} onClick={() => {
                                    handleShrink(0)
                                }}></AddBoxIcon> : <IndeterminateCheckBoxIcon style={{ marginBottom: '-7px', marginTop: '-9px', width: '24px', Color: '#162337', height: '24px', paddingTop: '0px', marginLeft: '31.572%', cursor: "pointer" }} onClick={() => {
                                    handleShrink(1)
                                }}></IndeterminateCheckBoxIcon>}
                            </StyledTableCell>
                            {tmpBtnFlg === 0 &&
                                storeData?.editPart?.overviewData?.overViewTableTwoSubHeader?.map((items, index) => (
                                    <StyledTableCell key={index} align='left' style={{ width: '220px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#888888", fontSize: '16px', fontWeight: '700', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                        {items}
                                    </StyledTableCell>
                                ))
                            }
                        </TableRow>
                        {storeData?.editPart?.overviewData?.overViewTableTwo?.length > 0 && storeData?.editPart?.overviewData?.overViewTableTwo?.map((rows, index) => {
                            if (index >= (currentEditPage - 1) * 10 && index < currentEditPage * 10) {
                                let rejiRed = false
                                rows.genkingai[0].meisai.map((item, yIndex) => {
                                    let missMatchRejiNo = false
                                    let missMatch = false
                                    let newIndex = 0
                                    let findTmp = posRejinoList?.indexOf(rows?.rejino?.toString())
                                    if (findTmp > -1) {
                                        newIndex = parseInt(findTmp)
                                    }
                                    else {
                                        missMatchRejiNo = true
                                        missMatch = true
                                        newIndex = index
                                    }

                                    if (item.key == "クーポン") {
                                        if (!missMatch && (matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai != null && item.value === matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai[yIndex]?.value)) {
                                            matchingCoupon = false
                                        } else {
                                            matchingCoupon = true
                                            rejiRed = true
                                        }
                                    } else if (item.key == "商品券 3千" || item.key == "商品券 2千" || item.key == "商品券 5百") {
                                        let nyukinkiTotal = 0
                                        let posTotal = 0
                                        rows.genkingai[0].meisai.map((map, index) => {
                                            if (map.key == "商品券 3千" || map.key == "商品券 2千" || map.key == "商品券 5百") {
                                                nyukinkiTotal += parseInt(map.value)
                                            }
                                        })
                                        if (matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai != null) {
                                            matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai?.map((map, index) => {
                                                if (map.key == "商品券 3千" || map.key == "商品券 2千" || map.key == "商品券 5百") {
                                                    posTotal += parseInt(map.value)
                                                }
                                            })
                                        }
                                        if (nyukinkiTotal != posTotal && !missMatchRejiNo) {
                                            missMatch = true
                                            rejiRed = true
                                        }
                                    } else if (item.key != "地域振興券") {
                                        if (missMatch || matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai == null || (item.value != matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai[yIndex]?.value)) {
                                            rejiRed = true
                                        }
                                    }
                                })
                                return <TableRow key={index}>
                                    <StyledTableCell align='center' style={!rejiRed ? { borderLeft: '1px solid #CCCCCC', borderRight: '1px solid #CCCCCC', padding: '8px', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '150%', paddingBottom: '7px' } : { border: '2px solid #E60024', padding: '8px', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '150%', paddingBottom: '7px' }}>
                                        {rows.rejinoFlg === "1" ?
                                            <BasicTextfield style={{ width: '100%' }}
                                                value={rows.rejino}
                                                autoComplete="off"
                                                onChange={(event) => { handleRejinoChange(event.target.value, index) }}
                                                name="rejiNo"
                                                id="formatted-numberformat-input"
                                                inputProps={{
                                                    maxLength: rows.rejino > 0 ? 9 : 10,
                                                }}
                                                InputProps={{
                                                    placeholder: "番号",
                                                    classes: {
                                                        input: classes.tableTwoInput,
                                                    },
                                                    inputComponent: NumberFormatCustom,
                                                }}
                                            />
                                            : rows.rejino}
                                    </StyledTableCell>

                                    <StyledTableCell align='right' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>

                                        <BasicTextfield style={{ width: '100%' }}
                                            value={parseInt(rows.cashTotal)}
                                            autoComplete="off"
                                            onChange={(event) => { event.target.value != '-' && handleCashTotalChange(event.target.value, index) }}
                                            name="numberformat"
                                            id="formatted-numberformat-input"
                                            inputProps={{
                                                maxLength: rows.cashTotal > 0 ? 11 : 12,
                                            }}
                                            InputProps={{
                                                placeholder: "金額",
                                                classes: {
                                                    input: rows.cashTotal < 0 ? classes.inputContentEx : classes.inputContent,
                                                },
                                                inputComponent: NumberFormatCustom,
                                            }}
                                        />

                                    </StyledTableCell>

                                    <StyledTableCell align='right' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>

                                        <BasicTextfield style={{ width: '100%' }}
                                            value={parseInt(rows.kabusoku)}
                                            autoComplete="off"
                                            onChange={(event) => { event.target.value != '-' && handleKabusokuChange(event.target.value, index) }}
                                            name="numberformat"
                                            id="formatted-numberformat-input"
                                            inputProps={{
                                                maxLength: rows.kabusoku > 0 ? 11 : 12,
                                            }}
                                            InputProps={{
                                                placeholder: "金額",
                                                classes: {
                                                    input: rows.kabusoku < 0 ? classes.inputContentEx : classes.inputContent,
                                                },
                                                inputComponent: NumberFormatCustom,
                                            }}
                                        />

                                    </StyledTableCell>
                                    <StyledTableCell align='right' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                        <NumberFormat value={rows.genkingai[0].goukei}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            style={{ color: rows.genkingai[0].goukei < 0 ? "red" : "162337" }}
                                        />

                                    </StyledTableCell>
                                    {tmpBtnFlg === 0 &&
                                        rows.genkingai[0].meisai.map((item, yIndex) => {
                                            let missMatchRejiNo = false
                                            let missMatch = false
                                            let newIndex = 0
                                            let findTmp = posRejinoList?.indexOf(rows?.rejino?.toString())
                                            if (findTmp > -1) {
                                                newIndex = parseInt(findTmp)
                                            }
                                            else {
                                                missMatchRejiNo = true
                                                missMatch = true
                                                newIndex = index
                                            }

                                            if (item.key == "クーポン") {
                                                if (!missMatch && (matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai != null && item.value === matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai[yIndex]?.value)) {
                                                    matchingCoupon = false
                                                } else {
                                                    matchingCoupon = true
                                                }
                                            }

                                            if (item.key == "商品券 3千" || item.key == "商品券 2千" || item.key == "商品券 5百") {
                                                let nyukinkiTotal = 0
                                                let posTotal = 0
                                                rows.genkingai[0].meisai.map((map, index) => {
                                                    if (map.key == "商品券 3千" || map.key == "商品券 2千" || map.key == "商品券 5百") {
                                                        nyukinkiTotal += parseInt(map.value)
                                                    }
                                                })
                                                if (matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai != null) {
                                                    matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai?.map((map, index) => {
                                                        if (map.key == "商品券 3千" || map.key == "商品券 2千" || map.key == "商品券 5百") {
                                                            posTotal += parseInt(map.value)
                                                        }
                                                    })
                                                }
                                                if (nyukinkiTotal != posTotal && !missMatchRejiNo) {
                                                    missMatch = true
                                                }

                                                return (<StyledTableCell key={yIndex} align='right' style={!missMatch ? { borderLeft: '1px solid #CCCCCC', width: '220px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }
                                                    : { width: '220px', border: '2px solid #E60024', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>

                                                    <BasicTextfield style={{ width: '100%' }}
                                                        value={parseInt(item.value)}
                                                        autoComplete="off"
                                                        onChange={(event) => { event.target.value != '-' && handleDetailChange(event.target.value, index, yIndex) }}
                                                        name="numberformat"
                                                        id="formatted-numberformat-input"
                                                        inputProps={{
                                                            maxLength: item.value > 0 ? 11 : 12,
                                                        }}
                                                        InputProps={{
                                                            placeholder: "金額",
                                                            classes: {
                                                                input: item.value < 0 ? classes.inputContentEx : classes.inputContent,
                                                            },
                                                            inputComponent: NumberFormatCustom,
                                                        }}
                                                    />

                                                </StyledTableCell>)
                                            } else if (item.key == "クーポン" || item.key == "地域振興券") {
                                                return (<StyledTableCell key={yIndex} align='right' style={!matchingCoupon ? { borderLeft: '1px solid #CCCCCC', width: '220px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }
                                                    : { width: '220px', border: '2px solid #E60024', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>

                                                    <BasicTextfield style={{ width: '100%' }}
                                                        value={parseInt(item.value)}
                                                        autoComplete="off"
                                                        onChange={(event) => { event.target.value != '-' && handleDetailChange(event.target.value, index, yIndex) }}
                                                        name="numberformat"
                                                        id="formatted-numberformat-input"
                                                        inputProps={{
                                                            maxLength: item.value > 0 ? 11 : 12,
                                                        }}
                                                        InputProps={{
                                                            placeholder: "金額",
                                                            classes: {
                                                                input: item.value < 0 ? classes.inputContentEx : classes.inputContent,
                                                            },
                                                            inputComponent: NumberFormatCustom,
                                                        }}
                                                    />

                                                </StyledTableCell>)
                                            } else {
                                                return (<StyledTableCell key={yIndex} align='right' style={!missMatch && (matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai != null && item.value === matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai[yIndex]?.value) ? { borderLeft: '1px solid #CCCCCC', width: '220px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }
                                                    : { width: '220px', border: '2px solid #E60024', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>

                                                    <BasicTextfield style={{ width: '100%' }}
                                                        value={parseInt(item.value)}
                                                        autoComplete="off"
                                                        onChange={(event) => { event.target.value != '-' && handleDetailChange(event.target.value, index, yIndex) }}
                                                        name="numberformat"
                                                        id="formatted-numberformat-input"
                                                        inputProps={{
                                                            maxLength: item.value > 0 ? 11 : 12,
                                                        }}
                                                        InputProps={{
                                                            placeholder: "金額",
                                                            classes: {
                                                                input: item.value < 0 ? classes.inputContentEx : classes.inputContent,
                                                            },
                                                            inputComponent: NumberFormatCustom,
                                                        }}
                                                    />

                                                </StyledTableCell>)
                                            }

                                        })}
                                </TableRow>
                            }
                        })}

                        <TableRow style={{ borderLeft: '1px solid #CCCCCC', borderRight: '1px solid #CCCCCC', borderLeft: '1px solid #CCCCCC', backgroundColor: '#FFFFFF' }}>
                            <StyledTableCell colSpan={tmpBtnFlg === 1 ? 4 : storeData.editPart.overviewData.overViewTableTwoSubHeader.length + 4} >
                                <AddCircleOutlineIcon style={{ float: "left", width: '24px', height: '24px', cursor: "pointer" }} onClick={() => { addTableTwoData() }} />
                                <div style={{ float: "left", marginLeft: '10px', cursor: "pointer" }} onClick={() => { addTableTwoData() }}>
                                    明細を追加
                                </div>
                            </StyledTableCell>
                        </TableRow>


                        <TableRow >
                            <StyledTableCell align='center' style={{ backgroundColor: '#F4F4F4', borderLeft: '1px solid #CCCCCC', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingBottom: '7px' }}>
                                合計
                            </StyledTableCell>
                            <StyledTableCell align='right' style={{ backgroundColor: '#F4F4F4', width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>

                                <NumberFormat value={editDataSumRow[0].sumcashTotal}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </StyledTableCell>
                            <StyledTableCell align='right' style={{ backgroundColor: '#F4F4F4', width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                <NumberFormat value={editDataSumRow[0].sumkabusoku}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </StyledTableCell>
                            <StyledTableCell align='right' style={{ backgroundColor: '#F4F4F4', width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                <NumberFormat value={editDataSumRow[0].sumgoukei}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </StyledTableCell>
                            {tmpBtnFlg === 1 ? null :
                                editDataSumRow[0].summeisai.map((item, index) => (
                                    <StyledTableCell key={index} align='right' style={{ backgroundColor: '#F4F4F4', width: '180px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                        <NumberFormat
                                            value={item.sumValue}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                        />
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableBody>
                </Table>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Pagination
                        count={editCount}
                        page={currentEditPage}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleEditChange}
                        color="orange"
                        classes={{ ul: classes.ul }}
                        style={{ marginTop: '2.597%' }}
                    />
                </div>
            </ div>
            :



            <div style={{ width: '100%' }}>
                <Table aria-label="OverviewTableTwo" style={{ width: widthPercent, borderRadius: '0px', height: '100%', tableLayout: 'fixed', }}>
                    <TableHead >
                        <TableRow >
                            <StyledTableCell align='center' style={{ borderLeft: '1px solid #CCCCCC', width: '180px', tableLayout: 'fixed', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingRight: '8px', paddingBottom: '7px' }}
                            >
                                レジNo.
                            </StyledTableCell>
                            < StyledTableCell className={classes.col1} colSpan={tmpBtnFlg === 1 ? 3 : storeData.nyukinki.overviewData.overViewTableTwoSubHeader.length + 3} align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingBottom: '7px' }}
                            >
                                商品売上
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        <TableRow >
                            <StyledTableCell style={{ borderLeft: '1px solid #CCCCCC', borderRight: '1px solid #CCCCCC', padding: '8px', fontSize: '16px' }}></StyledTableCell>
                            <StyledTableCell align='left' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                現金計
                            </StyledTableCell>
                            <StyledTableCell align='left' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                過不足
                            </StyledTableCell>
                            <StyledTableCell align='left' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                <span style={{ float: 'left' }}>現金外計</span>
                                {tmpBtnFlg === 1 ? <AddBoxIcon style={{ marginBottom: '-7px', marginTop: '-9px', width: "24px", Color: '#162337', height: '24px', paddingTop: '0px', marginLeft: '31.572%', cursor: "pointer" }} onClick={() => {
                                    handleShrink(0)
                                }}></AddBoxIcon> : <IndeterminateCheckBoxIcon style={{ marginBottom: '-7px', marginTop: '-9px', width: '24px', Color: '#162337', height: '24px', paddingTop: '0px', marginLeft: '31.572%', cursor: "pointer" }} onClick={() => {
                                    handleShrink(1)
                                }}></IndeterminateCheckBoxIcon>}
                            </StyledTableCell>
                            {tmpBtnFlg === 0 &&
                                storeData?.nyukinki?.overviewData?.overViewTableTwoSubHeader?.map((items, index) => (
                                    <StyledTableCell key={index} align='left' style={{ width: '220px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#888888", fontSize: '16px', fontWeight: '700', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                        {items}
                                    </StyledTableCell>
                                ))
                            }
                        </TableRow>
                        {storeData?.nyukinki?.overviewData?.overViewTableTwo?.length > 0 && storeData?.nyukinki?.overviewData?.overViewTableTwo?.map((rows, index) => {
                            if (index >= (currentPage - 1) * 10 && index < currentPage * 10) {
                                let rejiRed = false
                                rows.genkingai[0].meisai.map((item, yIndex) => {
                                    let missMatchRejiNo = false
                                    let missMatch = false
                                    let newIndex = 0
                                    let findTmp = posRejinoList?.indexOf(rows?.rejino?.toString())
                                    if (findTmp > -1) {
                                        newIndex = parseInt(findTmp)
                                    }
                                    else {
                                        missMatchRejiNo = true
                                        missMatch = true
                                        newIndex = index
                                    }

                                    if (item.key == "クーポン") {
                                        if (!missMatch && (matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai != null && item.value === matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai[yIndex]?.value)) {
                                            matchingCoupon = false
                                        } else {
                                            matchingCoupon = true
                                            rejiRed = true
                                        }
                                    } else if (item.key == "商品券 3千" || item.key == "商品券 2千" || item.key == "商品券 5百") {
                                        let nyukinkiTotal = 0
                                        let posTotal = 0
                                        rows.genkingai[0].meisai.map((map, index) => {
                                            if (map.key == "商品券 3千" || map.key == "商品券 2千" || map.key == "商品券 5百") {
                                                nyukinkiTotal += parseInt(map.value)
                                            }
                                        })
                                        if (matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai != null) {
                                            matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai?.map((map, index) => {
                                                if (map.key == "商品券 3千" || map.key == "商品券 2千" || map.key == "商品券 5百") {
                                                    posTotal += parseInt(map.value)
                                                }
                                            })
                                        }
                                        if (nyukinkiTotal != posTotal && !missMatchRejiNo) {
                                            missMatch = true
                                            rejiRed = true
                                        }
                                    } else if (item.key != "地域振興券") {
                                        if (missMatch || matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai == null || (item.value != matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai[yIndex]?.value)) {
                                            rejiRed = true
                                        }
                                    }
                                })
                                return <TableRow key={rows.rejino}>
                                    <StyledTableCell align='center' style={!rejiRed ? { borderLeft: '1px solid #CCCCCC', borderRight: '1px solid #CCCCCC', padding: '8px', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '150%', paddingBottom: '7px' } : { border: '2px solid #E60024', padding: '8px', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '150%', paddingBottom: '7px' }}>
                                        {rows.rejino}
                                    </StyledTableCell>

                                    <StyledTableCell align='right' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                        <NumberFormat value={rows.cashTotal} displayType={'text'}
                                            thousandSeparator={true} />
                                    </StyledTableCell>

                                    <StyledTableCell align='right' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                        <NumberFormat value={rows.kabusoku} displayType={'text'}
                                            thousandSeparator={true} />
                                    </StyledTableCell>
                                    <StyledTableCell align='right' style={{ width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                        <NumberFormat value={rows.genkingai[0].goukei}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            style={{ color: rows.genkingai[0].goukei < 0 ? "red" : "162337" }}
                                        />

                                    </StyledTableCell>
                                    {tmpBtnFlg === 0 &&
                                        rows.genkingai[0].meisai.map((item, yIndex) => {
                                            let missMatchRejiNo = false
                                            let missMatch = false
                                            let newIndex = 0
                                            let findTmp = posRejinoList?.indexOf(rows.rejino.toString())
                                            if (findTmp > -1) {
                                                newIndex = parseInt(findTmp)
                                            }
                                            else {
                                                missMatchRejiNo = true
                                                missMatch = true
                                                newIndex = index
                                            }

                                            if (item.key == "クーポン") {
                                                if (!missMatch && (matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai != null && item.value === matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai[yIndex]?.value)) {
                                                    matchingCoupon = false
                                                } else {
                                                    matchingCoupon = true
                                                }
                                            }


                                            if (item.key == "商品券 3千" || item.key == "商品券 2千" || item.key == "商品券 5百") {
                                                let nyukinkiTotal = 0
                                                let posTotal = 0
                                                rows.genkingai[0].meisai.map((map, index) => {
                                                    if (map.key == "商品券 3千" || map.key == "商品券 2千" || map.key == "商品券 5百") {
                                                        nyukinkiTotal += parseInt(map.value)
                                                    }
                                                })
                                                if (matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai != null) {
                                                    matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai?.map((map, index) => {
                                                        if (map.key == "商品券 3千" || map.key == "商品券 2千" || map.key == "商品券 5百") {
                                                            posTotal += parseInt(map.value)
                                                        }
                                                    })
                                                }
                                                if (nyukinkiTotal != posTotal && !missMatchRejiNo) {
                                                    missMatch = true
                                                }

                                                return (<StyledTableCell key={yIndex} align='right' style={!missMatch ? { borderLeft: '1px solid #CCCCCC', width: '220px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }
                                                    : { width: '220px', border: '2px solid #E60024', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                                    <NumberFormat value={item.value} displayType={'text'}
                                                        thousandSeparator={true} />
                                                </StyledTableCell>)
                                            } else if (item.key == "クーポン" || item.key == "地域振興券") {
                                                return (<StyledTableCell key={yIndex} align='right' style={!matchingCoupon ? { borderLeft: '1px solid #CCCCCC', width: '220px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }
                                                    : { width: '220px', border: '2px solid #E60024', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                                    <NumberFormat value={item.value} displayType={'text'}
                                                        thousandSeparator={true} />
                                                </StyledTableCell>)
                                            } else {
                                                return (<StyledTableCell key={yIndex} align='right' style={!missMatch && (matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai != null && item.value === matchingPos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0]?.meisai[yIndex]?.value) ? { borderLeft: '1px solid #CCCCCC', width: '220px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }
                                                    : { width: '220px', border: '2px solid #E60024', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                                    <NumberFormat value={item.value} displayType={'text'}
                                                        thousandSeparator={true} />
                                                </StyledTableCell>)
                                            }

                                        })}
                                </TableRow>
                            }
                        })}



                        <TableRow >
                            <StyledTableCell align='center' style={{ backgroundColor: '#F4F4F4', borderLeft: '1px solid #CCCCCC', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#162337", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingBottom: '7px' }}>
                                合計
                            </StyledTableCell>
                            <StyledTableCell align='right' style={{ backgroundColor: '#F4F4F4', width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>

                                <NumberFormat value={dataSumRow[0].sumcashTotal}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </StyledTableCell>
                            <StyledTableCell align='right' style={{ backgroundColor: '#F4F4F4', width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                <NumberFormat value={dataSumRow[0].sumkabusoku}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </StyledTableCell>
                            <StyledTableCell align='right' style={{ backgroundColor: '#F4F4F4', width: '140px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                <NumberFormat value={dataSumRow[0].sumgoukei}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </StyledTableCell>
                            {tmpBtnFlg === 1 ? null :
                                dataSumRow[0].summeisai.map((item, index) => (
                                    <StyledTableCell key={index} align='right' style={{ backgroundColor: '#F4F4F4', width: '180px', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '100%', padding: '8px', paddingBottom: '7px' }}>
                                        <NumberFormat
                                            value={item.sumValue}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                        />
                                    </StyledTableCell>
                                ))}
                        </TableRow>
                    </TableBody>
                </Table>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <Pagination
                        count={count}
                        page={currentPage}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        color="orange"
                        classes={{ ul: classes.ul }}
                        style={{ marginTop: '2.597%' }}
                    />
                </div>
            </ div>
    );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(OverviewTableTwo);





