
import React, { useEffect, useState, Fragment, useRef } from "react";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { withStyles } from "@mui/styles";
import Stack from '@mui/material/Stack';
import NumberFormatCustom from "../../../global/component/basic/NumberFormatCustom";
import locale from "antd/es/date-picker/locale/ja_JP";
import { set } from "date-fns";
import { DatePicker, Modal } from "antd";
import moment from 'moment';
import BasicTextfield from "../../../global/component/basic/BasicTextfield";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";


// 画面スタイル
const styles = (theme) => ({
    inputContent: {
        textAlign: "right",
        paddingTop: '8px',
        paddingBottom: '8px',
        color: '#162337',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        ineHeight: '150%'
    },
    inputContentEx: {

        textAlign: "right",
        paddingTop: '8px',
        paddingBottom: '8px',
        color: 'red',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        ineHeight: '150%'
    },
    dateContent: {
        height: '100%',
        textAlign: "left",
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '16px',
        color: '#162337',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%'
    },
    row1: {
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        color: "#162337",
        ontSize: '16px',
        fontWeight: '700',
        lineHeight: '150%',
        padding: '8px',
        paddingBottom: '7px',
    },
    row2: {
        color: '#162337',
        height: '40px',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%',
        paddingTop: '8px',
        paddingBottom: '7px',
        paddingLeft: '8px',
        paddingRight: '8px'
    },
    row3: {
        color: 'red',
        height: '40px',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%',
        paddingTop: '8px',
        paddingBottom: '7px',
        paddingLeft: '8px',
        paddingRight: '8px'
    }
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#162337',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '16px',
        backgroundColor: '#FFFFFF'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },

    'tr,td,th': {
        border: '1px solid #CCCCCC',
    },
    'td,th': {
        width: '33.334%'
    }
}));

//　画面入り口
function OverviewTableOne(props) {

    const { classes, editBtnFlg, storeData, setStoreData, submitDisabled, setSubmitDisabled } = props;

    const [adjustmentDate, setAdjustmentDate] = useState(editBtnFlg ? storeData.editPart.overviewData.overViewTableOne.adjustmentDate ?? null : storeData.nyukinki.overviewData.overViewTableOne.adjustmentDate ?? null);

    const [principalChange, setPrincipalChange] = useState(editBtnFlg ? storeData.editPart.overviewData.overViewTableOne.principalChange ?? null : storeData.nyukinki.overviewData.overViewTableOne.principalChange ?? null);

    const [cashAdjustment, setCashAdjustmentChange] = useState(editBtnFlg ? storeData.editPart.overviewData.overViewTableOne.cashAdjustment ?? null : storeData.nyukinki.overviewData.overViewTableOne.cashAdjustment ?? null);

    const handleChange = (value) => {
        setPrincipalChange(value);
        const overviewData = storeData.editPart.overviewData
        overviewData.overViewTableOne.principalChange = value;
        setStoreData({ ...storeData, editPart: { ...storeData.editPart, overviewData: overviewData } })
        setSubmitDisabled(true)
    };

    const handleCashAdjustmentChange = (value) => {
        setCashAdjustmentChange(value);
        const overviewData = storeData.editPart.overviewData
        overviewData.overViewTableOne.cashAdjustment = value;
        setStoreData({ ...storeData, editPart: { ...storeData.editPart, overviewData: overviewData } })
        setSubmitDisabled(true)
    };


    const handleAdjustmentDateChange = (date) => {
        setAdjustmentDate(date);
        const overviewData = storeData.editPart.overviewData
        overviewData.overViewTableOne.adjustmentDate = date;
        setStoreData({ ...storeData, editPart: { ...storeData.editPart, overviewData: overviewData } })
        setSubmitDisabled(true)
    };

    const disabledDate = (current) => {
        // Can not select days after today and today
        return current && current > new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    };

    useEffect(() => {
        // getCurrSession();
        setPrincipalChange(storeData?.editPart?.overviewData?.overViewTableOne?.principalChange)
        setAdjustmentDate(storeData?.editPart?.overviewData?.overViewTableOne?.adjustmentDate ?? "")
        setCashAdjustmentChange(storeData?.editPart?.overviewData?.overViewTableOne?.cashAdjustment)
    }, [storeData])

    //　画面表示    
    return (
        <Table aria-label="OverviewTableOne" style={{ borderRadius: '0px' }}>
            <TableHead >
                <TableRow>
                    <StyledTableCell colSpan={2} align='center' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px' }}>
                        入金調整額
                    </StyledTableCell>
                    <StyledTableCell align='center' style={{ fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px' }}>
                        元金変更
                    </StyledTableCell>
                </TableRow>
            </TableHead>
            <TableBody >
                <StyledTableRow >
                    <StyledTableCell align='center' component="td" scope="row" className={classes.row1}>
                        調整日
                    </StyledTableCell>
                    <StyledTableCell align='center' component="td" scope="row" className={classes.row1}>
                        調整額
                    </StyledTableCell>

                    <StyledTableCell component="td" scope="row">

                    </StyledTableCell>
                </StyledTableRow>
                <StyledTableRow style={{ height: '55px' }}>
                    <StyledTableCell className={classes.row2} component="td" scope="row" >
                        <DatePicker disabled={!editBtnFlg || storeData.nyukinki?.reportData?.approvalStatus === "2" ? true : false}
                            allowClear={false}
                            showToday={false}
                            locale={locale}
                            format="YYYY/MM/DD"
                            suffixIcon={<CalendarTodayIcon />}
                            disabledDate={disabledDate}
                            style={{
                                height: '40px',
                                fontFamily: 'Noto Sans',
                                fontStyle: 'normal',
                                fontSize: '16px',
                                fontWeight: '400',
                                lineHeight: '150%',
                                width: "100%"
                            }}
                            inputReadOnly
                            value={adjustmentDate == "" ? "" : moment(adjustmentDate, 'YYYY/MM/DD')}
                            onChange={(value) => handleAdjustmentDateChange(moment(value).format("YYYY-MM-DD"))}
                            size="large"
                            placeholder="日付"
                        />
                    </StyledTableCell>
                    <StyledTableCell align='right' style={{ padding: '8px', paddingBottom: '7px' }} >
                        <BasicTextfield style={{ width: '100%', }}
                            disabled={!editBtnFlg || storeData.nyukinki?.reportData?.approvalStatus === "2" ? true : false}
                            value={parseInt(cashAdjustment)}
                            autoComplete="off"
                            onChange={(event) => { event.target.value != '-' && handleCashAdjustmentChange(event.target.value) }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            inputProps={{
                                maxLength: cashAdjustment > 0 ? 11 : 12,
                            }}
                            InputProps={{
                                placeholder: "金額",
                                classes: {
                                    input: cashAdjustment < 0 ? classes.inputContentEx : classes.inputContent,
                                },
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                    </StyledTableCell>
                    <StyledTableCell className={classes.row2} style={{ padding: '8px', paddingBottom: '7px' }}>
                        <BasicTextfield style={{ width: '100%' }}
                            value={parseInt(principalChange)}
                            disabled={!editBtnFlg || storeData.nyukinki?.reportData?.approvalStatus === "2" ? true : false}
                            autoComplete="off"
                            onChange={(event) => { event.target.value != '-' && handleChange(event.target.value) }}
                            name="numberformat"
                            id="formatted-numberformat-input"
                            inputProps={{
                                maxLength: principalChange > 0 ? 11 : 12,
                            }}
                            InputProps={{
                                placeholder: "金額",
                                classes: {
                                    input: principalChange < 0 ? classes.inputContentEx : classes.inputContent,
                                },
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                    </StyledTableCell>
                </StyledTableRow>
            </TableBody >
        </Table >
    );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(OverviewTableOne);