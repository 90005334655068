
import React from "react";
import { Routes, Route } from "react-router-dom"
import {
  Box
} from "@mui/material";
import { withStyles } from "@mui/styles";

import Report from "../pages/store/report/Report"
import Overview from "../pages/store/overview/Overview"
import OffCredit from "../pages/store/off-credit/OffCredit"
import TenantCredit from "../pages/store/tenant-credit/TenantCredit"
import Details from "../pages/store/details/Details"
import Confirm from "../pages/store/confirm/Confirm"


//　背景スタイル
const styles = (theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    width: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginBottom: 0,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: 0,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
      marginBottom: 0,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  childDiv: {
    height: 'calc(100vh - 48px)',
    overflowY: 'scroll'
  }
});

// 入り口
function StoreRouters(props) {
  const {
    classes,
    setSelectedTab,
    courses,
    prefectures,
    storeData,
    setStoreData,
    submitDisabled,
    setSubmitDisabled,
    setIsCircularLoading
  } = props

  // 画面表示
  return (
    <Box className={classes.wrapper}>
      <Routes>
        <Route index path="*" element={<div className={classes.childDiv}> <Report setSelectedTab={setSelectedTab} storeData={storeData}
          setStoreData={setStoreData} setIsCircularLoading={setIsCircularLoading} /></div>} />
        <Route path="report" element={<div className={classes.childDiv}> <Report setSelectedTab={setSelectedTab} storeData={storeData}
          setStoreData={setStoreData} setIsCircularLoading={setIsCircularLoading} /></div>} />
        <Route path="overview" element={<div className={classes.childDiv}> <Overview setSelectedTab={setSelectedTab} storeData={storeData}
          setStoreData={setStoreData} submitDisabled={submitDisabled} setSubmitDisabled={setSubmitDisabled} setIsCircularLoading={setIsCircularLoading} /></div>} />
        <Route path="offcredit" element={<div className={classes.childDiv}> <OffCredit setSelectedTab={setSelectedTab} storeData={storeData}
          setStoreData={setStoreData} submitDisabled={submitDisabled} setSubmitDisabled={setSubmitDisabled} /></div>} />
        <Route path="tenantcredit" element={<div className={classes.childDiv}> <TenantCredit setSelectedTab={setSelectedTab} storeData={storeData}
          setStoreData={setStoreData} submitDisabled={submitDisabled} setSubmitDisabled={setSubmitDisabled} /></div>} />
        <Route path="details" element={<div className={classes.childDiv}> <Details setSelectedTab={setSelectedTab} storeData={storeData}
          setStoreData={setStoreData} /></div>} />
        <Route path="confirm" element={<div className={classes.childDiv}> <Confirm setSelectedTab={setSelectedTab} storeData={storeData}
          setStoreData={setStoreData} /></div>} />
      </Routes>
    </Box>
  )
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(StoreRouters);