import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
    Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";

const styles = (theme) => ({
    dialogPaper: {
        minHeight: "80vh",
        maxHeight: "80vh",
        width: "60%",
        maxWidth: "60%",
    },
    title: {
        textAlign: "center",
        fontFamily: "Noto Sans",
        color: "#162337",
        fontSize: "24px",
        lineHeight: "24px",
        fontWeight: "700",
        marginTop: "30px"
    },
    button: {
        width: "192px",
        height: "48px"
    },
});

function CompleteDialog(props) {
    const { classes, dialogVisible, closeDialog, logout } = props;
    return (
        <div>
            <Dialog
                open={dialogVisible}
                scroll="paper"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle>
                    <div className={classes.title}>日報の提出完了</div>
                    <CloseIcon style={{
                        color: "#162337", width: 32, height: 32, position: "absolute",
                        right: 40,
                        top: 40,
                    }}
                        onClick={closeDialog} />
                </DialogTitle>
                <DialogContent>
                    <div style={{
                        display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
                    }}>
                        <img
                            src={`${process.env.PUBLIC_URL + "/images/complete.png"}`}
                        />
                        <div style={{
                            height: "16px"
                        }} />
                        <Typography
                            style={{
                                color: "#162337",
                                fontWeight: 700,
                                fontSize: "18px"
                            }}
                        >
                            いつもありがとうございます！
                        </Typography>
                        <Typography
                            style={{
                                color: "#162337",
                                fontWeight: 700,
                                fontSize: "18px"
                            }}
                        >
                            明日もよろしくいお願い致します。
                        </Typography>
                        <div style={{
                            height: "16px"
                        }} />
                        <Button
                            variant="contained"
                            onClick={logout}
                            color="orange"
                            className={classes.button}
                        >
                            <Typography
                                style={{
                                    color: "#FFFFFF",
                                    fontWeight: 700,
                                    fontSize: "16px"
                                }}
                            >
                                ログアウトして閉じる
                            </Typography>
                        </Button>
                    </div>

                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withStyles(styles, { withTheme: true })(CompleteDialog);
