import { useEffect, useState, useRef } from "react";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { RouterString } from "../../../global/common/constants";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { getAccountingDailyAPI } from "../../../network/api";
import { useMounted } from "../../../global/function/useMounted";
import BaseCircularProgress from "../../../global/component/basic/BaseCircularProgress";
import usePagination from "@mui/material/usePagination/usePagination";
import moment from "moment";
import { localConstants, Error } from "../../../global/common/constants";


// 画面スタイル
const styles = (theme) => ({
  headRow: {
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
    padding: "8px",
  },
  cell: {
    width: "140px",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "8px",
  },
  errorCell: {
    width: "140px",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#E60024",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "8px",
  },
  redUnderline: {
    color: "#E60024",
    textDecoration: "underline",
    textDecorationColor: "#E60024",
    cursor: "pointer",
  },
  blackUnderline: {
    color: "#162337",
    textDecoration: "underline",
    textDecorationColor: "#162337",
    cursor: "pointer",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#162337",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    backgroundColor: "#FFFFFF",
  },
}));

// 日報提出状況画面入り口
function ReportStatus(props) {
  const { classes, setSelectedTab,
    accountingData,
    setAccountingData } = props;

  const page = useRef(1);
  const [isLoading, setIsLoading] = useState(false);
  const [reportStatusData, setReportStatusData] = useState();
  const isMounted = useMounted()

  const handleChange = (e, p) => {
    page.current = p
    getDaily()
  };

  const getDaily = () => {
    setIsLoading(true);
    let param = {
      pageNum: page.current,
      pageLimit: 7
    }
    getAccountingDailyAPI(param).then((res) => {
      if (!isMounted()) return
      let resData = res.data
      if (res.status >= 400 && res.status < 500) {
        alert(Error.E0003);
      } else if (res.status == 200) {
        setReportStatusData(resData)
      }
      setIsLoading(false)
    }).catch((err) => {
      if (!isMounted()) return
      setIsLoading(false)
    })

  }
  useEffect(() => {
    setSelectedTab(RouterString.ReportStatus);
    getDaily()
  }, [setSelectedTab]);


  const navigate = useNavigate();

  const toUnsubmittedStores = (businessDay) => {
    setAccountingData({
      ...accountingData,
      businessDay: businessDay
    });
    localStorage.setItem(localConstants.BusinessDay, businessDay)
    navigate(RouterString.UnsubmittedStores);
  }

  const toUnauthorizedStores = (businessDay) => {
    setAccountingData({
      ...accountingData,
      businessDay: businessDay
    });
    localStorage.setItem(localConstants.BusinessDay, businessDay)
    navigate(RouterString.UnauthorizedStores);
  }

  const toAuthorizedStores = (businessDay) => {
    setAccountingData({
      ...accountingData,
      businessDay: businessDay
    });
    localStorage.setItem(localConstants.BusinessDay, businessDay)
    navigate(RouterString.AuthorizedStores);
  }

  return (
    <>
      {isLoading && <BaseCircularProgress />}
      <Table style={{ margin: "24px", width: "calc(100% - 48px)" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell
              align="center"
              className={classes.headRow}
              style={{ width: "15%" }}
            >
              営業日
            </StyledTableCell>
            <StyledTableCell
              className={classes.headRow}
              align="center"
              style={{ width: "15%" }}
            >
              未提出店舗数
            </StyledTableCell>
            <StyledTableCell
              className={classes.headRow}
              align="center"
              style={{ width: "15%" }}
            >
              未承認店舗数
            </StyledTableCell>
            <StyledTableCell
              className={classes.headRow}
              align="center"
              style={{ width: "15%" }}
            >
              承認済み店舗数
            </StyledTableCell>
            <StyledTableCell
              className={classes.headRow}
              align="center"
              style={{ width: "40%" }}
            >
              エラー
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {reportStatusData?.dataList?.map((rows, index) => (
            <TableRow key={index}>
              <StyledTableCell align="left" className={classes.cell} style={{ borderLeft: "1px solid #CCCCCC" }}>
                {moment(rows.businessDay).format("YYYY/MM/DD")}
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                {rows.unsubmittedStoresNumber > 0 ? (
                  <span
                    className={classes.redUnderline}
                    onClick={() => {
                      toUnsubmittedStores(rows.businessDay)
                    }}
                  >
                    {rows.unsubmittedStoresNumber}
                  </span>
                ) : (
                  rows.unsubmittedStoresNumber
                )}
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                {rows.unauthorizedStoresNumber > 0 ? (
                  <span
                    className={classes.redUnderline}
                    onClick={() => {
                      toUnauthorizedStores(rows.businessDay)
                    }}
                  >
                    {rows.unauthorizedStoresNumber}
                  </span>
                ) : (
                  rows.unauthorizedStoresNumber
                )}
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                {rows.authorizedStoresNumber > 0 ? (
                  <span
                    className={classes.blackUnderline}
                    onClick={() => {
                      toAuthorizedStores(rows.businessDay)
                    }}
                  >
                    {rows.authorizedStoresNumber}
                  </span>
                ) : (
                  rows.authorizedStoresNumber
                )}
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.errorCell}>
                {rows.errMsgNum > 0 ? "エラーがあるので確認してください（" + `${rows.errMsgNum}件）` : ""}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={reportStatusData ? Math.ceil(reportStatusData?.totalBusinessDayNum / 7) : 0}
        variant="outlined"
        shape="rounded"
        color="orange"
        onChange={handleChange}
        style={{ display: "flex", justifyContent: "center" }}
      />
    </>
  );
}

export default withStyles(styles, { withTheme: true })(ReportStatus);
