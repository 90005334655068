import React, { Fragment } from "react";
import { TextField, Typography } from "@mui/material";
import { withStyles, makeStyles } from "@mui/styles";

const styles = (theme) => ({
  labelBg: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 24
  },
  lablePrefix: {
    marginBottom: 0,
    fontSize: 12,
  },
  lableSuffix: {
    marginBottom: 0,
    fontSize: 9,
    color: "#E60024",
    marginLeft: theme.spacing(0.5),
  },
  input: {
    height: 48,
  },
  notchedOutlinemy: {
    borderWidth: '0px',
  },
  textFieldStyle: {
    marginTop: '0px'
  },
  inputContent: {
    height: 18,
    padding: 14,
  },
});

function StripeTextField(props) {
  const {
    stripeOptions,
    select,
    theme,
    classes,
    labelText,
    labelSuffixText,
    inputStyle,
    inputClasses,
    variant,
    margin,
    fullWidth,
    ...rest
  } = props;

  const options = {
    style: {
      base: {
        ...theme.typography.body1,
        color: theme.palette.text.primary,
        fontSize: "16px",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: theme.palette.text.secondary,
        },
      },
      invalid: {
        iconColor: theme.palette.error.main,
        color: theme.palette.error.main,
      },
    },
    ...stripeOptions,
  };

  return (
    <Fragment>
      <div className={classes.labelBg}>
        {labelText && (
          <Typography inline="true" classes={{ root: classes.lablePrefix }}>
            {labelText}
          </Typography>
        )}
        {labelSuffixText && (
          <Typography inline="true" classes={{ root: classes.lableSuffix }}>
            {labelSuffixText}
          </Typography>
        )}
      </div>
      <TextField
        variant={variant}
        margin={margin}
        fullWidth={fullWidth}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
        }}
        InputProps={{
          className: classes.input,
          options: options,
          style: inputStyle,
          classes: {
            input: classes.inputContent
          }
        }}
        {...rest}
      />
    </Fragment>
  );
}

StripeTextField.defaultProps = {
  variant: "outlined",
  margin: "dense", // "dense"  // "normal"
  fullWidth: true,
}

export default withStyles(styles, { withTheme: true })(StripeTextField);
