
import React from "react";

import { Snackbar } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles =(theme) => ({
    snackbarMessage: {
        whiteSpace: 'pre-wrap',
        textAlign: 'center',
        left: "50%",
        transform: 'translateX(-50%)',
        marginBottom: "30px"
    }
})


const snackbarFunction = function(props) {
    const {open, message, classes, handleCloseSnackBar}  = props

    return (
        <Snackbar
            open={ open }
            message= { message }
            classes = {{
                root: classes.snackbarMessage
            }}
            onClose={ handleCloseSnackBar }
            autoHideDuration={ 3000 }
        />
    )
}

export default withStyles(styles, { withTheme: true })(snackbarFunction) 