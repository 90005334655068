import React, { useState, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { RegExp, Error, RouterString, localConstants, networkErr } from "../../../global/common/constants";
import { useMounted } from "../../../global/function/useMounted";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormDialog from "../../../global/component/dialog/FormDialog";
import ErrorMsgBox from "../../../global/component/dialog/ErrorMsgBox";
import StripeTextField from "../../../global/component/basic/StripeTextField";
import SingleSureButton from "../../../global/component/dialog/SingleSureButton";
import { validatingCodeAPI } from "../../../network/api"
// 画面スタイル
const styles = (theme) => ({
    dialogPaper: {
        minHeight: "80vh",
        maxHeight: "80vh",
        width: "60%",
        maxWidth: "60%",
    },
    labelBg: {
        marginTop: 0
    },
    actions: {
        height: 130
    },
    sureButton: {
        fontSize: 16,
        backgroundColor: "#EF7A00",
        "&:enabled:hover": {
            backgroundColor: "#EF7A00",
            color: "#fff",
        },
    },
});

// ログイン画面入り口
function ValidatingCodeDialog(props) {
    const {
        classes, validatingCodeDialogVisiable, closeValidatingCodeDialog, setValidatingCodeDialogVisiable
    } = props;

    // 定数定義
    const [isLoading, setIsLoading] = useState(false);

    const loginValidatingCode = useRef("");

    const [errorMsg, setErrorMsg] = useState({});
    const isMounted = useMounted()
    const navigate = useNavigate()

    const login = () => {

        setErrorMsg(null);
        setIsLoading(true);
        // validatingCode必須チェック
        if (!loginValidatingCode.current.value) {
            setErrorMsg({ type: "validatingCode", text: Error.ValidatingCodeEmpty });
            loginValidatingCode.current.focus();
            setIsLoading(false);
            return
        }

        let param = {
            validatingCode: loginValidatingCode.current.value,
        }

        validatingCodeAPI(param).then((res) => {
            if (!isMounted()) return
            let resData = res.data
            if (res.status >= 400 && res.status < 500) { // error
                setErrorMsg({ text: resData.detail });
            } else if (res.status == 200) { // verify after login
                if (resData.data == "Success") {
                    closeValidatingCodeDialog()
                }
                else {
                    setErrorMsg({ type: "validatingCode", text: Error.ValidatingFaild });
                }
            }
            setIsLoading(false);
        }).catch((err) => {
            if (!isMounted()) return
            setErrorMsg({ text: networkErr.networkErrorText })
            setIsLoading(false)
        })
    }

    // 画面表示
    return (
        <div>
            <FormDialog
                open={validatingCodeDialogVisiable}
                classes={{
                    actions: classes.actions,
                }}
                onClose={() => { }}
                loading={isLoading}
                header={
                    <div>
                        <img
                            src={`${process.env.PUBLIC_URL + "/images/ok_logo.svg"}`}
                            style={{
                                width: "48px",
                                height: "24px",
                            }}
                        />
                        <IconButton
                            aria-label="close"
                            onClick={() => setValidatingCodeDialogVisiable(false)}
                            size="large"
                            sx={{
                                position: "absolute",
                                right: 12,
                                top: 30,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>}

                actions={
                    <SingleSureButton
                        isLoading={isLoading}
                        text="ログイン"
                        classes={{
                            root: classes.sureButton
                        }}
                        // ログインボタン押下イベント
                        onClick={(e) => {
                            e.preventDefault();
                            login();

                        }}
                    />
                }
            >
                <Fragment>

                    {errorMsg && errorMsg.text && <ErrorMsgBox errorMsg={errorMsg} />}
                    <StripeTextField
                        labelText="認証コード"
                        labelSuffixText="*"
                        error={errorMsg && errorMsg.type === "validatingCode"}
                        inputRef={loginValidatingCode}
                        placeholder="認証コードを入力してください"
                        classes={{
                            labelBg: classes.labelBg
                        }}
                    />
                </Fragment>
            </FormDialog>
        </div >
    );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(ValidatingCodeDialog);
