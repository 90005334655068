// events.js

const customEventName = {
    GlobalMessageTip: "GlobalMessageTip"
}

function onEventListener(eventType, listener) {
    document.addEventListener(eventType, listener);
}
  
function offEventListener(eventType, listener) {
    document.removeEventListener(eventType, listener);
}

function onceEventListener(eventType, listener) {
    onEventListener(eventType, handleEventOnce);

    function handleEventOnce(event) {
        listener(event);
        offEventListener(eventType, handleEventOnce);
    }
}

function triggerEventListener(eventType, data) {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
}

export { onEventListener, onceEventListener, offEventListener, triggerEventListener , customEventName};