import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  dialogPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: 640,
    marginTop: 80,
  },
  container: {
    height: 'auto'
  },
  dialogPaperScrollPaper: {
    maxWidth: "none",
    maxHeight: "none",
  },
  form: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogContent: {
    padding: "0px !important",
    width: "100%",
  },
  dialogHeader: {
    height: 112,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0px",
    marginBottom: 0,

    "& p": {
      fontSize: 24,
      fontWeight: 500,
      fontFamily: theme.typography.fontFamily,
    },
  },
  dialogItems: {
    width: 480,
    overflowX: "hidden",
  },
  actions: {
    width: 480,
    height: 96,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

/**
 * A Wrapper around the Dialog component to create centered
 * Login, Register or other Dialogs.
 */
function FormDialog(props) {
  const {
    classes,
    open,
    onClose,
    header,
    children,
    actions,
    hideBackdrop,

    dialogItemsHeight,
    borderTopShow,
    borderBottomShow

  } = props;


  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
      classes={{
        paper: classes.dialogPaper,
        paperScrollPaper: classes.dialogPaperScrollPaper,
        container: classes.container
      }}
      hideBackdrop={hideBackdrop ? hideBackdrop : false}
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.form}>
          <Box className={classes.dialogHeader}>{header}</Box>
          <Box
            className={classes.dialogItems}
            sx={{
              height: dialogItemsHeight || null,
              borderTop: borderTopShow && `2px solid #C5C5C5`,
              borderBottom: borderBottomShow && '2px solid #C5C5C5'
            }}>
            {children}
          </Box>
          <Box className={classes.actions}>{actions}</Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

FormDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  header: PropTypes.element,
  content: PropTypes.element,
  actions: PropTypes.element,
  hideBackdrop: PropTypes.bool,
};

export default withStyles(styles, { withTheme: true })(FormDialog);
