
import axios from "axios";
import { userRefreshToken } from "./api";
import { RouterString, localConstants, networkErr } from "../global/common/constants";


// the flag of refreshing 
var isRefreshing = false

// Re-request queue
var requests = []

const dealReturn = (response) => {
  if (!response) {
    return "接続中断"
  }
  return response;
};

export default dealReturn;