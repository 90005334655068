import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
    Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import Barcode from 'react-barcode';
import NumberFormat from "react-number-format";
import { localConstants } from "../../common/constants";
import moment from "moment";
import barcode from "../../common/barcode.json";
import { getCodabarAPI } from "../../../network/api"
import { useMounted } from "../../../global/function/useMounted";


const styles = (theme) => ({
    number: {
        textAlign: "right",
        color: "#162337",
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        fontSize: "16px",
        fontWeight: "600",
    }
});

function ReceiptForm(props) {
    const { classes, ref, storeData } = props;
    const [businessDay, setBusinessDay] = useState()
    const [barcodeValue, setBarcodeValue] = useState()
    const [base64, setBase64] = useState("")
    const isMounted = useMounted()
    const [data, setData] = useState([
        {
            currencyAmount: 10000,
            amount: 1
        },
        {
            currencyAmount: 5000,
            amount: 2
        },
        {
            currencyAmount: 2000,
            amount: 0
        },
        {
            currencyAmount: 1000,
            amount: 0
        },
        {
            currencyAmount: 500,
            amount: 10
        },
        {
            currencyAmount: 100,
            amount: 11
        },
        {
            currencyAmount: 50,
            amount: 20
        },
        {
            currencyAmount: 10,
            amount: 189
        },
        {
            currencyAmount: 5,
            amount: 100
        },
        {
            currencyAmount: 1,
            amount: 1000
        }
    ])
    useEffect(() => {
        setBusinessDay(moment(storeData?.nyukinki?.reportData?.businessDay ?? new Date()).format("YYYY/MM/DD"))
    }, [storeData])
    const getCodabar = (code) => {
        let param = {
            code: code
        }
        getCodabarAPI(param).then((res) => {
            if (!isMounted()) return
            let resData = res.data
            if (res.status >= 400 && res.status < 500) {
            } else if (res.status == 200) {
                setBase64(resData)
            }
        }).catch((err) => {
            console.log(err)
            if (!isMounted()) return
        })

    }
    useEffect(() => {
        let storeID = localStorage.getItem(localConstants.StoreId)
        for (let index = 0; index < barcode.length; index++) {
            if (barcode[index].StoreCode == storeID) {
                var checkDigits = "0"
                var divided = ((parseInt("550" + barcode[index].RegionalCode + barcode[index].StoreIdentificationCode)) / 7).toString()
                var list = divided.split(".")
                if (list.length == 2) {
                    checkDigits = Math.ceil((Number("0." + list[1].slice(0, 1)) * 7))
                } else {
                    checkDigits = "0"
                }
                var code = "A0550" + barcode[index].RegionalCode + barcode[index].StoreIdentificationCode + checkDigits + "A"
                setBarcodeValue(code)
                getCodabar(code)
                return
            }
        }
    }, [])
    return (
        <Box style={{
            height: "443px", width: "808px", backgroundColor: "#ffffff", padding: "20px", display: "flex", alignItems: "center"
        }}>
            <div style={{ border: "1px solid #2C3849", width: "100%", height: "100%", alignItems: "center", display: "flex", paddingTop: "20px", flexDirection: "column", }}>
                <Typography
                    sx={{
                        color: "#2C3849",
                        marginLeft: "8px",
                        fontWeight: 500,
                        fontSize: "20px",
                    }}
                >
                    入金伝票
                </Typography>
                <div style={{ marginTop: "24px" }}></div>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, marginRight: "50px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    color: "#162337",
                                    fontFamily: "Noto Sans",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                }}
                            >
                                会社名
                            </Typography>
                            <div style={{ width: "24px" }}></div>
                            <div style={{
                                width: '100%',
                                flex: 1
                            }}>
                                {<Box
                                    style={{
                                        float: "right"
                                    }}>
                                    <Typography
                                        style={{
                                            color: "#162337",
                                            fontFamily: "Noto Sans",
                                            fontStyle: "normal",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                        }}
                                        align="center"
                                    >
                                        オーケー株式会社
                                    </Typography>
                                </Box>}
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid #2C3849", width: "100%", marginBottom: "1px"
                        }} />
                        <div style={{
                            borderBottom: "1px solid #2C3849", width: "100%", marginBottom: "15px"
                        }} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    color: "#162337",
                                    fontFamily: "Noto Sans",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                }}
                            >
                                店舗
                            </Typography>
                            <div style={{
                                width: '100%',
                                flex: 1
                            }}>
                                {<Box
                                    style={{
                                        float: "right"
                                    }}>
                                    <Typography
                                        style={{
                                            color: "#162337",
                                            fontFamily: "Noto Sans",
                                            fontStyle: "normal",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                        }}
                                        align="center"
                                    >
                                        {localStorage.getItem(localConstants.StoreName)}
                                    </Typography>
                                </Box>}
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid #2C3849", width: "100%", marginBottom: "1px"
                        }} />
                        <div style={{
                            borderBottom: "1px solid #2C3849", width: "100%", marginBottom: "15px"
                        }} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    color: "#162337",
                                    fontFamily: "Noto Sans",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                }}
                            >
                                営業日
                            </Typography>
                            <div style={{
                                width: '100%',
                                flex: 1
                            }}>
                                {<Box
                                    style={{
                                        float: "right"
                                    }}>
                                    <Typography
                                        style={{
                                            color: "#162337",
                                            fontFamily: "Noto Sans",
                                            fontStyle: "normal",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                        }}
                                        align="center"
                                    >
                                        {businessDay}
                                    </Typography>
                                </Box>}
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid #2C3849", width: "100%", marginBottom: "1px"
                        }} />
                        <div style={{
                            borderBottom: "1px solid #2C3849", width: "100%", marginBottom: "15px"
                        }} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                sx={{
                                    color: "#162337",
                                    fontFamily: "Noto Sans",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                }}
                            >
                                入金額
                            </Typography>
                            <div style={{
                                width: '100%',
                                flex: 1
                            }}>
                                {<Box
                                    style={{
                                        float: "right"
                                    }}>
                                    < NumberFormat
                                        style={{
                                            color: "#162337",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            fontFamily: "Noto Sans",
                                            fontStyle: "normal"
                                        }}
                                        value={
                                            storeData.nyukinki?.overviewData ? storeData.nyukinki?.overviewData.overViewTableOne.depositAmount : 0
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                    />
                                </Box>}
                            </div>
                        </div>
                        <div style={{
                            borderBottom: "1px solid #2C3849", width: "100%", marginBottom: "1px"
                        }} />
                        <div style={{
                            borderBottom: "1px solid #2C3849", width: "100%", marginBottom: "15px"
                        }} />
                        {/* {barcodeValue && <Barcode value={barcodeValue} format="CODE39" width={1} />} */}
                        {base64 && <img
                            src={`data:image/jpeg;base64,${base64}`}
                            style={{
                                width: "350px",
                                height: "150px",
                            }}
                        />}
                    </div>
                    <div style={{ flex: 1, display: "flex" }}>
                        <div style={{ marginRight: "20px", textAlign: "right" }}>
                            <Typography
                                sx={{
                                    textAlign: "right",
                                    color: "#162337",
                                    fontFamily: "Noto Sans",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                }}
                            >
                                金種
                            </Typography>
                            {data.map((rowsDataItem, index) => {
                                return (
                                    <div key={index}>
                                        < NumberFormat
                                            style={{
                                                color: "#162337",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                fontFamily: "Noto Sans",
                                                fontStyle: "normal"
                                            }}
                                            value={rowsDataItem.currencyAmount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                        <div style={{
                                            borderBottom: "1px solid #00000000", width: "100%"
                                        }} />
                                    </div>
                                );
                            })}
                        </div>
                        <div style={{ marginRight: "30px" }}>
                            <div style={{ display: "flex" }}>
                                <div style={{
                                    width: "20px"
                                }} />
                                <Typography
                                    sx={{
                                        textAlign: "right",
                                        color: "#162337",
                                        fontFamily: "Noto Sans",
                                        fontStyle: "normal",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        width: "40px"
                                    }}
                                >
                                    枚数
                                </Typography>
                                <div style={{
                                    width: "80px"
                                }} />
                                <Typography
                                    sx={{
                                        textAlign: "right",
                                        color: "#162337",
                                        fontFamily: "Noto Sans",
                                        fontStyle: "normal",
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        width: "40px"
                                    }}
                                >
                                    小計
                                </Typography>
                                <div style={{
                                    width: "30px"
                                }} />
                            </div>
                            {data.map((rowsDataItem, index) => {
                                return (
                                    <div key={index}>
                                        < NumberFormat
                                            style={{
                                                color: "#00000000",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                fontFamily: "Noto Sans",
                                                fontStyle: "normal"
                                            }}
                                            value={rowsDataItem.currencyAmount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                        <div style={{
                                            borderBottom: "1px solid #2C3849", width: "100%"
                                        }} />

                                    </div>
                                );
                            })}
                        </div>
                        {/* <div style={{ marginRight: "50px", textAlign: "right" }}>
                            <Typography
                                sx={{
                                    textAlign: "right",
                                    color: "#162337",
                                    fontFamily: "Noto Sans",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    width: "40px"
                                }}
                            >
                                枚数
                            </Typography>
                            {storeData?.nyukinki?.overviewData?.sumCoins?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        < NumberFormat
                                            style={{
                                                color: "#162337",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                fontFamily: "Noto Sans",
                                                fontStyle: "normal"
                                            }}
                                            // value={item}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div style={{ marginRight: "50px", textAlign: "right" }}>
                            <Typography
                                sx={{
                                    textAlign: "right",
                                    color: "#162337",
                                    fontFamily: "Noto Sans",
                                    fontStyle: "normal",
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    width: "40px"
                                }}
                            >
                                小計
                            </Typography>
                            {data.map((rowsDataItem, index) => {
                                return (
                                    <div key={index}>
                                        < NumberFormat
                                            style={{
                                                color: "#162337",
                                                fontSize: "16px",
                                                fontWeight: "600",
                                                fontFamily: "Noto Sans",
                                                fontStyle: "normal"
                                            }}
                                            // value={rowsDataItem.currencyAmount * storeData?.nyukinki?.overviewData?.sumCoins[index]}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </div>
                                );
                            })}
                        </div> */}
                    </div>

                </div>

            </div>

        </Box >
    );
}

export default withStyles(styles, { withTheme: true })(ReceiptForm);
