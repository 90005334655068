import React, { useEffect, useState, Fragment } from "react";
import {
  Box,
  List,
  ListItem,
  Divider,
  Typography,
  Button,
  DialogActions,
} from "@mui/material";
import { withStyles } from "@mui/styles";

import { RouterString } from "../../common/constants";
import { Link } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import jpLocale from "date-fns/locale/ja";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import SingleSureButton from "../dialog/SingleSureButton";
import CompleteDialog from "./CompleteDialog";
import { useNavigate } from "react-router-dom";
import BySubjectDialog from "../../../pages/accounting/by-subject/BySubjectDialog";
import ATableDialog from "../../../pages/accounting/a-table/ATableDialog";
import { localConstants } from "../../../global/common/constants";

// 画面スタイル
const styles = (theme) => ({
  root: {
    height: "calc(100vh)",
    width: "18.75%",
    background: theme.palette.primary.main,
    flexShrink: 0,
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column",
  },
  menuLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  permanentDrawerListItem: {
    justifyContent: "left",
    padding: "0px",
    margin: "0px",
  },
  selectedDivider: {
    width: 4,
    background: "#EF7A00",
  },
  itemDivider: {
    width: 4,
    background: "transparent",
  },
  brandText: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.white,
  },
  primaryText: {
    fontSize: 14,
    fontWeight: 700,
    color: theme.palette.white,
  },
  dateContent: {
    height: "100%",
    textAlign: "left",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "16px",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "400",
  },
  inputComplete: {
    backgroundColor: "#2C3849",
  },
  inputNotComplete: {
    backgroundColor: "#00BDE6",
  },
  sureButton: {
    fontSize: 16,
    marginBottom: 16,
    backgroundColor: "#EF7A00",
    "&:enabled:hover": {
      backgroundColor: "#EF7A00",
      color: "#fff",
    },
  },
  actions: {
    width: 480,
    height: 96,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

// 左側メニュー画面入り口
function AccountingMenu(props) {
  const { classes, selectedTab,
    accountingData,
    setAccountingData } = props;
  const [isLoading, setIsLoading] = useState(false);
  // メニュー項目リスト
  let menus = [
    {
      link: RouterString.ReportStatus,
      name: "日報提出状況",
      selected:
        selectedTab == RouterString.ReportStatus || selectedTab == "/accounting"
          ? true
          : false,
    },
    {
      link: RouterString.UnsubmittedStores,
      name: "未提出店舗",
      selected: selectedTab == RouterString.UnsubmittedStores ? true : false,
    },
    {
      link: RouterString.UnauthorizedStores,
      name: "未承認店舗",
      selected: selectedTab == RouterString.UnauthorizedStores ? true : false,
    },
    {
      link: RouterString.AuthorizedStores,
      name: "承認済み店舗",
      selected: selectedTab == RouterString.AuthorizedStores ? true : false,
    },
  ];

  // 定数定義
  let [menuItems, setMenuItems] = useState(menus);
  const [dateTime, setDateTime] = useState(
    moment(localStorage.getItem(localConstants.BusinessDay) ?? new Date()).format("YYYY/MM/DD")
  );
  const [hideMenu, setHideMenu] = useState(true);

  const [aTableVisible, setATableVisible] = useState(false);
  const [bySubjectVisible, setBySubjectVisible] = useState(false);

  function showATableDialog() {
    setATableVisible(true);
  }
  function showBySubjectDialog() {
    setBySubjectVisible(true);
  }
  function closeATableDialog() {
    setATableVisible(false);
  }
  function closeBySubjectDialog() {
    setBySubjectVisible(false);
  }

  const MyActionBar = ({ onAccept, onCancel }) => {
    return (
      <DialogActions>
        <Button onClick={onCancel}> キャンセル </Button>
        <Button onClick={onAccept}> はい </Button>
      </DialogActions>
    );
  };

  // メニュー初期化画面切替表示
  useEffect(() => {
    setDateTime(moment(localStorage.getItem(localConstants.BusinessDay) ?? new Date()).format("YYYY/MM/DD"))
    let menuItemsCopy = [...menuItems];
    menuItemsCopy.map((item) => {
      item.selected = false;
    });
    switch (selectedTab) {
      case RouterString.ReportStatus:
      case "/accounting":
        menuItemsCopy[0].selected = true;
        setAccountingData({})
        break;
      case RouterString.UnsubmittedStores:
        menuItemsCopy[1].selected = true;
        break;
      case RouterString.UnauthorizedStores:
        menuItemsCopy[2].selected = true;
        break;
      case RouterString.AuthorizedStores:
        menuItemsCopy[3].selected = true;
        break;
    }

    setMenuItems(menuItemsCopy);
    if (selectedTab == RouterString.ReportStatus) {
      setHideMenu(true);
    } else {
      setHideMenu(false);
    }
  }, [selectedTab]);

  // 画面表示
  return !hideMenu ? (
    <Box className={classes.root}>
      <div
        style={{
          display: "flex",
          marginLeft: 16,
          marginTop: 8,
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={`${process.env.PUBLIC_URL + "/images/ok_logo.svg"}`}
            style={{
              width: "48px",
              height: "24px",
            }}
          />
        </div>

        <Typography
          variant="body1"
          sx={{
            color: "#FFFFFF",
            marginLeft: "16px",
            fontWeight: 700,
            fontSize: "14px",
          }}
        >
          レジ日報
        </Typography>
      </div>
      <div
        style={{
          marginTop: 8,
        }}
      />
      <div>
        <div
          style={{
            backgroundColor: "#ffffff",
            margin: "16px",
            borderRadius: "4px",
          }}
        >
          <div className={classes.dateContent}>{dateTime}</div>
        </div>
      </div>

      <div
        style={{
          borderBottom: "1px solid #2C3849",
        }}
      />
      <List>
        {menuItems.map((element, index) =>
          index == 0 ? (
            <Link
              to={element.link}
              key={element.link}
              className={classes.menuLink}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "52px",
                }}
              >
                <Divider
                  orientation="vertical"
                  classes={
                    element.selected
                      ? { root: classes.selectedDivider }
                      : { root: classes.itemDivider }
                  }
                  style={{ width: 4, height: "52px" }}
                />
                <ListIcon
                  style={{
                    color: "#FFFFFF",
                    width: 24,
                    height: 24,
                    marginLeft: 16,
                  }}
                />
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    marginLeft: "8px",
                    fontWeight: 700,
                    fontSize: "14px",
                  }}
                >
                  {element.name}
                </Typography>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #2C3849",
                }}
              />
            </Link>
          ) : (
            <Link
              to={element.link}
              key={element.link}
              className={classes.menuLink}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "52px",
                }}
              >
                <Divider
                  orientation="vertical"
                  classes={
                    element.selected
                      ? { root: classes.selectedDivider }
                      : { root: classes.itemDivider }
                  }
                  style={{ width: 4, height: "52px" }}
                />
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontWeight: 700,
                    fontSize: "14px",
                    marginLeft: "16px",
                  }}
                >
                  {element.name}
                </Typography>
              </div>
              <div
                style={{
                  borderBottom: "1px solid #2C3849",
                }}
              />
            </Link>
          )
        )}
      </List>
      {selectedTab === RouterString.AuthorizedStores && (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              borderBottom: "1px solid #2C3849",
              marginBottom: "16px",
            }}
          />
          <div style={{ marginLeft: 16, marginRight: 16 }}>
            <SingleSureButton
              isLoading={isLoading}
              text="A表出力"
              classes={{
                root: classes.sureButton,
              }}
              onClick={showATableDialog}
            />
          </div>
          <div style={{ marginLeft: 16, marginRight: 16 }}>
            <SingleSureButton
              isLoading={isLoading}
              text="仕訳出力"
              classes={{
                root: classes.sureButton,
              }}
              onClick={showBySubjectDialog}
            />
          </div>
          <BySubjectDialog
            dialogVisible={bySubjectVisible}
            closeDialog={closeBySubjectDialog}
          />
          <ATableDialog
            dialogVisible={aTableVisible}
            closeDialog={closeATableDialog}
          />
        </div>
      )}
    </Box>
  ) : (
    <Box className={classes.root}>
      <div
        style={{
          display: "flex",
          marginLeft: 16,
          marginTop: 8,
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={`${process.env.PUBLIC_URL + "/images/ok_logo.svg"}`}
            style={{
              width: "48px",
              height: "24px",
            }}
          />
        </div>
        <Typography
          variant="body1"
          sx={{
            color: "#FFFFFF",
            marginLeft: "16px",
            fontWeight: 700,
            fontSize: "14px",
          }}
        >
          レジ日報
        </Typography>
      </div>
      <div
        style={{
          marginTop: 8,
        }}
      />
      <div
        style={{
          borderBottom: "1px solid #2C3849",
        }}
      />
      <List>
        <Link
          to={menuItems[0].link}
          key={menuItems[0].link}
          className={classes.menuLink}
        >
          <div
            style={{ display: "flex", alignItems: "center", height: "52px" }}
          >
            <Divider
              orientation="vertical"
              classes={
                menuItems[0].selected
                  ? { root: classes.selectedDivider }
                  : { root: classes.itemDivider }
              }
              style={{ width: 4, height: "52px" }}
            />
            <ListIcon
              style={{
                color: "#FFFFFF",
                width: 24,
                height: 24,
                marginLeft: 16,
              }}
            />
            <Typography
              sx={{
                color: "#FFFFFF",
                marginLeft: "8px",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              {menuItems[0].name}
            </Typography>
          </div>
          <div
            style={{
              borderBottom: "1px solid #2C3849",
            }}
          />
        </Link>
      </List>
    </Box>
  );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(AccountingMenu);
