import React, { Component } from 'react'


/**
*String to Json
*
*/
function stringToJson(data) {
    return JSON.parse(data);
}
/**
*Json to String
*/
function jsonToString(data) {
    return JSON.stringify(data);
}
/**
*map to Json
*/
function mapToJson(map) {
    return JSON.stringify(strMapToObj(map));
}
/**
*Json to Map
*/
function jsonToMap(jsonStr) {
    return objToStrMap(JSON.parse(jsonStr));
}

/**
*map to object
*/
function strMapToObj(strMap) {
    let obj = Object.create(null);
    for (let [k, v] of strMap) {
        obj[k] = v;
    }
    return obj;
}

/**
*object to map
*/
function objToStrMap(obj) {
    let strMap = new Map();
    for (let k of Object.keys(obj)) {
        strMap.set(k, obj[k]);
    }
    return strMap;
}
/**
*object to string
*/
export function objectToString(obj) {
    return JSON.stringify(mapToJson(objToStrMap(obj)));
}
/**
*string to object
*/
export function stringToObejct(str) {
    return strMapToObj(jsonToMap(stringToJson(str)));
}
