import React, { useEffect, useState, Fragment, useReducer } from "react";
import {
    Box,
    Typography,
    Button
} from "@mui/material";
import { withStyles } from "@mui/styles";
import MessageFunction from "../mountedWindow/MessageFunction";
import { onEventListener, offEventListener, customEventName } from "../../global/function/customEvent";
import Settings from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { useNavigate } from "react-router-dom";
import { RouterString } from "../../global/common/constants";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import MasterCreateNewDialog from "./MasterCreateNewDialog";
import MasterEditDialog from "./MasterEditDialog";
import BasicTextfield from "../../global/component/basic/BasicTextfield";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useMounted } from "../../global/function/useMounted";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getMasterAPI, updateMasterAPI } from "../../network/api";
import BaseCircularProgress from "../../global/component/basic/BaseCircularProgress";
import util from "../../global/common/util"
import { localConstants, Error, CognitoLoginUrl } from "../../global/common/constants";
import { set } from "date-fns";
import ValidatingCodeDialog from "../../global/component/pages/ValidatingCodeDialog";
const styles = (theme) => ({
    title: {
        textAlign: "center",
        fontFamily: "Noto Sans",
        color: "#162337",
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "700",
    },
    dialogPaper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: 640,
        marginTop: 88,
    },
    dialogContent: {
        textAlign: "center",
        fontFamily: "Noto Sans",
        color: "#162337",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400",
    },
    inputContent: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingLeft: '16px',
        paddingRight: '16px',
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        ineHeight: '150%'
    },
    createNew: {
        width: '25%',
        marginTop: "0px",
        paddingTop: '7px', paddingBottom: '7px',
        paddingLeft: '15px', paddingRight: '15px',
        border: '1px solid #EF7A00',
        color: '#EF7A00',
        fontFamily: 'Noto Sans',
        fontStyle: 'regular',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%'
    },
    backBeforeChange: {
        width: '33.337%',
        marginTop: "0px",
        marginLeft: "8px",
        paddingTop: '7px', paddingBottom: '7px',
        paddingLeft: '15px', paddingRight: '15px',
        border: '1px solid #162337',
        backgroundColor: '#162337',
        color: '#FFFFFF',
        fontFamily: 'Noto Sans',
        fontStyle: 'regular',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%'
    },
    applyChanges: {
        width: '37.5%',
        marginLeft: "8px",
        marginRight: "0px",
        paddingTop: '7px', paddingBottom: '7px',
        paddingLeft: '15px', paddingRight: '15px',
        border: '1px solid #EF7A00',
        backgroundColor: '#EF7A00',
        color: '#FFFFFF',
        fontFamily: 'Noto Sans',
        fontStyle: 'regular',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%'

    },
    editMaster: {
        paddingTop: '7px', paddingBottom: '7px',
        paddingLeft: '15px', paddingRight: '15px',
        border: '1px solid #EF7A00',
        color: '#EF7A00',
        fontFamily: 'Noto Sans',
        fontStyle: 'regular',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '100%'
    },
    deleteMaster: {
        width: "36px",
        height: "24px",
        marginTop: "3px",
        color: "grey"
    },
    childDiv: {
        height: 'calc(100vh - 48px)',
        overflowY: 'scroll'
    },
    tableRowHaveChanged: {
        backgroundColor: "#FFF5D3"
    },
    tableRow: {
        backgroundColor: '#FFFFFF'
    }
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#162337',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        borderLeft: '1px solid #CCCCCC',
        fontSize: '16px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },

}));


// トップ画面入り口
function Master(props) {

    // 定数定義
    const {
        classes
    } = props
    const [messageOpen, setMessageOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [searchContent, setSearchContent] = useState("")
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate()
    const isMounted = useMounted()
    const [highLightArr, setHighLightArr] = useState([])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logout = () => {
        localStorage.clear()
        setAnchorEl(null);
        window.location.href = CognitoLoginUrl.logoutUrl
        // navigate("/")
    };
    const handleCloseSnackBar = () => {
        setMessageOpen(false)
    }
    const handleSearchByKeyWord = (value) => {
        setSearchContent(value)
    }


    const [isLoading, setIsLoading] = useState(false);
    const [masterData, setMasterData] = useState();
    const [compareMasterData, setCompareMasterData] = useState();
    const forceUpdate = useReducer((bool) => !bool)[1];
    const [createNewDialogVisible, setCreateNewDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [editData, setEditData] = useState({});
    const [editIndex, setEditIndex] = useState();
    const [deleteIndex, setDeleteIndex] = useState();
    const [deleteDialogVisable, setDeleteDialogVisable] = useState(false);
    const [rollBackDialogVisable, setRollBackDialogVisable] = useState(false);
    const [updateDialogVisable, setUpdateDialogVisable] = useState(false);
    const [buttonDisplayFlg, setButtonDisplayFlg] = useState(false);
    const [validatingCodeDialogVisiable, setValidatingCodeDialogVisiable] = useState(false);
    const [validatingFlag, setValidatingFlag] = useState(0);
    function closeValidatingCodeDialog() {
        setValidatingCodeDialogVisiable(false);
    }
    function openCreateNewDialog() {
        setValidatingCodeDialogVisiable(false);
        if (validatingFlag == 1) {
            setCreateNewDialogVisible(true);
        }
        else if (validatingFlag == 2) {
            setEditDialogVisible(true);
        }
        else if (validatingFlag == 3) {
            setDeleteDialogVisable(true);
        }

    }
    function closeCreateNewDialog() {
        setCreateNewDialogVisible(false);
    }
    function addDataCreateNewDialog(data) {
        masterData.masterTable.push(data)
        masterData.masterTable.sort(up)
        highLightArr.push({ "paymentId": data.paymentId, "subPaymentId": data.subPaymentId })
        setHighLightArr([...highLightArr])
        setMasterData({ ...masterData })
        setCreateNewDialogVisible(false);
    }
    function openEditDialog() {
        setEditDialogVisible(true);
    }
    function closeEditDialog() {
        setEditDialogVisible(false)
    }
    function editDataFromEditDialog(data) {
        masterData.masterTable.splice(editIndex, 1, data)
        masterData.masterTable.sort(up)
        highLightArr.push({ "paymentId": data.paymentId, "subPaymentId": data.subPaymentId })
        setHighLightArr([...highLightArr])
        setMasterData({ ...masterData })
        setEditDialogVisible(false);
    }
    const backBeforeChange = () => {
        setMasterData(compareMasterData)
        setHighLightArr([])
        setRollBackDialogVisable(false)
    }
    const deleteMaster = (index) => {
        masterData.masterTable.splice(index, 1)
        masterData.masterTable.sort(up)
        setMasterData({ ...masterData })
        setDeleteDialogVisable(false)
    }
    const closeAlertDialog = () => {
        setDeleteDialogVisable(false)
        setRollBackDialogVisable(false)
        setUpdateDialogVisable(false)
    }
    const update = () => {
        updateMasterData();
        setUpdateDialogVisable(false)
    }
    const getMasterData = () => {
        if (!isMounted()) return
        setIsLoading(true);
        getMasterAPI().then((res) => {
            let resData = res.data.data
            if (res.status >= 400 && res.status < 500) {
                alert(Error.E0003);
            } else if (res.status == 200) {
                console.log(res)
                resData.masterTable.sort(up);
                setCompareMasterData(JSON.parse(JSON.stringify(resData)));
                setMasterData(JSON.parse(JSON.stringify(resData)));
            }
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
            if (!isMounted()) return
        })
    };

    function up(a, b) {
        return a.accountSubjectCode - b.accountSubjectCode
    }

    const updateMasterData = () => {
        if (!isMounted()) return
        setIsLoading(true);
        let param = {
            data: masterData
        }
        console.log(param)
        updateMasterAPI(param).then((res) => {
            let resData = res.body
            if (res.status >= 400 && res.status < 500) {
                alert(Error.E0003);
            } else if (res.status == 200) {
                setHighLightArr([])
            }
            setIsLoading(false)
        }).catch((err) => {
            setIsLoading(false)
            if (!isMounted()) return
        })
    };
    // 画面初期化処理
    useEffect(() => {
        if (util.getUrlParam("expiredTime")) {
            localStorage.setItem(localConstants.ExpiredTime, util.getUrlParam("expiredTime"))
            localStorage.setItem(localConstants.Email, util.getUrlParam("email"))
            localStorage.setItem(localConstants.UserAccess, util.getUrlParam("userAccess"))
            localStorage.setItem(localConstants.StoreId, util.getUrlParam("storeCode"))
            localStorage.setItem(localConstants.StoreName, util.getUrlParam("storeName"))
            localStorage.setItem(localConstants.LastName, util.getUrlParam("lastName"))
            localStorage.setItem(localConstants.FirstName, util.getUrlParam("firstName"))
            navigate(RouterString.Master)
        }
        if (!localStorage.getItem(localConstants.ExpiredTime)) {
            navigate(RouterString.Login)
            return
        }
        if (!localStorage.getItem(localConstants.UserAccess)) {
            navigate(RouterString.Login)
            return
        }
        if (parseInt(localStorage.getItem(localConstants.ExpiredTime)) < parseInt(new Date().getTime() / 1000)) {
            navigate(RouterString.Login)
            return
        }
        if (parseInt(localStorage.getItem(localConstants.UserAccess)) == 0) {
            navigate(RouterString.Login)
            return
        }
        // else if (parseInt(localStorage.getItem(localConstants.UserAccess)) == 1) {
        //     navigate(RouterString.Accounting)
        //     return
        // }
        getMasterData();
    }, []);

    useEffect(() => {
        if (compareMasterData && masterData) {
            if (JSON.stringify(compareMasterData) === JSON.stringify(masterData)) {
                setButtonDisplayFlg(false)
            }
            else {
                setButtonDisplayFlg(true)
            }
        }
    }, [compareMasterData, masterData]);


    // 画面表示
    return (
        <>
            {isLoading && <BaseCircularProgress />}
            <ValidatingCodeDialog validatingCodeDialogVisiable={validatingCodeDialogVisiable} closeValidatingCodeDialog={openCreateNewDialog} setValidatingCodeDialogVisiable={setValidatingCodeDialogVisiable} />
            <MasterCreateNewDialog
                createNewDialogVisible={createNewDialogVisible}
                closeCreateNewDialog={closeCreateNewDialog}
                addDataCreateNewDialog={addDataCreateNewDialog}
                masterData={masterData}
            />
            <MasterEditDialog
                editDialogVisible={editDialogVisible}
                closeEditDialog={closeEditDialog}
                editDataFromEditDialog={editDataFromEditDialog}
                masterData={masterData}
                editData={editData}
                setEditData={setEditData}
                editIndex={editIndex}
            />
            <Dialog
                open={deleteDialogVisable}
                scroll="paper"
                classes={{
                    paper: classes.dialogPaper,
                }}
            >
                <DialogTitle>
                    <div className={classes.title}>データを削除しますか？</div>
                    <IconButton
                        aria-label="close"
                        onClick={closeAlertDialog}
                        size="large"
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.dialogContent}>  {"本当に「" + masterData?.masterTable[deleteIndex]?.journalName + "」のマスターデータを削除しますか？"}</div>
                    <div className={classes.dialogContent}>    この操作は「変更内容を反映」ボタンを押すまでシステムには反映されません。</div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={closeAlertDialog}
                        color="orange"
                        style={{
                            fontSize: 14,
                            width: "112px",
                            marginLeft: "20px",
                            marginRight: "auto",
                        }}
                    >
                        キャンセル
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            deleteMaster(deleteIndex)
                        }}
                        style={{
                            fontSize: 14,
                            backgroundColor: "#EF7A00",
                            width: "112px",
                            marginRight: "15px",
                        }}
                    >
                        削除
                    </Button>
                </DialogActions>
                <div
                    style={{
                        height: "8px",
                    }}
                >
                </div>
            </Dialog>
            <Dialog
                open={updateDialogVisable}
                scroll="paper"
                classes={{
                    paper: classes.dialogPaper,
                }}
            >
                <DialogTitle >
                    <div className={classes.title}>変更内容を反映しますか？</div>
                    <IconButton
                        aria-label="close"
                        onClick={closeAlertDialog}
                        size="large"
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.dialogContent}>  本当にすべての変更内容を反映しますか？</div>
                    <div className={classes.dialogContent}>    この操作は取り消せません。</div>
                </DialogContent>
                <DialogActions >
                    <Button
                        variant="outlined"
                        onClick={closeAlertDialog}
                        color="orange"
                        style={{
                            fontSize: 14,
                            width: "112px",
                            marginLeft: "20px",
                            marginRight: "auto",
                        }}
                    >
                        キャンセル
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            update()
                        }}
                        // onClick={
                        //     update
                        // }
                        style={{
                            fontSize: 14,
                            backgroundColor: "#EF7A00",
                            width: "112px",
                            marginRight: "15px",
                        }}
                    >
                        反映
                    </Button>
                </DialogActions>
                <div
                    style={{
                        height: "8px",
                    }}
                >
                </div>
            </Dialog>
            <Dialog
                open={rollBackDialogVisable}
                scroll="paper"
                classes={{
                    paper: classes.dialogPaper,
                }}
            >
                <DialogTitle >
                    <div className={classes.title}>変更前の状態に戻しますか？</div>
                    <IconButton
                        aria-label="close"
                        onClick={closeAlertDialog}
                        size="large"
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.dialogContent}>  すべての変更が破棄されます。本当に変更前の状態に戻しますか？</div>
                    <div className={classes.dialogContent}>    この操作は取り消せません。</div>
                </DialogContent>
                <DialogActions >
                    <Button
                        variant="outlined"
                        onClick={closeAlertDialog}
                        color="orange"
                        style={{
                            fontSize: 14,
                            width: "112px",
                            marginLeft: "20px",
                            marginRight: "auto",
                        }}
                    >
                        キャンセル
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            backBeforeChange()
                        }}
                        style={{
                            fontSize: 14,
                            backgroundColor: "#EF7A00",
                            width: "112px",
                            marginRight: "15px",
                        }}
                    >
                        破棄
                    </Button>
                </DialogActions>
                <div
                    style={{
                        height: "8px",
                    }}
                >
                </div>
            </Dialog>
            <Box style={{
                height: '100vh'
            }}>

                <Box sx={{ display: 'flex' }}>
                    <div style={{
                        display: "absolute",
                        width: "23.438%",
                        backgroundColor: "#162337"
                    }}>

                        <div style={{ display: "flex", width: "100%", backgroundColor: "#162337", marginLeft: 16, marginTop: 12, marginBottom: 12, alignItems: "center" }}>
                            <div>
                                <img
                                    src={`${process.env.PUBLIC_URL + "/images/ok_logo.svg"}`}
                                    style={{
                                        width: "48px",
                                        height: "24px",
                                    }}
                                />
                            </div>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "#FFFFFF",
                                    marginLeft: "16px",
                                    fontWeight: 700,
                                    fontSize: "14px"
                                }}
                            >
                                レジ日報
                            </Typography>
                        </div>
                    </div>
                    {/* タイトルバー */}
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right",
                        width: "100%",
                        height: "48px",
                        backgroundColor: "#FFFFFF"

                    }}>

                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: "#162337",
                                marginRight: "16px"
                            }}
                        >
                            {localStorage.getItem(localConstants.StoreName)}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: "#162337",
                                marginRight: "24px"
                            }}
                        >
                            {localStorage.getItem(localConstants.LastName) + " " + localStorage.getItem(localConstants.FirstName) + "さん"}
                        </Typography>
                        <Settings style={{ color: "#162337", width: 20, height: 20, marginRight: "24px", cursor: "pointer" }} onClick={handleClick} />
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            autoFocus={false}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {parseInt(localStorage.getItem(localConstants.UserAccess)) != 0 ? <MenuItem
                                onClick={() => {
                                    setAnchorEl(null);
                                    navigate(RouterString.Accounting)
                                }}>
                                <ArrowRight style={{ color: "#162337", width: 16, height: 16, marginRight: "8px", marginLeft: "8px" }} />
                                <Typography style={{
                                    color: "#162337", fontWeight: '400',
                                    fontSize: '14px', marginRight: "8px"
                                }} >
                                    経理画面へ移動する
                                </Typography>
                            </MenuItem> : null}

                            <MenuItem onClick={logout}>
                                <ArrowRight style={{ color: "#162337", width: 16, height: 16, marginRight: "8px", marginLeft: "8px" }} />
                                <Typography style={{
                                    color: "#162337", fontWeight: '400',
                                    fontSize: '14px', marginRight: "8px"
                                }} >
                                    ログアウト
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </div>

                </Box>

                {/* グローバルメッセージ処理 */}
                <MessageFunction
                    open={messageOpen}
                    message={message}
                    handleCloseSnackBar={handleCloseSnackBar}
                />
                <div >

                    <div>
                        <div style={{ display: "flex", }}><BasicTextfield style={{ width: '27.891%', backgroundColor: "#FFFFFF", margin: "24px", marginBottom: '16px' }}
                            onChange={(event) => { handleSearchByKeyWord(event.target.value) }}
                            InputProps={{
                                placeholder: "キーワード检索",
                                classes: {
                                    input: classes.inputContent,
                                },
                            }}
                        />
                            <div style={{ width: '46.485%', float: "right", justifyContent: "right", marginTop: "24px" }}>
                            </div>
                            <div style={{ width: '34.661%', float: "right", marginTop: "24px", marginRight: "24px" }}>
                                <Button className={classes.createNew}
                                    onClick={() => {
                                        setValidatingFlag(1)
                                        setValidatingCodeDialogVisiable(true)

                                    }}>
                                    新規作成
                                </Button>
                                {buttonDisplayFlg &&
                                    <Button className={classes.backBeforeChange} onClick={() => {
                                        setRollBackDialogVisable(true)
                                    }} >
                                        変更前に戻す
                                    </Button>}
                                {buttonDisplayFlg &&
                                    <Button className={classes.applyChanges} onClick={() => {
                                        setUpdateDialogVisable(true)
                                    }} >
                                        変更内容を反映
                                    </Button>}
                            </div>
                        </div>
                    </div>

                    <div style={{ marginLeft: "24px", marginRight: "24px" }}>
                        <TableContainer component={Paper} style={{ boxShadow: 'none', borderRadius: '0px', backgroundColor: '#f4f4f4', borderBottom: '0px' }}>
                            <Table aria-label="OverviewTableTwo" style={{ borderRadius: '0px', height: '100%', tableLayout: 'fixed', }}>
                                <TableHead >
                                    <TableRow >
                                        <StyledTableCell align='center' style={{ borderLeft: '1px solid #CCCCCC', width: '20.309%', tableLayout: 'fixed', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'bold', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingRight: '8px', paddingBottom: '7px' }}
                                        >
                                            仕訳名称
                                        </StyledTableCell>
                                        < StyledTableCell align='center' style={{ width: '12.186%', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingBottom: '7px' }}
                                        >
                                            細目
                                        </StyledTableCell>
                                        < StyledTableCell align='center' style={{ width: '13.81%', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingBottom: '7px' }}
                                        >
                                            明細貸借区分
                                        </StyledTableCell>
                                        < StyledTableCell align='center' style={{ width: '13.81%', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingBottom: '7px' }}
                                        >
                                            勘定科目コード
                                        </StyledTableCell>
                                        < StyledTableCell align='center' style={{ width: '13.81%', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingBottom: '7px' }}
                                        >
                                            売上情報抽出
                                        </StyledTableCell>
                                        < StyledTableCell align='center' style={{ width: '18.847%', borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingBottom: '7px' }}
                                        >
                                        </StyledTableCell>
                                        < StyledTableCell align='center' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#FFFFFF", fontSize: '16px', fontWeight: '700', lineHeight: '150%', padding: '8px', paddingBottom: '7px' }}
                                        >
                                            削除
                                        </StyledTableCell>
                                    </TableRow>
                                    <TableRow></TableRow>
                                </TableHead>
                                <TableBody >
                                    {masterData != null && searchContent === "" ? masterData?.masterTable?.map((rows, index) => (
                                        <TableRow key={index} className={highLightArr.some(item => JSON.stringify(item) === JSON.stringify({ "paymentId": rows.paymentId, "subPaymentId": rows.subPaymentId })) ? classes.tableRowHaveChanged : classes.tableRow}>
                                            < StyledTableCell align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '150%', paddingTop: '12px', paddingBottom: '11px', paddingLeft: "8px", paddingRight: "8px" }}>
                                                {rows.journalName}
                                            </StyledTableCell>
                                            < StyledTableCell align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '150%', paddingTop: '12px', paddingBottom: '11px', paddingLeft: "8px", paddingRight: "8px" }}>
                                                {rows.detail}
                                            </StyledTableCell>
                                            < StyledTableCell align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '150%', ppaddingTop: '12px', paddingBottom: '11px', paddingLeft: "8px", paddingRight: "8px" }}>
                                                {rows.detailBalanceIndicator === "1" ? "貸方" : "借方"}
                                            </StyledTableCell>
                                            < StyledTableCell align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '150%', paddingTop: '12px', paddingBottom: '11px', paddingLeft: "8px", paddingRight: "8px" }}>
                                                {rows.accountSubjectCode}
                                            </StyledTableCell>
                                            < StyledTableCell align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '150%', paddingTop: '12px', paddingBottom: '11px', paddingLeft: "8px", paddingRight: "8px" }}>
                                                {rows.screenDisplay === "1" ? "表示" : "非表示"}
                                            </StyledTableCell>
                                            < StyledTableCell align='center' style={{ borderRight: '1px solid #CCCCCC', paddingTop: '8px', paddingLeft: "27px", paddingRight: "27px", paddingBottom: '7px', }}>
                                                <Button className={classes.editMaster} onClick={() => {
                                                    setValidatingFlag(2)
                                                    setEditData(rows)
                                                    setEditIndex(index)
                                                    setValidatingCodeDialogVisiable(true)
                                                }} >
                                                    マスターを編集する
                                                </Button>
                                            </StyledTableCell>
                                            < StyledTableCell align='center' style={{
                                                borderRight: '1px solid #CCCCCC', paddingTop: '7px',
                                                paddingLeft: "7px", paddingRight: "7px", paddingBottom: '7px'
                                            }}>
                                                <div style={{
                                                    border: '1px solid #CCCCCC', width: '100%', height: '86.5%', borderRadius: '4px'
                                                }}>
                                                    <DeleteForeverIcon className={classes.deleteMaster}
                                                        onClick={() => {
                                                            setValidatingFlag(3)
                                                            setDeleteIndex(index)
                                                            setValidatingCodeDialogVisiable(true)
                                                        }} >
                                                    </DeleteForeverIcon>
                                                </div>
                                            </StyledTableCell>
                                        </TableRow>
                                    )) : masterData !== null && searchContent !== "" ? masterData.masterTable.map((rows, index) => (
                                        rows.journalName.includes(searchContent) || rows.accountSubjectCode.includes(searchContent) ?
                                            <TableRow key={index} className={highLightArr.some(item => JSON.stringify(item) === JSON.stringify({ "paymentId": rows.paymentId, "subPaymentId": rows.subPaymentId })) ? classes.tableRowHaveChanged : classes.tableRow}>
                                                < StyledTableCell align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '150%', paddingTop: '12px', paddingBottom: '11px', paddingLeft: "8px", paddingRight: "8px" }}>
                                                    {rows.journalName}
                                                </StyledTableCell>
                                                < StyledTableCell align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '150%', paddingTop: '12px', paddingBottom: '11px', paddingLeft: "8px", paddingRight: "8px" }}>
                                                    {rows.detail}
                                                </StyledTableCell>
                                                < StyledTableCell align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '150%', ppaddingTop: '12px', paddingBottom: '11px', paddingLeft: "8px", paddingRight: "8px" }}>
                                                    {rows.detailBalanceIndicator === "1" ? "借方" : "貸方"}
                                                </StyledTableCell>
                                                < StyledTableCell align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '150%', paddingTop: '12px', paddingBottom: '11px', paddingLeft: "8px", paddingRight: "8px" }}>
                                                    {rows.accountSubjectCode}
                                                </StyledTableCell>
                                                < StyledTableCell align='left' style={{ borderRight: '1px solid #CCCCCC', fontFamily: 'Noto Sans', fontStyle: 'normal', color: "#162337", fontSize: '16px', fontWeight: '400', lineHeight: '150%', paddingTop: '12px', paddingBottom: '11px', paddingLeft: "8px", paddingRight: "8px" }}>
                                                    {rows.screenDisplay === "1" ? "表示" : "非表示"}
                                                </StyledTableCell>
                                                < StyledTableCell align='center' style={{ borderRight: '1px solid #CCCCCC', paddingTop: '8px', paddingLeft: "27px", paddingRight: "27px", paddingBottom: '7px', }}>
                                                    <Button className={classes.editMaster} onClick={() => {
                                                        setValidatingFlag(2)
                                                        setEditData(rows)
                                                        setEditIndex(index)
                                                        setValidatingCodeDialogVisiable(true)
                                                    }} >
                                                        マスターを編集する
                                                    </Button>
                                                </StyledTableCell>
                                                < StyledTableCell align='center' style={{
                                                    borderRight: '1px solid #CCCCCC', paddingTop: '7px',
                                                    paddingLeft: "7px", paddingRight: "7px", paddingBottom: '7px'
                                                }}>
                                                    <div style={{
                                                        border: '1px solid #CCCCCC', width: '100%', height: '86.5%', borderRadius: '4px'
                                                    }}>
                                                        <DeleteForeverIcon className={classes.deleteMaster}
                                                            onClick={() => {
                                                                setValidatingFlag(3)
                                                                setDeleteIndex(index)
                                                                setValidatingCodeDialogVisiable(true)
                                                            }} >
                                                        </DeleteForeverIcon>
                                                    </div>
                                                </StyledTableCell>
                                            </TableRow>
                                            : null
                                    ))
                                        :
                                        null}
                                </TableBody>
                            </Table>
                        </TableContainer >
                    </div>
                </div>
            </Box >
        </>
    )
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(Master);