
const dic = {

    // utc time change to location time
    UTCDateToLocalDate: function(dateStr) {
        if(dateStr.indexOf('.') != -1){
          dateStr = dateStr.substring(0,dateStr.lastIndexOf('.'))
        }
        /// fix safari
        dateStr = dateStr.replace(/-/g, '/')
        var utc_date = new Date(dateStr + ' UTC')
        var local_date = new Date(utc_date.toString())
        var local_date_str = local_date.Format('yyyy/MM/dd hh:mm:ss')
        return local_date_str;
    },

    // get s3 bucket key from image url
    GetUrlParams: function(url){
        if(url){
            let param = url.split('/')
            if(param[2]){
                let part = []
                param.map((ele, index) => {
                    if(index > 2){
                        part.push(ele)
                    }
                })
                return part.join('/')
            }else{
                return ""
            }
        }else{
            return ""
        }
    },

    getUrlParam(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURI(r[2]);
        return null;
    },
 
    /*
    * changeURLStatic  don`t jump
    *   @param name 
    *   @param value 
    *
    * */
    changeURLStatic(name, value) {
        var url = window.location.href;
        var reg = eval('/([\?|&]' + name + '=)[^&]*/gi');
        value = value.toString().replace(/(^\s*)|(\s*$)/g, "");  //
        if (!value) {
            var url2 = url.replace(reg, '');  //replace
        } else {
            if (url.match(reg)) {
                var url2 = url.replace(reg, '$1' + value);  //replace
            } else {
                var url2 = url + (url.indexOf('?') > -1 ? '&' : '?') + name + '=' + value;  // no param add param
            }
        }
        window.history.replaceState(null, null, url2);  // replace local url
    },

    // Shallow comparison of objects
    shallowEqual(object1, object2) {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
      
        if (keys1.length !== keys2.length) {
          return false;
        }
      
        for (let index = 0; index < keys1.length; index++) {
          const val1 = object1[keys1[index]];
          const val2 = object2[keys2[index]];
          if (val1 !== val2) {
            return false;
          }
        }
      
        return true;
    },

    hexColorToRGBA(hexColor,alpho) {
        var sColor = hexColor.toLowerCase();
        //十六进制颜色值的正则表达式
        var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
        // 如果是16进制颜色
        if (sColor && reg.test(sColor)) {
            if (sColor.length === 4) {
                var sColorNew = "#";
                for (var i=1; i<4; i+=1) {
                    sColorNew += sColor.slice(i, i+1).concat(sColor.slice(i, i+1));    
                }
                sColor = sColorNew;
            }
            //处理六位的颜色值
            var sColorChange = [];
            for (var i=1; i<7; i+=2) {
                sColorChange.push(parseInt("0x"+sColor.slice(i, i+2)));    
            }
            return "RGBA(" + sColorChange.join(",") + "," + alpho + ")";
        }
        return sColor;
    },

    getDate(dateStr){
      let temp = dateStr.split('-')
      let date = new Date(temp[0], temp[1] - 1, temp[2])
      return date
    },

    formatEveryDay(start, end){
      let dateList = []
      let startTime = this.getDate(start)
      let endTime = this.getDate(end)

      while ((endTime.getTime() - startTime.getTime()) >= 0) {
        let year = startTime.getFullYear()
        let month = startTime.getMonth() + 1 <10 ? '0' + (startTime.getMonth() + 1) : startTime.getMonth() + 1
        let day = startTime.getDate().toString().length == 1 ? '0' + startTime.getDate() : startTime.getDate()
        
        dateList.push(year + '-' + month + '-' + day)
        startTime.setDate(startTime.getDate() + 1)
      }

      return dateList
    },

    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    }
}

export default dic