import React from "react";
import { TextField } from "@mui/material";
import { withStyles, makeStyles } from "@mui/styles";

const styles = (theme) => ({

});

function BasicTextField(props) {
    const {
        ...rest
    } = props;

    return (
        <TextField
            sx={{
                '& legend': { display: 'none' },
                '& fieldset': { top: 0 },
            }}
            {...rest}
        />
    );
}



export default withStyles(styles, { withTheme: true })(BasicTextField);
