


import React, { useEffect, useState, Fragment } from "react";
import {
    Button
} from "@mui/material";
import { withStyles } from "@mui/styles";

import ButtonCircularProgress from "../basic/ButtonCircularProgress";

const styles = (theme) => ({
    root: {
        fontSize: 16,
        "&:enabled:hover": {
            backgroundColor: "#EF7A00",
            color: "#fff",
        },
    },
});

function SingleSureButton(props) {
    const {
        classes,
        isLoading,
        text,
        size,
        fullWidth,
        variant,
        color,
        ...rest
    } = props

    return (
        <Button
            className={classes.root}
            fullWidth={fullWidth}
            variant={variant}
            color={color}
            disabled={isLoading}
            size={size}
            {...rest}
        >
            {text}
            {isLoading && <ButtonCircularProgress />}
        </Button>
    )
}


SingleSureButton.defaultProps = {
    size: "large",
    fullWidth: true,
    variant: "contained",
    color: "primary"
}

export default withStyles(styles, { withTheme: true })(SingleSureButton);