import React, { useEffect, useState, useReducer } from "react";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NumberFormatCustom from "../../global/component/basic/NumberFormatCustom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import BasicTextfield from "../../global/component/basic/BasicTextfield";
import TextField from '@mui/material/TextField';
import { Error } from "../../global/common/constants";

const Placeholder = ({ children }) => {
    return <div style={{ color: "#aaa" }}>{children}</div>;
};

const styles = (theme) => ({
    dialogPaper: {
        minHeight: "83.335vh",
        maxHeight: "83.335vh",
        width: "62.5%",
        maxWidth: "63.5%",
    },
    title: {
        textAlign: "center",
        fontFamily: "Noto Sans",
        color: "#162337",
        fontSize: "24px",
        lineHeight: "24px",
        fontWeight: "700",
        marginTop: "30px",
    },
    alert: {
        backgroundColor: "#FACCD3",
        color: "#E60024",
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "100%",
        paddingTop: "16px",
        paddingBottom: "16px"
    },
    inputContent: {
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%',
        color: "#162337",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "16px",
        paddingRight: "16px"
    },
    screenDisplayItem: {
        height: '124px',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%',
        color: "#162337",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "16px",
        paddingRight: "16px"
    },
    leftContent: {
        paddingLeft: "7px", paddingRight: "7px", border: '1px solid #CCCCCC', paddingBottom: "15px", paddingTop: "16px", fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '700',
        lineHeight: '150%', width: "22.548%", backgroundColor: "#162337", color: "#FFFFFF"
    },
    rightContent: {
        width: "45%", border: '1px solid #CCCCCC', backgroundColor: "#FFFFFF", padding: "8px",
    },
    selectContent: {
        width: "100%",
        padding: "8px",
        paddingLeft: "2px",
        fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%', color: "#162337",
        height: "39px",

    },
    itemContent: {
        padding: "8px", fontFamily: 'Noto Sans',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%', color: "#162337",
    },
    leftButton: {
        fontSize: "16px",
        width: "112px",
        height: "48px",
        marginLeft: "20px",
        marginRight: "auto",
    },
    rightButton: {
        fontSize: "16px",
        width: "112px",
        height: "48px",
        marginRight: "15px",
        color: "#FFFFFF",
    },
    input: {
        height: 120,
        backgroundColor: "#ffffff",
    },
    root: {
        [`& fieldset`]: {
            borderRadius: 4,
        },
        '& .MuiInputBase-root': {
            color: '#000000',
        },
    },
    lableSuffix: {
        marginBottom: 0,
        fontSize: 14,
        color: "#E60024",
        marginLeft: theme.spacing(0.5),
    },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({

}));

function MasterEditDialog(props) {
    const { classes, editDialogVisible, closeEditDialog, editDataFromEditDialog, masterData, editData, setEditData, editIndex } = props;
    const [placeName, setPlaceName] = useState("");
    const forceUpdate = useReducer((bool) => !bool)[1];



    const [errorMsg, setErrorMsg] = useState([]);
    const handleDetailBalanceIndicatorChange = (value) => {
        setEditData({ ...editData, detailBalanceIndicator: value })
    };
    const handleExtensionChange = (value) => {
        setEditData({ ...editData, extension: value })
    };
    const handleTaxationDivisionChange = (value) => {
        setEditData({ ...editData, taxationDivision: value })
    };
    const handleSaleTaxDivisionChange = (value) => {
        setEditData({ ...editData, saleTaxDivision: value })
    };
    const handleSaleTaxRateDivisionChange = (value) => {
        setEditData({ ...editData, saleTaxRateDivision: value })
    };
    const handleAccountingDepartmentChange = (value) => {
        setEditData({ ...editData, accountingDepartment: value })

    };
    const handleDetailChange = (value) => {
        setEditData({ ...editData, detail: value })
    };

    const handleScreenDisplayChange = (value) => {
        setEditData({ ...editData, screenDisplay: value })
    };

    const handleInputChange = (value) => {
        setEditData({ ...editData, input: value })
    };

    const handleAccountCode = (value) => {
        value = value.replace(/[^\d]/g, '');
        setEditData({ ...editData, accountSubjectCode: value })
    }

    const handlePaymentId = (value) => {
        setEditData({ ...editData, paymentId: value })
    }

    const handleSubPaymentId = (value) => {
        setEditData({ ...editData, subPaymentId: value })
    }

    const handleScreenDisplayItemChange = (value) => {
        setEditData({ ...editData, screenDisplayItem: value })
    };

    const handleJournalNameChange = (value) => {
        setEditData({ ...editData, journalName: value })

    };

    const closeNewDialog = () => {
        setErrorMsg([])
        closeEditDialog()
    }
    const add = () => {
        checkPaymentIdDuplicate()
        if (
            checkEmpty(editData.journalName) ||
            checkEmpty(editData.paymentId) ||
            checkEmpty(editData.accountingDepartment) ||
            checkEmpty(editData.accountSubjectCode) ||
            checkEmpty(editData.taxationDivision) ||
            checkEmpty(editData.screenDisplayItem)

        ) {
            if (!errorMsg.includes(Error.E0043)) {
                errorMsg.push(Error.E0043)
                forceUpdate()
            }
        } else {
            if (errorMsg.includes(Error.E0043)) {
                errorMsg.splice(errorMsg.indexOf(Error.E0043), 1)
                forceUpdate()
            }
        }
        if (errorMsg?.length > 0) {
            return
        } else {
            setErrorMsg([])
            editDataFromEditDialog(editData)
        }

    }
    function checkEmpty(value) {
        if (value == "" || !value) {
            return true
        } else {
            return false
        }
    }


    const checkPaymentIdDuplicate = (value) => {
        if (masterData?.masterTable?.length > 0) {
            for (var idx = 0; idx < masterData.masterTable.length; idx++) {
                if (masterData.masterTable[idx].paymentId == editData.paymentId && masterData.masterTable[idx].subPaymentId == editData.subPaymentId && idx != editIndex) {
                    if (!errorMsg.includes("取引コード、サブ取引番号が重複しています。")) {
                        errorMsg.push("取引コード、サブ取引番号が重複しています。")
                        forceUpdate()
                    }
                    return
                } else {
                    if (errorMsg.includes("取引コード、サブ取引番号が重複しています。")) {
                        errorMsg.splice(errorMsg.indexOf("取引コード、サブ取引番号が重複しています。"), 1)
                        forceUpdate()
                    }
                }
            }
        }
    }

    // 画面初期化処理
    useEffect(() => {
    }, []);
    return (
        <div>
            <Dialog
                open={editDialogVisible}
                scroll="paper"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle>
                    <div className={classes.title}>マスター編集</div>
                    <IconButton
                        aria-label="close"
                        onClick={() => closeNewDialog()}
                        size="large"
                        sx={{
                            position: "absolute",
                            right: 12,
                            top: 30,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ marginLeft: "16px", marginRight: "8px" }}>
                    {errorMsg?.length > 0 && errorMsg.map((rows, index) => (
                        <div key={index} style={{ marginBottom: "2px" }}>
                            <div style={{ display: "flex", float: "left", marginRight: "16px" }}>
                                <InfoOutlinedIcon
                                    style={{
                                        background: "#FACCD3",
                                        color: "#E60024",
                                        marginLeft: "16px",
                                        marginTop: "16px",
                                        width: "19.21px",
                                        height: "19.21px",
                                    }}
                                ></InfoOutlinedIcon>
                            </div>
                            <div key={index} className={classes.alert}> {rows}</div>
                        </div>
                    ))}
                    <Table style={{ borderRadius: "0px", marginTop: "8px" }}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    <div style={{ display: "flex" }}>
                                        仕訳名称
                                        <div className={classes.lableSuffix}>*</div>
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell align="center" className={classes.rightContent} >
                                    <BasicTextfield style={{ width: '100%', }}
                                        value={editData.journalName ? editData.journalName : ""}
                                        onChange={(event) => { handleJournalNameChange(event.target.value ?? "") }}
                                        inputProps={{
                                            maxLength: 20,
                                        }}
                                        InputProps={{
                                            placeholder: "仕訳名称を入力してください",
                                            classes: {
                                                input: classes.inputContent,
                                            },
                                        }}
                                    />
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    明細貸借区分
                                </StyledTableCell>

                                <StyledTableCell align="left" className={classes.rightContent} >
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        className={classes.selectContent}
                                        defaultValue={editData.detailBalanceIndicator}
                                        onChange={(event) => { handleDetailBalanceIndicatorChange(event.target.value) }}
                                    >
                                        <MenuItem className={classes.itemContent} value={"0"} selected={true}>借方</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"1"}>貸方</MenuItem>
                                    </Select>
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    <div style={{ display: "flex" }}>
                                        勘定科目コード
                                        <div className={classes.lableSuffix}>*</div>
                                    </div>
                                </StyledTableCell>

                                <StyledTableCell align="center" className={classes.rightContent} >
                                    <BasicTextfield style={{ width: '100%', }}
                                        value={editData.accountSubjectCode ? editData.accountSubjectCode : ""}
                                        onChange={(event) => { handleAccountCode(event.target.value ?? "") }}
                                        inputProps={{
                                            maxLength: 5,
                                        }}
                                        InputProps={{
                                            placeholder: "勘定科目コードを入力してください",
                                            classes: {
                                                input: classes.inputContent,
                                            },

                                        }}
                                    />
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    <div style={{ display: "flex" }}>
                                        取引コード
                                        <div className={classes.lableSuffix}>*</div>
                                    </div>
                                </StyledTableCell>

                                <StyledTableCell align="center" className={classes.rightContent} >
                                    <BasicTextfield style={{ width: '100%', }}
                                        value={editData.paymentId ? editData.paymentId : ""}
                                        name="numberformat"
                                        inputProps={{
                                            maxLength: Number(editData.paymentId) > 0 ? 3 : 4,
                                        }}
                                        onChange={(event) => { handlePaymentId(event.target.value) }}
                                        InputProps={{
                                            placeholder: "取引コードを入力してください",
                                            classes: {
                                                input: classes.inputContent,
                                            },
                                            inputComponent: NumberFormatCustom,
                                        }}
                                    />
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    サブ取引番号
                                </StyledTableCell>

                                <StyledTableCell align="center" className={classes.rightContent} >
                                    <BasicTextfield style={{ width: '100%', }}
                                        value={editData.subPaymentId ? editData.subPaymentId : ""}
                                        name="numberformat"
                                        inputProps={{
                                            maxLength: Number(editData.subPaymentId) > 0 ? 3 : 4,
                                        }}
                                        onChange={(event) => { handleSubPaymentId(event.target.value) }}
                                        InputProps={{
                                            placeholder: "サブ取引番号を入力してください",
                                            classes: {
                                                input: classes.inputContent,
                                            },
                                            inputComponent: NumberFormatCustom,
                                        }}
                                    />
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    <div style={{ display: "flex" }}>
                                        会計部門
                                        <div className={classes.lableSuffix}>*</div>
                                    </div>
                                </StyledTableCell>

                                <StyledTableCell align="left" className={classes.rightContent} >
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        className={classes.selectContent}
                                        displayEmpty
                                        input={<OutlinedInput />}
                                        onChange={(event) =>
                                            handleAccountingDepartmentChange(event.target.value)
                                        }
                                        value={editData.accountingDepartment ?? ""}
                                        renderValue={
                                            editData.accountingDepartment !== null ? undefined : () => <Placeholder>会計部門を選択してください</Placeholder>
                                        }
                                    >
                                        <MenuItem className={classes.itemContent} value={"店コード"}>店コード</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"000000"}>000000</MenuItem>
                                    </Select>
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    細目
                                </StyledTableCell>

                                <StyledTableCell align="left" className={classes.rightContent} >
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        className={classes.selectContent}
                                        displayEmpty
                                        input={<OutlinedInput />}
                                        onChange={(event) =>
                                            handleDetailChange(event.target.value)
                                        }
                                        value={editData.detail == null ? "" : editData.detail == "0" ? " " : editData.detail}
                                        renderValue={
                                            editData.detail !== null ? undefined : () => <Placeholder>細目を選択してください</Placeholder>
                                        }
                                    >
                                        <MenuItem className={classes.itemContent} value={"店コード"} >店コード</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"101"} >101</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"102"}>102</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"103"} >103</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"104"}>104</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"107"}>107</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"109"} >109</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"110"}>110</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"999"} >999</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"0"}>空白指定</MenuItem>
                                    </Select>
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    消費税区分
                                </StyledTableCell>

                                <StyledTableCell align="left" className={classes.rightContent} >
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        className={classes.selectContent}
                                        displayEmpty
                                        input={<OutlinedInput />}
                                        onChange={(event) =>
                                            handleSaleTaxDivisionChange(event.target.value)
                                        }
                                        value={editData.saleTaxDivision == null ? "" : editData.saleTaxDivision == "0" ? " " : editData.saleTaxDivision}
                                        renderValue={
                                            editData.saleTaxDivision !== null ? undefined : () => <Placeholder>消費税区分を選択してください</Placeholder>
                                        }
                                    >
                                        <MenuItem className={classes.itemContent} value={"50"} >50</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"20"} >20</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"1D"} >1D</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"10"} >10</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"0"}>空白指定</MenuItem>
                                    </Select>
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    消費税率区分
                                </StyledTableCell>

                                <StyledTableCell align="left" className={classes.rightContent} >
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        className={classes.selectContent}
                                        displayEmpty
                                        input={<OutlinedInput />}
                                        onChange={(event) =>
                                            handleSaleTaxRateDivisionChange(event.target.value)
                                        }
                                        value={editData.saleTaxRateDivision == null ? "" : editData.saleTaxRateDivision == "0" ? " " : editData.saleTaxRateDivision}
                                        renderValue={
                                            editData.saleTaxRateDivision !== null ? undefined : () => <Placeholder>消費税率区分を選択してください</Placeholder>
                                        }
                                    >
                                        <MenuItem className={classes.itemContent} value={"10"} >10</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"8"} >8</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"5"} >5</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"0"}>空白指定</MenuItem>
                                    </Select>
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    <div style={{ display: "flex" }}>
                                        課税区分
                                        <div className={classes.lableSuffix}>*</div>
                                    </div>
                                </StyledTableCell>

                                <StyledTableCell align="left" className={classes.rightContent} >
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        className={classes.selectContent}
                                        displayEmpty
                                        input={<OutlinedInput />}
                                        onChange={(event) =>
                                            handleTaxationDivisionChange(event.target.value)
                                        }
                                        value={editData.taxationDivision ?? ""}
                                        renderValue={
                                            editData.taxationDivision !== null ? undefined : () => <Placeholder>課税区分を選択してください</Placeholder>
                                        }
                                    >
                                        <MenuItem className={classes.itemContent} value={"3"} >3</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"0"}>0</MenuItem>
                                    </Select>
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    拡張
                                </StyledTableCell>

                                <StyledTableCell align="left" className={classes.rightContent} >
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        className={classes.selectContent}
                                        displayEmpty
                                        input={<OutlinedInput />}
                                        onChange={(event) =>
                                            handleExtensionChange(event.target.value)
                                        }
                                        value={editData.extension == null ? "" : editData.extension == "0" ? " " : editData.extension}
                                        renderValue={
                                            editData.extension !== null ? undefined : () => <Placeholder>拡張を選択してください</Placeholder>
                                        }
                                    >
                                        <MenuItem className={classes.itemContent} value={"999"} >999</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"0"}>空白指定</MenuItem>
                                    </Select>
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    画面表示
                                </StyledTableCell>

                                <StyledTableCell align="left" className={classes.rightContent} >
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        className={classes.selectContent}
                                        defaultValue={editData.screenDisplay}
                                        onChange={(event) =>
                                            handleScreenDisplayChange(event.target.value)
                                        }
                                    >
                                        <MenuItem className={classes.itemContent} value={"1"} selected={true}>表示</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"0"}>非表示</MenuItem>
                                    </Select>
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    入力
                                </StyledTableCell>

                                <StyledTableCell align="left" className={classes.rightContent} >
                                    <Select
                                        sx={{
                                            '& legend': { display: 'none' },
                                            '& fieldset': { top: 0 },
                                        }}
                                        className={classes.selectContent}
                                        defaultValue={editData.input}
                                        onChange={(event) =>
                                            handleInputChange(event.target.value)
                                        }
                                    >
                                        <MenuItem className={classes.itemContent} value={"1"} selected={true}>可</MenuItem>
                                        <MenuItem className={classes.itemContent} value={"0"}>不可</MenuItem>
                                    </Select>
                                </StyledTableCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableCell align="left" className={classes.leftContent} >
                                    <div style={{ display: "flex" }}>
                                        画面表示項目
                                        <div className={classes.lableSuffix}>*</div>
                                    </div>
                                </StyledTableCell>

                                <StyledTableCell align="left" className={classes.rightContent} >
                                    <TextField
                                        placeholder="画面表示項目を入力してください"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        InputProps={{
                                            classes: {
                                                input: classes.screenDisplayItem,
                                            }
                                        }}
                                        value={editData.screenDisplayItem ? editData.screenDisplayItem : ""}
                                        onChange={(event) => { handleScreenDisplayItemChange(event.target.value) }}
                                        className={classes.root}
                                    />
                                </StyledTableCell>
                            </TableRow>

                        </TableHead>
                    </Table>

                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => closeNewDialog()}
                        color="orange"
                        className={classes.leftButton}
                    >
                        キャンセル
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => add()}
                        color="orange"
                        className={classes.rightButton}
                    >
                        更新
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default withStyles(styles, { withTheme: true })(MasterEditDialog);
