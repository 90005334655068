import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { RouterString } from "../../../global/common/constants";
import NumberFormat from "react-number-format";
import { OverViewAPI } from "../../../network/api";
import DetailTableOne from "./DetailTableOne";
import DetailTableTwo from "./DetailTableTwo";
import { useNavigate } from "react-router-dom";
import { useMounted } from "../../../global/function/useMounted";
import { getOffpaymentMasterAPI } from "../../../network/api";
import { getTenantcreditMasterAPI } from "../../../network/api";
import BaseCircularProgress from "../../../global/component/basic/BaseCircularProgress";
import { localConstants, Error } from "../../../global/common/constants";

// 画面レイアウトスタイル
const styles = (theme) => ({
  contentFont: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#002239",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
  },
});

//　画面入り口
function Details(props) {
  const { classes, setSelectedTab, storeData } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [offCreditMaster, setOffCreditMaster] = useState([]);
  const isMounted = useMounted()
  const [tenantCreditMaster, setTenantCreditMaster] = useState([]);

  const navigate = useNavigate();

  const getOffpaymentMasterData = () => {
    setIsLoading(true);
    getOffpaymentMasterAPI().then((res) => {
      if (!isMounted()) return
      console.log(res)
      let resData = res.data
      if (res.status >= 400 && res.status < 500) {
        alert(Error.E0003);
      } else if (res.status == 200) {
        setOffCreditMaster(resData)
      }
      setIsLoading(false)
    }).catch((err) => {
      if (!isMounted()) return
      setIsLoading(false)
    })

  }

  const getTenantCreditMasterData = () => {
    setIsLoading(true);
    getTenantcreditMasterAPI().then((res) => {
      if (!isMounted()) return
      let resData = res.data
      if (res.status >= 400 && res.status < 500) {
        alert(Error.E0003);
      } else if (res.status == 200) {
        setTenantCreditMaster(resData)
      }
      setIsLoading(false)
    }).catch((err) => {
      if (!isMounted()) return
      setIsLoading(false)
    })

  }

  // 画面初期化データ取得
  useEffect(() => {
    if (!storeData.nyukinki?.reportData) {
      navigate(RouterString.Report)
    }
    getOffpaymentMasterData()
    getTenantCreditMasterData()
    setSelectedTab(RouterString.Details);
  }, [setSelectedTab]);




  return (
    <Box>
      {isLoading && <BaseCircularProgress />}
      <div
        style={{
          height: "5%",
          marginLeft: "2.308%",
          marginTop: "2.308%",
          fontFamily: "Noto Sans JP",
          fontStyle: "Bold",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "150%",
          color: "#162337",
        }}
      >
        内訳
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            width: "23.077%",
            height: "3.571%",
            marginLeft: "2.308%",
          }}
        >
          <div>
            <Typography className={classes.contentFont}>営業日現金</Typography>
          </div>
          <div>
            <Typography
              className={classes.contentFont}
              style={{
                fontFamily: "Noto Sans JP",
                fontSize: "28px",
                lineHeight: "100%",
                borderBottom: "1px solid #CCCCCC",
                paddingBottom: "3px",
                textAlign: "right",
              }}
            >
              <NumberFormat
                value={

                  storeData.nyukinki?.overviewData ? storeData.nyukinki?.overviewData.overViewTableOne.businessDayCash : 0
                }
                displayType={"text"}
                thousandSeparator={true}
              />
              <span style={{ fontSize: "16px" }}>{`\u00A0\u00A0円`}</span>
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: "23.077%",
            height: "3.571%",
            marginLeft: "2.308%",
          }}
        >
          <div>
            <Typography className={classes.contentFont}>入金額</Typography>
          </div>
          <div>
            <Typography
              className={classes.contentFont}
              style={{
                fontFamily: "Noto Sans JP",
                fontSize: "28px",
                lineHeight: "100%",
                borderBottom: "1px solid #CCCCCC",
                paddingBottom: "3px",
                textAlign: "right",
              }}
            >
              <NumberFormat
                value={
                  storeData.nyukinki?.overviewData ? storeData.nyukinki?.overviewData.overViewTableOne.depositAmount : 0
                }
                displayType={"text"}
                thousandSeparator={true}
              />
              <span style={{ fontSize: "16px" }}>{`\u00A0\u00A0円`}</span>
            </Typography>
          </div>
        </div>
      </div>

      {/*  入金調整額テーブル１ */}
      {storeData.nyukinki?.overviewData?.overViewTableOne && (
        <div
          style={{
            width: "95.385%",
            height: "20.238%",
            marginLeft: "2.308%",
            marginTop: "24px",
          }}
        >
          <DetailTableOne storeData={storeData} />
        </div>
      )}

      {/*  入金調整額テーブル2 */}
      {storeData.nyukinki?.overviewData?.overViewTableTwo && (
        <div style={{ marginLeft: "2.308%", marginTop: "2.308%" }}>
          <DetailTableTwo storeData={storeData} offCreditMaster={offCreditMaster} tenantCreditMaster={tenantCreditMaster} />
        </div>
      )}
    </Box>
  );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(Details);
