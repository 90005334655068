import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { RouterString } from "../../../global/common/constants";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getUnSubmittedStoresAPI } from "../../../network/api";
import { useMounted } from "../../../global/function/useMounted";
import BaseCircularProgress from "../../../global/component/basic/BaseCircularProgress";
import { useNavigate } from "react-router-dom";
import { localConstants, Error } from "../../../global/common/constants";
import Alert from "@mui/material/Alert";

// 画面スタイル
const styles = (theme) => ({
  headRow: {
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
    padding: "8px",
  },
  cell: {
    width: "140px",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "8px",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#162337",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    backgroundColor: "#FFFFFF",
  },
}));

// 日報提出状況画面入り口
function UnsubmittedStores(props) {
  const { classes, setSelectedTab,
    accountingData,
    setAccountingData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const isMounted = useMounted()
  const navigate = useNavigate();
  const [message, setMessage] = useState(false);

  const getUnSubmittedStoresData = () => {
    setIsLoading(true);
    let param = {
      businessDay: localStorage.getItem(localConstants.BusinessDay)
    }
    console.log(param)
    getUnSubmittedStoresAPI(param).then((res) => {
      if (!isMounted()) return
      console.log(res)
      let resData = res.data
      if (res.status >= 400 && res.status < 500) {
        alert(Error.E0003);
      } else if (res.status == 200) {
        resData.sort(up);
        setData(resData)
        if (resData?.length > 0) {
          setMessage(false)
        } else {
          setMessage(true)
        }
      }
      setIsLoading(false)
    }).catch((err) => {
      if (!isMounted()) return
      setIsLoading(false)
    })

  }
  function up(a, b) {
    return a.storeId - b.storeId
  }
  useEffect(() => {
    if (!localStorage.getItem(localConstants.BusinessDay)) {
      navigate(RouterString.ReportStatus)
      return
    }
    setSelectedTab(RouterString.UnsubmittedStores);
    getUnSubmittedStoresData()
  }, [setSelectedTab]);

  return (
    <>
      {isLoading && <BaseCircularProgress />}
      <div
        style={{
          marginLeft: "25px",
          marginTop: "25px",
          fontFamily: "Noto Sans JP",
          fontStyle: "Bold",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "150%",
          color: "#162337",
        }}
      >
        未提出店舗
      </div>
      {message &&
        <Alert
          severity="info"
          className={classes.alert}
          style={{ width: "100%" }}
        >
          未提出の店舗がありません。
        </Alert>
      }

      {data?.length > 0 && <Table style={{ margin: "24px", width: "calc(100% - 48px)" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell
              align="center"
              className={classes.headRow}
              style={{ width: "15%" }}
            >
              店舗コード
            </StyledTableCell>
            <StyledTableCell
              className={classes.headRow}
              align="center"
              style={{ width: "85%" }}
            >
              店舗名
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 && data?.map((rows, index) => (
            <TableRow key={index}>
              <StyledTableCell align="left" className={classes.cell} style={{ borderLeft: "1px solid #CCCCCC" }}>
                {rows.storeId}
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                {rows.storeName}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>}
    </>
  );
}

export default withStyles(styles, { withTheme: true })(UnsubmittedStores);
