import React, { useState } from "react";
import { withStyles } from "@mui/styles";
import {
    Box,
    Typography,
    Button
} from "@mui/material";
import StripeTextField from "../../global/component/basic/StripeTextField";
import SingleSureButton from "../../global/component/dialog/SingleSureButton";
import { CognitoLoginUrl } from "../../global/common/constants";
// 画面スタイル
const styles = (theme) => ({
    content: {
        textAlign: "center",
        fontFamily: "Noto Sans",
        color: "#231815",
        fontSize: "30px",
        lineHeight: "26px",
        fontWeight: "700",
    },
    button: {
        width: "228px",
        height: "64px"
    },
});

// ログイン Error 画面入り口
function LoginError(props) {
    const {
        classes,
    } = props;
    const jumpToLogin = () => {
        window.location.href = CognitoLoginUrl.logoutUrl
    }
    // 画面表示
    return (
        <div style={{
            display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginTop: "63px"
        }}>
            <div className={classes.content}>  スクリプト実行中にエラーが発生しました。</div>
            <div className={classes.content} style={{ marginTop: "20px", marginBottom: "40px" }}>   IT本部にご連絡ください。</div>
            <img
                src={`${process.env.PUBLIC_URL + "/images/login_error.png"}`}
            />
            <div style={{
                height: "55px"
            }} />
            <Button
                variant="contained"
                onClick={() => {
                    jumpToLogin()
                }}
                color="orange"
                className={classes.button}
            >
                <Typography
                    style={{
                        color: "#FFFFFF",
                        fontWeight: 700,
                        fontSize: "20px"
                    }}
                >
                    ログアウト
                </Typography>
            </Button>
        </div>
    );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(LoginError);
