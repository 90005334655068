import React, { useEffect, useState } from "react";
import { Typography, CircularProgress, Stack, Backdrop } from "@mui/material";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, Modal } from "antd";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useMounted } from "../../../global/function/useMounted";
import { localConstants, Error } from "../../../global/common/constants";
import {
  accountingATableOutputDataAPI,
  accountingShiwakeOutputAPI,
  accountingS3OneDayAPI,
} from "../../../network/api";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import dic from "../../../global/common/util";
import axios from "axios";

const styles = (theme) => ({
  title: {
    textAlign: "center",
    fontFamily: "Noto Sans",
    color: "#162337",
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: "700",
  },
  headerCell: {
    borderTop: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "100%",
    padding: "8px",
  },
  bodyCell: {
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "8px",
  },
  negativeNumber: {
    borderTop: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
    textAlign: "right",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingRight: "8px",
    color: "red",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    ineHeight: "150%",
  },
  positiveNumber: {
    borderTop: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
    textAlign: "right",
    paddingTop: "8px",
    paddingRight: "8px",
    paddingBottom: "8px",
    color: "#162337",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    ineHeight: "150%",
  },
  leftButton: {
    width: "120px",
    height: "48px",
    marginLeft: "20px",
    marginRight: "auto",
    fontSize: "16px",
  },
  rightButton: {
    width: "120px",
    height: "48px",
    marginRight: "15px",
    fontSize: "16px",
    color: "#FFFFFF",
  },
  dateContent: {
    height: "40px",
    textAlign: "left",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "16px",
    color: "#162337",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
  },
  timeTitle: {
    color: "#002239",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
  },
  timeMid: {
    color: "#002239",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "55px",
  },
  alert: {
    backgroundColor: "#FACCD3",
    color: "#E60024",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "100%",
    paddingTop: "16px",
    paddingBottom: "16px",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#162337",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    backgroundColor: "#FFFFFF",
  },
}));

function BySubjectDialog(props) {
  const { classes, dialogVisible, closeDialog } = props;

  const [data, setData] = useState();
  const isMounted = useMounted();
  const [checkAll, setCheckAll] = useState(false);
  const [errorFlg, setErrorFlg] = useState(false);
  const [checkedFlg, setCheckedFlg] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  const [dateTo, setDateTo] = useState(
    localStorage.getItem(localConstants.BusinessDay)
  );
  const [dateFrom, setDateFrom] = useState(
    localStorage.getItem(localConstants.BusinessDay)
  );

  // 画面初期化データ取得
  useEffect(() => {
    getATableOutputData(
      localStorage.getItem(localConstants.BusinessDay),
      localStorage.getItem(localConstants.BusinessDay)
    );
  }, []);

  useEffect(() => {
    for (let index = 0; index < data?.length; index++) {
      if (data[index].checked) {
        setCheckedFlg(true);
        break;
      } else {
        setCheckedFlg(false);
      }
    }
  }, [data]);

  const getATableOutputData = (stDate, edDate) => {
    let param = {
      startDate: stDate,
      endDate: edDate,
    };
    accountingATableOutputDataAPI(param)
      .then((res) => {
        if (!isMounted()) return;
        let resData = res.data;
        if (res.status >= 400 && res.status < 500) {
          alert(Error.E0003);
        } else if (res.status === 200) {
          resData.forEach((item) => {
            item["checked"] = false;
          });
          setData(resData);
          setCheckedFlg(false);
        }
      })
      .catch((err) => {
        if (!isMounted()) return;
      });
  };

  function handleCheckAll() {
    setData(
      data.map((e) => {
        e.checked = !checkAll;
        return e;
      })
    );
    setCheckAll(!checkAll);
  }

  function handleCheck(value, index) {
    data[index].checked = value;
    setData([...data]);
  }

  const [loading, setLoading] = useState(false);
  const [circularLoading, setCircularLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const output = (stDate, edDate) => {
    setLoadingText("s3 ファイルを読み取っています");
    let codeList = [];
    data.forEach((item) => {
      if (item.checked) {
        codeList.push(item.storeId);
        setErrorFlg(false);
      }
    });
    if (codeList.length == 0) {
      return;
    }

    let dateList = dic.formatEveryDay(stDate, edDate);
    let param = [];
    if (codeList.length > 20) {
      let groupedArray = dic.group(codeList, Math.ceil(codeList.length / 6));
      dateList.forEach((date) => {
        groupedArray.forEach((arr) => {
          param.push(accountingS3OneDayAPI({ date, codeList: arr, type: 1 }));
        });
      });
    } else {
      dateList.forEach((date) => {
        param.push(accountingS3OneDayAPI({ date, codeList, type: 1 }));
      });
    }
    setLoading(true);
    setCircularLoading(true);
    axios
      .all(param)
      .then(
        axios.spread((...args) => {
          setLoadingText("仕訳ファイルをgloviaファイルサーバに出力しています");
          let data = {};
          for (const arg of args) {
            if (arg.status == 200) {
              let temp = arg.data;
              if (data[temp.date]) {
                data[temp.date].push(...temp.data);
              } else {
                data[temp.date] = temp.data;
              }
            }
          }

          let param2 = []
          for (const key in data) {
            if (data[key].length > 0) {
              param2.push(accountingShiwakeOutputAPI({ date: key, data: data[key] }));
            }
          }

          if (param2.length > 0) {
            axios.all(param2).then(
              axios.spread((...args) => {
                for (const arg of args) {
                  if (arg.status != 200) {
                    alert(Error.E0003);
                    setLoading(false);
                    setCircularLoading(false);
                    return
                  }
                }
                alert("仕訳ファイルをgloviaファイルサーバに出力しました。");
                setLoading(false);
                setCircularLoading(false);
              })
            );
          } else {
            alert(Error.E0003);
            setLoading(false);
            setCircularLoading(false);
          }
        })
      )
      .catch((err) => {
        alert(Error.E0003);
        setLoading(false);
        setCircularLoading(false);
      });
  };

  function setTimeFrom(value) {
    setDateFrom(value);
    const endTime = new Date(dateTo);
    const startTime = new Date(value);
    var nowDate = new Date();
    if (nowDate.getTime() <= startTime.getTime()) {
      setErrorFlg(true);
      setErrMsg(" 開始日は過去の日付を入力してください。");
    } else if (nowDate.getTime() <= endTime.getTime()) {
      setErrorFlg(true);
      setErrMsg("終了日は過去の日付を入力してください。");
    } else if (endTime.getTime() < startTime.getTime()) {
      setErrorFlg(true);
      setErrMsg("開始日は終了日より過去の日付を入力してください。");
    } else {
      setErrorFlg(false);
      getATableOutputData(
        moment(value).format("YYYY-MM-DD"),
        moment(dateTo).format("YYYY-MM-DD")
      );
    }
  }
  function setTimeTo(value) {
    setDateTo(value);
    const endTime = new Date(value);
    const startTime = new Date(dateFrom);
    var nowDate = new Date();
    if (nowDate.getTime() <= startTime.getTime()) {
      setErrorFlg(true);
      setErrMsg(" 開始日は過去の日付を入力してください。");
    } else if (nowDate.getTime() <= endTime.getTime()) {
      setErrorFlg(true);
      setErrMsg("終了日は過去の日付を入力してください。");
    } else if (endTime.getTime() < startTime.getTime()) {
      setErrorFlg(true);
      setErrMsg("開始日は終了日より過去の日付を入力してください。");
    } else {
      setErrorFlg(false);
      getATableOutputData(
        moment(dateFrom).format("YYYY-MM-DD"),
        moment(value).format("YYYY-MM-DD")
      );
    }
  }

  return (
    <Modal
      centered
      open={dialogVisible}
      closable={false}
      footer={null}
      width="62.5%"
    >
      {circularLoading && (
        <Backdrop sx={{ color: "#000", zIndex: 10001 }} open={circularLoading}>
          <Stack
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10000,
              color: "#fff",
            }}
          >
            <CircularProgress color="orange" size="4rem" />
            <Typography style={{ fontSize: "20px" }}>{loadingText}</Typography>
            <Typography style={{ fontSize: "20px" }}>
              しばらくお待ちください
            </Typography>
          </Stack>
        </Backdrop>
      )}
      <div style={{ height: "80vh" }}>
        <DialogTitle>
          <div className={classes.title}>仕訳出力</div>
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            size="large"
            sx={{
              position: "absolute",
              right: 40,
              top: 20,
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </DialogTitle>
        {errorFlg == true && errMsg && (
          <div style={{ marginBottom: "2px" }}>
            <div
              style={{ display: "flex", float: "left", marginRight: "16px" }}
            >
              <InfoOutlinedIcon
                style={{
                  background: "#FACCD3",
                  color: "#E60024",
                  marginLeft: "16px",
                  marginTop: "16px",
                  width: "19.21px",
                  height: "19.21px",
                }}
              ></InfoOutlinedIcon>
            </div>
            <div className={classes.alert}> {errMsg}</div>
          </div>
        )}

        <DialogContent style={{ maxHeight: "60vh", minHeight: "120px" }}>
          <div className={classes.timeTitle}>出力期間</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "8px 0 16px 0",
            }}
          >
            <DatePicker
              allowClear={false}
              locale={locale}
              inputReadOnly
              format="YYYY/MM/DD"
              onChange={(value) => {
                setTimeFrom(value);
              }}
              value={moment(dateFrom)}
              suffixIcon={<CalendarTodayIcon />}
              size="large"
              placeholder="日付"
              style={{ width: "45%" }}
            />
            <div className={classes.timeMid}>~</div>
            <DatePicker
              allowClear={false}
              locale={locale}
              inputReadOnly
              format="YYYY/MM/DD"
              onChange={(value) => {
                setTimeTo(value);
              }}
              value={moment(dateTo)}
              suffixIcon={<CalendarTodayIcon />}
              size="large"
              placeholder="日付"
              style={{ width: "45%" }}
            />
          </div>
          <TableContainer style={{ height: "320px" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    align="center"
                    className={classes.headerCell}
                    style={{ width: "25%" }}
                  >
                    店舗名
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.headerCell}
                    style={{ width: "20%" }}
                  >
                    店舗コード
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.headerCell}
                    style={{ width: "20%" }}
                  >
                    借方合計
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.headerCell}
                    style={{ width: "20%" }}
                  >
                    貸方合計
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className={classes.headerCell}
                    style={{ width: "15.5%" }}
                  >
                    出力
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow style={{ height: "40px" }}>
                  <StyledTableCell
                    style={{
                      borderLeft: "1px solid #CCCCCC",
                      borderRight: "1px solid #CCCCCC",
                    }}
                  />
                  <StyledTableCell
                    style={{ borderRight: "1px solid #CCCCCC" }}
                  />
                  <StyledTableCell
                    style={{ borderRight: "1px solid #CCCCCC" }}
                  />
                  <StyledTableCell
                    style={{ borderRight: "1px solid #CCCCCC" }}
                  />
                  <StyledTableCell
                    align="center"
                    style={{
                      borderRight: "1px solid #CCCCCC",
                      padding: "8px",
                      fontWeight: "400",
                    }}
                  >
                    全選択
                    <Checkbox
                      checked={checkAll}
                      onChange={handleCheckAll}
                      color="orange"
                      style={{ padding: "0", margin: "0 0 2px 5px" }}
                    />
                  </StyledTableCell>
                </TableRow>
                {data?.map((rowsDataItem, index) => {
                  return (
                    <TableRow key={index}>
                      <StyledTableCell
                        align="center"
                        className={classes.bodyCell}
                        style={{ borderLeft: "1px solid #CCCCCC" }}
                      >
                        {rowsDataItem.storeName}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className={classes.bodyCell}
                      >
                        {rowsDataItem.storeId}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className={classes.bodyCell}
                      >
                        <NumberFormat
                          value={rowsDataItem.totalDebit}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className={classes.bodyCell}
                      >
                        <NumberFormat
                          value={rowsDataItem.totalCredit}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        className={classes.bodyCell}
                      >
                        <Checkbox
                          checked={rowsDataItem.checked ? true : false}
                          onChange={(e, result) => handleCheck(result, index)}
                          color="orange"
                          style={{ padding: "0" }}
                        />
                      </StyledTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={closeDialog}
            color="orange"
            className={classes.leftButton}
          >
            キャンセル
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={() =>
              output(
                moment(dateFrom).format("YYYY-MM-DD"),
                moment(dateTo).format("YYYY-MM-DD")
              )
            }
            color="orange"
            className={classes.rightButton}
            disabled={errorFlg || !checkedFlg}
          >
            出力
          </LoadingButton>
        </DialogActions>
      </div>
    </Modal>
  );
}

export default withStyles(styles, { withTheme: true })(BySubjectDialog);
