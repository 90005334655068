


import React, {useEffect, useState, Fragment} from "react";
import {
    Box,
    Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { ErrorOutline } from "@mui/icons-material"

const styles = (theme) => ({
    root: {
        backgroundColor: "#FFE9E9",
        width: "100%",
        textAlign: "left",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 8,
        marginBottom: 24,
    },
    typoText: {
        display: "flex",
        color: "#EA0000",
    },
    text: {
        marginLeft: 8 , 
        whiteSpace: "pre-line"
    }
});

function ErrorMsgBox (props) {
    const {
        classes,
        errorMsg,
    } = props

    return (
        <Box className={classes.root}>
            <Typography 
                classes={{
                    root: classes.typoText
                }}
            >
                <ErrorOutline /> 
                <span className={ classes.text }>{errorMsg.text}</span>
            </Typography>
        </Box>
    )
}


ErrorMsgBox.defaultProps = {
    
}

export default withStyles(styles, { withTheme: true })(ErrorMsgBox);