import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DatePicker, Modal } from "antd";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NumberFormatCustom from "../../../global/component/basic/NumberFormatCustom";
import BasicTextfield from "../../../global/component/basic/BasicTextfield";
import { Error } from "../../../global/common/constants";

const styles = (theme) => ({
  title: {
    textAlign: "center",
    fontFamily: "Noto Sans",
    color: "#162337",
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: "700",
  },
  alert: {
    backgroundColor: "#FACCD3",
    color: "#E60024",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "16px",
  },
  headerCell: {
    borderTop: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "100%",
    padding: "8px",
  },
  bodyCell: {
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "8px",
  },
  negativeNumber: {
    borderTop: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
    textAlign: "right",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingRight: "8px",
    color: "red",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    ineHeight: "150%",
  },
  positiveNumber: {
    borderTop: "1px solid #CCCCCC",
    borderRight: "1px solid #CCCCCC",
    textAlign: "right",
    paddingTop: "8px",
    paddingRight: "8px",
    paddingBottom: "8px",
    color: "#162337",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    ineHeight: "150%",
  },
  leftButton: {
    fontSize: "16px",
    width: "120px",
    height: "48px",
    marginLeft: "20px",
    marginRight: "auto",
  },
  rightButton: {
    fontSize: "16px",
    width: "120px",
    height: "48px",
    marginRight: "15px",
    color: "#FFFFFF",
  },
  inputContent: {
    textAlign: "right",
    paddingTop: "9px",
    paddingBottom: "8px",
    color: "#162337",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    ineHeight: "150%",
  },
  inputContentEx: {
    textAlign: "right",
    paddingTop: "9px",
    paddingBottom: "8px",
    color: "red",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    ineHeight: "150%",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#162337",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    backgroundColor: "#FFFFFF",
  },
}));

function OffCreditDialog(props) {
  const { classes, dialogVisible, closeDialog, dialogIndex, storeData, setStoreData, offCreditMaster, readOnlyFlag, submitDisabled, setSubmitDisabled } = props;

  const [totalSettlementAmountEntered, setTotalSettlementAmountEntered] = useState(0);
  const [editDataList, setEditDataList] = useState({});

  function updateExists(value, index) {
    editDataList.overviewData.overViewTableTwo[dialogIndex].offPayment.rowsData[index].leaseCategory = offCreditMaster[value]
    let total = 0;
    addDetialArray.forEach((e) => {
      if (e.leaseCategory != null && e.leaseCategory != "") {
        total += Number(e.settlementAmount);
      }
    });
    let tempTotalSettlementAmountEntered = 0
    editDataList?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.rowsData?.map((rowsDataItem, index) => {
      if (rowsDataItem.settlementAmount != null && rowsDataItem.settlementAmount != "" && rowsDataItem.leaseCategory != null && rowsDataItem.leaseCategory != "") {
        tempTotalSettlementAmountEntered += parseInt(rowsDataItem.settlementAmount)
      }
    })

    setTotalSettlementAmountEntered(total + tempTotalSettlementAmountEntered);
    setEditDataList({ ...editDataList })
  }

  function setTradingHour(value, index) {
    addDetialArray[index].tradingHour = value;
    setAddDetialArray([...addDetialArray]);
  }

  function setSettlementAmount(value, index) {
    addDetialArray[index].settlementAmount = value;
    setAddDetialArray([...addDetialArray]);
    let total = 0;
    addDetialArray.forEach((e) => {
      if (e.leaseCategory != null && e.leaseCategory != "") {
        total += Number(e.settlementAmount);
      }
    });
    let tempTotalSettlementAmountEntered = 0
    editDataList?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.rowsData?.map((rowsDataItem, index) => {
      if (rowsDataItem.settlementAmount != null && rowsDataItem.settlementAmount != "" && rowsDataItem.leaseCategory != null && rowsDataItem.leaseCategory != "") {
        tempTotalSettlementAmountEntered += parseInt(rowsDataItem.settlementAmount)
      }
    })

    setTotalSettlementAmountEntered(total + tempTotalSettlementAmountEntered);
  }

  function setLeaseCategory(value, index) {
    addDetialArray[index].leaseCategory = offCreditMaster[value];
    setAddDetialArray([...addDetialArray]);
    let total = 0;
    addDetialArray.forEach((e) => {
      if (e.leaseCategory != null && e.leaseCategory != "") {
        total += Number(e.settlementAmount);
      }
    });
    let tempTotalSettlementAmountEntered = 0
    editDataList?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.rowsData?.map((rowsDataItem, index) => {
      if (rowsDataItem.settlementAmount != null && rowsDataItem.settlementAmount != "" && rowsDataItem.leaseCategory != null && rowsDataItem.leaseCategory != "") {
        tempTotalSettlementAmountEntered += parseInt(rowsDataItem.settlementAmount)
      }
    })

    setTotalSettlementAmountEntered(total + tempTotalSettlementAmountEntered);
  }

  const [addDetialArray, setAddDetialArray] = useState([]);
  function addDetial() {
    addDetialArray.push({
      tradingHour: moment(),
      settlementAmount: null,
      leaseCategory: "",
    });
    setAddDetialArray([...addDetialArray]);
  }

  const [insertDisable, setInsertDisable] = useState(false);
  const [sameTimeFlag, setSameTimeFlag] = useState(false);
  function insert() {
    setInsertDisable(true);

    storeData.nyukinki.overviewData.overViewTableTwo[dialogIndex].offPayment.rowsData = editDataList?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.rowsData
    storeData.nyukinki.overviewData.overViewTableTwo[dialogIndex].offPayment.rowsData = [
      ...storeData.nyukinki.overviewData.overViewTableTwo[dialogIndex].offPayment.rowsData,
      ...addDetialArray.map((e) => {
        e.tradingHour = moment(e.tradingHour).format("YYYY/MM/DD HH:mm:ss");
        return e;
      }),
    ];
    setStoreData({ ...storeData })
    setSubmitDisabled(true)
    setAddDetialArray([]);
    setInsertDisable(false);
    setSameTimeFlag(false)
    closeDialog();
  }

  function close() {
    setAddDetialArray([]);
    setSameTimeFlag(false)
    closeDialog();
  }

  useEffect(() => {
    setEditDataList(JSON.parse(JSON.stringify(storeData?.nyukinki)))
    let tempTotalSettlementAmountEntered = 0
    storeData?.nyukinki?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.rowsData?.map((rowsDataItem, index) => {
      if (rowsDataItem.settlementAmount != null && rowsDataItem.settlementAmount != "" && rowsDataItem.leaseCategory != null && rowsDataItem.leaseCategory != "") {
        tempTotalSettlementAmountEntered += parseInt(rowsDataItem.settlementAmount)
      }
    })
    setTotalSettlementAmountEntered(tempTotalSettlementAmountEntered);
  }, [dialogVisible]);
  useEffect(() => {
    if (addDetialArray.length > 0) {
      let tradingHourList = []
      for (let index = 0; index < addDetialArray.length; index++) {
        tradingHourList.push(moment(addDetialArray[index].tradingHour).format("YYYY/MM/DD HH:mm:ss"))
      }
      for (let editIndex = 0; editIndex < editDataList?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.rowsData?.length; editIndex++) {
        tradingHourList.push(moment(editDataList?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.rowsData[editIndex].tradingHour).format("YYYY/MM/DD HH:mm:ss"))
      }
      console.log(tradingHourList)
      for (let index = 0; index < tradingHourList.length; index++) {
        const set = new Set(tradingHourList);
        if (set.size != tradingHourList.length) {
          setSameTimeFlag(true)
          console.log(true)
        } else {
          setSameTimeFlag(false)
          console.log(false)
        }
      }
    }
  }, [addDetialArray]);
  return (
    <Modal
      centered
      open={dialogVisible}
      closable={false}
      footer={null}
      width="60%"
    >
      <div style={{ height: "80vh" }}>
        <DialogTitle>
          <div className={classes.title}>オフ決済 決済種別選択</div>
          <IconButton
            aria-label="close"
            onClick={() => close()}
            size="large"
            sx={{
              position: "absolute",
              right: 40,
              top: 20,
            }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ height: "63vh" }}>
          <Alert severity="error" className={classes.alert} style={{ display: parseInt(storeData?.nyukinki?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.totalSettlementAmount) === totalSettlementAmountEntered ? "none" : "" }}>
            {Error.E0032}
            <div style={{ marginTop: "10px", display: !sameTimeFlag ? "none" : "" }}>
              {"取引時間が重複しています。時間は重複しないようにしてください。"}
            </div>
          </Alert>
          <Table style={{ margin: "20px 0px" }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.headerCell}
                  style={{
                    width: "30%",
                    backgroundColor: "#162337",
                    color: "#FFFFFF",
                  }}
                >
                  合計決済額
                </TableCell>
                <TableCell
                  align="right"
                  className={
                    storeData?.nyukinki?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.totalSettlementAmount < 0
                      ? classes.negativeNumber
                      : classes.positiveNumber
                  }
                  style={{ fontWeight: 700 }}
                >
                  <NumberFormat
                    value={storeData?.nyukinki?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.totalSettlementAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="center"
                  className={classes.headerCell}
                  style={{ backgroundColor: "#F4F4F4", borderRight: "0px" }}
                >
                  入力済みの合計決済額
                </TableCell>
                <TableCell
                  align="right"
                  className={
                    totalSettlementAmountEntered < 0
                      ? classes.negativeNumber
                      : classes.positiveNumber
                  }
                >
                  <NumberFormat
                    value={totalSettlementAmountEntered}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  className={classes.headerCell}
                  style={{ width: "35%" }}
                >
                  取引時間
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  className={classes.headerCell}
                  style={{ width: "25%" }}
                >
                  決済額
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  className={classes.headerCell}
                  style={{ width: "40%" }}
                >
                  ブランド種別
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {editDataList?.overviewData?.overViewTableTwo[dialogIndex]?.offPayment?.rowsData?.map((rowsDataItem, index) => {
                return (
                  <TableRow key={index}>
                    <StyledTableCell
                      align="left"
                      className={classes.bodyCell}
                      style={{ borderLeft: "1px solid #CCCCCC" }}
                    >
                      {rowsDataItem.tradingHour}
                    </StyledTableCell>
                    <StyledTableCell align="right" className={classes.bodyCell}>
                      <NumberFormat
                        value={rowsDataItem.settlementAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.bodyCell}>
                      <Select
                        disabled={readOnlyFlag}
                        sx={{
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 },
                        }}
                        value={offCreditMaster.indexOf(rowsDataItem?.leaseCategory) >= 0 ? offCreditMaster.indexOf(rowsDataItem?.leaseCategory) : ""}
                        onChange={(event) =>
                          updateExists(event.target.value, index)
                        }
                        style={{ height: "40px", width: "100%" }}
                      >
                        {offCreditMaster.map((name, index) => (
                          <MenuItem
                            key={name}
                            value={index}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
              {addDetialArray.map((rowsDataItem, index) => {
                return (
                  <TableRow key={index}>
                    <StyledTableCell
                      className={classes.bodyCell}
                      style={{ borderLeft: "1px solid #CCCCCC" }}
                    >
                      <DatePicker
                        showTime
                        allowClear={false}
                        locale={locale}
                        inputReadOnly
                        format="YYYY/MM/DD HH:mm:ss"
                        onChange={(value) => setTradingHour(value, index)}
                        value={rowsDataItem.tradingHour}
                        suffixIcon={<CalendarTodayIcon />}
                        size="large"
                        placeholder="日時"
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className={classes.bodyCell}
                    >
                      <BasicTextfield
                        style={{ width: "100%" }}
                        value={rowsDataItem.settlementAmount}
                        autoComplete="off"
                        onChange={(event) =>
                          setSettlementAmount(event.target.value, index)
                        }
                        name="numberformat"
                        id="formatted-numberformat-input"
                        InputProps={{
                          placeholder: "金額",
                          classes: {
                            input:
                              rowsDataItem.settlementAmount < 0
                                ? classes.inputContentEx
                                : classes.inputContent,
                          },
                          inputComponent: NumberFormatCustom,
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left" className={classes.bodyCell}>

                      <Select

                        sx={{
                          '& legend': { display: 'none' },
                          '& fieldset': { top: 0 },
                        }}
                        value={offCreditMaster.indexOf(rowsDataItem?.leaseCategory) >= 0 ? offCreditMaster.indexOf(rowsDataItem?.leaseCategory) : ""}
                        onChange={(event) =>
                          setLeaseCategory(event.target.value, index)
                        }
                        style={{ height: "40px", width: "100%" }}
                      >
                        {offCreditMaster.map((name, index) => (
                          <MenuItem
                            key={name}
                            value={index}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>

          </Table>
          {!readOnlyFlag &&
            <div style={{ border: "1px solid #CCCCCC", borderTop: "0px" }}>
              <div
                onClick={addDetial}
                style={{
                  margin: "0 0 0 10px",
                  width: "120px",
                  height: "40px",
                  cursor: "pointer",
                }}
              >
                <AddCircleOutlineIcon
                  style={{ float: "left", marginTop: "10px" }}
                />
                <div style={{ float: "left", margin: "10px 0 0 10px" }}>
                  明細を追加
                </div>
              </div>
            </div>
          }
        </DialogContent>
        {!readOnlyFlag &&
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => close()}
              color="orange"
              className={classes.leftButton}
            >
              キャンセル
            </Button>
            <Button
              variant="contained"
              onClick={insert}
              color="orange"
              className={classes.rightButton}
              disabled={insertDisable || sameTimeFlag}
            >
              登録
            </Button>
          </DialogActions>
        }
      </div>
    </Modal >
  );
}

export default withStyles(styles, { withTheme: true })(OffCreditDialog);
