import { useEffect, useState, Fragment, useRef } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import NumberFormat from "react-number-format";
import moment from "moment";

// 画面スタイル
const styles = (theme) => ({
  row1: {
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    ontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
    padding: "8px",
  },
  row2: {
    color: "#162337",
    height: "40px",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "150%",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#162337",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
    padding: "8px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    backgroundColor: "#FFFFFF",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },

  "tr,td,th": {
    border: "1px solid #CCCCCC",
  },
  "td,th": {
    width: "25%",
  },
}));

//　画面入り口
function ConfirmTableOne(props) {
  const { classes, storeData } = props;

  const [adjustmentDate, setAdjustmentDate] = useState(storeData.nyukinki.overviewData.overViewTableOne.adjustmentDate ?? null);

  const [principalChange, setPrincipalChange] = useState(storeData.nyukinki.overviewData.overViewTableOne.principalChange ?? null);

  const [cashAdjustment, setCashAdjustmentChange] = useState(storeData.nyukinki.overviewData.overViewTableOne.cashAdjustment ?? null);

  //　画面表示
  return (
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell colSpan={2} align="center">
            入金調整額
          </StyledTableCell>
          <StyledTableCell colSpan={1} align="center">
            元金変更
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell align="center" className={classes.row1}>
            調整日
          </StyledTableCell>
          <StyledTableCell align="center" className={classes.row1}>
            調整額
          </StyledTableCell>
          <StyledTableCell
            align="center"
            className={classes.row1}
          ></StyledTableCell>
        </StyledTableRow>

        <StyledTableRow>
          <StyledTableCell className={classes.row2}>
            {adjustmentDate && moment(adjustmentDate).format("YYYY/MM/DD")}
          </StyledTableCell>
          <StyledTableCell className={classes.row2} align="right">
            <NumberFormat
              value={cashAdjustment}
              style={{ color: cashAdjustment < 0 ? "#E60024" : "#162337" }}
              displayType={"text"}
              thousandSeparator={true}
            />
          </StyledTableCell>
          <StyledTableCell className={classes.row2} align="right">
            <NumberFormat
              value={principalChange}
              style={{
                color: principalChange < 0 ? "#E60024" : "#162337",
              }}
              displayType={"text"}
              thousandSeparator={true}
            />
          </StyledTableCell>
        </StyledTableRow>
      </TableBody>
    </Table>
  );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(ConfirmTableOne);
