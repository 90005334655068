import { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Store from '../pages/store/Store.js';
import Master from '../pages/master/Master.js';
import Accounting from '../pages/accounting/Accounting.js';
import LoginError from '../pages/login/loginError.js';
import { CognitoLoginUrl } from '../global/common/constants.js';
const RedirectToCognito = () => {
     useEffect(() => {
          window.location.href = CognitoLoginUrl.url

     }, [])
     return (<></>)
}
// 総元ルート
const RoutesDom = () => (
     <BrowserRouter>
          <Routes>
               <Route path="*" element={<RedirectToCognito />} />
               <Route path="login/*" element={<RedirectToCognito />} />
               <Route path="store/*" element={<Store />} />
               <Route path="master/*" element={<Master />} />
               <Route path="accounting/*" element={<Accounting />} />
               <Route path="login_error/*" element={<LoginError />} />
          </Routes>
     </BrowserRouter>
)

export default RoutesDom;