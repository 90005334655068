import React from "react";
import PropTypes from "prop-types";
import {
    CircularProgress,
    LinearProgress
} from "@mui/material";
import { withStyles } from "@mui/styles";
const styles = (theme) => ({
    root: {
        marginLeft: "50%",
        marginTop: "50%",
        position: "absolute",
        zIndex: 10000
    },
});

function BaseCircularProgress(props) {
    const {
        classes,
    } = props

    return (
        <LinearProgress color="orange" />
    )
}


BaseCircularProgress.defaultProps = {

}

BaseCircularProgress.propsTypes = {

}
export default withStyles(styles, { withTheme: true })(BaseCircularProgress);