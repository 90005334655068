
import request from './request.js';

/**
 * Login API
 */
export const getTokenAPI = (data) => request.post('/token', { data })

/**
 * ValidatingCode API
 */
export const validatingCodeAPI = (data) => request.post('/master/validatingCode', { data })

/**
 * refresh token API
 */
export const userRefreshToken = (data) => request.post('/user/userRefreshToken', { data })

/**
 * UserInfo API
 */
export const getUserInfoAPI = (data) => request.post('/user/info', { data })

/**
 * get Report data API
 */
export const getReportAPI = (data) => request.post("/store/report", { data })

/**
 * get Overview data API
 */
export const getOverviewAPI = (data) => request.post("/store/overview", { data })

/**
 * get Overvie edit data API
 */
export const getOverviewEditDataAPI = (data) => request.post("/store/overview/editData", { data })

/**
 * submit Overview data API
 */
export const submitOverviewAPI = (data) => request.post("/store/submit", { data })

/**
 * get offpayment master data API
 */
export const getOffpaymentMasterAPI = (data) => request.get("/store/offpaymentMaster", { data })

/**
* get tenantcredit master data API
*/
export const getTenantcreditMasterAPI = (data) => request.get("/store/tenantcreditMaster", { data })

/**
* get accounting daily API
*/
export const getAccountingDailyAPI = (data) => request.get("/accounting/daily", { data })

/**
* get unSubmittedStores data API
*/
export const getUnSubmittedStoresAPI = (data) => request.get("/accounting/unSubmittedStores", { data })

/**
* get UnAuthorizedStores data API
*/
export const getUnAuthorizedStoresAPI = (data) => request.get("/accounting/unAuthorizedStores", { data })

/**
* get authorizedStores data API
*/
export const getAuthorizedStoresAPI = (data) => request.get("/accounting/authorizedStores", { data })

/**
* get master data API
*/
export const getMasterAPI = (data) => request.get("/master/getShiwake", { data })

/**
* update master data API
*/
export const updateMasterAPI = (data) => request.post("/master/updateShiwake", { data })

/**
* update authorize status API
*/
export const updateAuthorizeStatusListAPI = (data) => request.post("/accounting/updateAuthorizeStatus", { data })

/**
* accounting authorize API
*/
export const authorizeAPI = (data) => request.post("/accounting/authorize", { data })

/**
* accounting get compare data API
*/
export const accountingCompareDataAPI = (data) => request.post("/accounting/getDailyShiwake", { data })

/**
* accounting get overview data API
*/
export const accountingOverviewDataAPI = (data) => request.post("/accounting/overview", { data })

/**
* accounting get ATable data API
*/
export const accountingATableOutputDataAPI = (data) => request.get("/accounting/authorizedStores", { data })

/**
* accounting S3 Output API
*/
export const accountingS3OneDayAPI = (data) => request.post("/accounting/s3OneDay", { data })

/**
* accounting ATable Output API
*/
export const accountingATableOutputAPI = (data) => request.post("/accounting/aTableOutput", { data })

/**
* accounting ATable Output API
*/
export const accountingShiwakeOutputAPI = (data) => request.post("/accounting/shiwakeOutput", { data })

/**
* tenant credit dialog API
*/
export const tenantDialogAPI = (data) => request.post("/store/tenantDialog", { data })

/**
* get nw-7 codabar
*/
export const getCodabarAPI = (data) => request.get("/codabar", { data })
