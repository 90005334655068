
import React from "react";
import { Routes, Route } from "react-router-dom"
import {
  Box
} from "@mui/material";
import { withStyles } from "@mui/styles";

import ReportStatus from "../pages/accounting/report-status/ReportStatus"
import UnsubmittedStores from "../pages/accounting/unsubmitted-stores/UnsubmittedStores";
import UnauthorizedStores from "../pages/accounting/unauthorized-stores/UnauthorizedStores";
import AuthorizedStores from "../pages/accounting/authorized-stores/AuthorizedStores";
import CompareBorrow from "../pages/accounting/compare-borrow/CompareBorrow";
import DailyDetail from "../pages/accounting/daily-detail/DailyDetail";


//　背景スタイル
const styles = (theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    width: "auto",
    [theme.breakpoints.up("xs")]: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginBottom: 0,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: 0,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
      marginBottom: 0,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  childDiv: {
    height: 'calc(100vh - 48px)',
    overflowY: 'scroll'
  }
});

// 入り口
function AccountingRouters(props) {
  const {
    classes,
    setSelectedTab,
    courses,
    prefectures,
    accountingData,
    setAccountingData
  } = props

  // 画面表示
  return (
    <Box className={classes.wrapper}>
      <Routes>
        <Route index path="*" element={<div className={classes.childDiv}> <ReportStatus setSelectedTab={setSelectedTab} accountingData={accountingData}
          setAccountingData={setAccountingData} /></div>} />
        <Route path="reportstatus" element={<div className={classes.childDiv}> <ReportStatus setSelectedTab={setSelectedTab} accountingData={accountingData}
          setAccountingData={setAccountingData} /></div>} />
        <Route path="unsubmittedStores" element={<div className={classes.childDiv}> <UnsubmittedStores setSelectedTab={setSelectedTab} accountingData={accountingData}
          setAccountingData={setAccountingData} /></div>} />
        <Route path="unauthorizedStores" element={<div className={classes.childDiv}> <UnauthorizedStores setSelectedTab={setSelectedTab} accountingData={accountingData}
          setAccountingData={setAccountingData} /></div>} />
        <Route path="authorizedStores" element={<div className={classes.childDiv}> <AuthorizedStores setSelectedTab={setSelectedTab} accountingData={accountingData}
          setAccountingData={setAccountingData} /></div>} />
        <Route path="compareBorrow" element={<div className={classes.childDiv}> <CompareBorrow setSelectedTab={setSelectedTab} accountingData={accountingData}
          setAccountingData={setAccountingData} /></div>} />
        <Route path="dailyDetail" element={<div className={classes.childDiv}> <DailyDetail setSelectedTab={setSelectedTab} accountingData={accountingData}
          setAccountingData={setAccountingData} /></div>} />
      </Routes>
    </Box>
  )
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(AccountingRouters);