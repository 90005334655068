import React, { useEffect, useState, Fragment, useReducer } from "react";
import { Box, Typography, Button } from "@mui/material";
import { withStyles } from "@mui/styles";
import OverviewTableOne from "./OverviewTableOne";
import OverviewTableTwo from "./OverviewTableTwo";
import OverviewTableThree from "./OverviewTableTwo";
import CircularProgress from "@mui/material/CircularProgress";
import { RouterString, DialogType } from "../../../global/common/constants";
import NumberFormat from "react-number-format";
import { getOverviewAPI, getOverviewEditDataAPI } from "../../../network/api";
import { useMounted } from "../../../global/function/useMounted";
import { set } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import Refresh from "@mui/icons-material/Refresh";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import moment from 'moment';
import { Error, localConstants } from "../../../global/common/constants";
import { objectToString, stringToObejct } from "../../../global/function/JsonUtil"
import { useNavigate } from "react-router-dom";
import BaseCircularProgress from "../../../global/component/basic/BaseCircularProgress";

// 画面レイアウトスタイル
const styles = (theme) => ({
    overviewContentFont: {
        fontFamily: "Noto Sans",
        fontStyle: "normal",
        color: "#002239",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "150%",
    },
    progressCircle: {
        color: "rgba(0,0,0,0.54)",
        transform: "rotate(-45deg)",
    },
    update: {
        FontFamily: "Noto Sans",
        FontStyle: "Bold",
        FontSize: "16px",
        LineHeight: "100%",
        fontWeight: 700,
        color: "#000000",
    },
    title: {
        textAlign: "center",
        fontFamily: "Noto Sans",
        color: "#162337",
        fontSize: "20px",
        lineHeight: "24px",
        fontWeight: "700",
    },
});

//　画面入り口 
function Overview(props) {
    const { classes,
        setSelectedTab,
        storeData,
        setStoreData,
        submitDisabled,
        setSubmitDisabled,
        setIsCircularLoading
    } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshData, setIsRefreshData] = useState(0);
    const isMounted = useMounted();
    const navigate = useNavigate()


    const forceUpdate = useReducer((bool) => !bool)[1];

    const [dialogVisible, setDialogVisible] = useState(false);


    // 概要データ更新
    const updateData = () => {
        setDialogVisible(true)
    };

    const closeDialog = () => {
        setDialogVisible(false)
    }
    const refreshData = () => {
        setDialogVisible(false)
        getOverViewData();
    }

    // 概要データ取得
    const getOverViewData = () => {
        setIsCircularLoading(true);
        // 概要データ取得APIを呼び出す
        let param = {
            businessDay: storeData.nyukinki.reportData.businessDay,
            approvalStatus: "0",
            storeId: localStorage.getItem(localConstants.StoreId),
        }
        getOverviewAPI(param).then((res) => {
            if (!isMounted()) return
            let resData = res.data
            if (res.status >= 400 && res.status < 500) {
                alert(Error.E0003);
            } else if (res.status == 200) {
                resData?.nyukinki?.overviewData?.overViewTableTwo?.sort((a, b) => (parseInt(a.rejino) > parseInt(b.rejino)) ? 1 : -1)
                setStoreData({
                    ...storeData,
                    nyukinki: {
                        ...storeData.nyukinki,
                        overviewData: resData?.nyukinki?.overviewData
                    },
                    editPart: {
                        overviewData: JSON.parse(JSON.stringify(resData?.nyukinki?.overviewData))
                    },
                    pos: resData?.pos

                })
                setIsRefreshData(isRefreshData + 1)
            }
            setIsCircularLoading(false)
        }).catch((err) => {
            setIsCircularLoading(false)
            if (!isMounted()) return
        })

    };

    // 概要データ編集
    const editData = (flg) => {
        if (!flg) {
            var judgeRejiFlg = true
            var rejiArr = []
            var nyukinkiRejiArr = []
            if (storeData.editPart?.overviewData?.overViewTableTwo?.length > 0) {
                for (var idx = 0; idx < storeData.editPart.overviewData.overViewTableTwo.length; idx++) {
                    if (storeData.editPart.overviewData.overViewTableTwo[idx].rejino == null || storeData.editPart.overviewData.overViewTableTwo[idx].rejino == "") {
                        alert(Error.E0046)
                        judgeRejiFlg = false
                        break
                    }
                    else if (storeData.editPart.overviewData.overViewTableTwo[idx].rejino == "101" || storeData.editPart.overviewData.overViewTableTwo[idx].rejino == "102" || storeData.editPart.overviewData.overViewTableTwo[idx].rejino == "103" || storeData.editPart.overviewData.overViewTableTwo[idx].rejino == "104" || storeData.editPart.overviewData.overViewTableTwo[idx].rejino == "105") {
                        alert(Error.E0047)
                        judgeRejiFlg = false
                        break
                    }
                    else {
                        rejiArr.push(storeData.editPart.overviewData.overViewTableTwo[idx].rejino)
                    }
                }

                function findDiff(arr) {
                    const filtered = arr.filter((item, index) => arr.indexOf(item) !== index);
                    return [...new Set(filtered)]
                }


                let diff = findDiff(rejiArr);
                let strError = ""
                strError = Error.E0045.replace("{0}", diff.toString())
                console.log(diff);
                if (diff.length > 0) {
                    alert(strError)
                    judgeRejiFlg = false
                }

                if (judgeRejiFlg) {
                    for (var zidx = 0; zidx < storeData.nyukinki.overviewData.overViewTableTwo.length; zidx++) {
                        nyukinkiRejiArr.push(storeData.nyukinki.overviewData.overViewTableTwo[zidx].rejino)
                    }
                    // let difference = nyukinkiRejiArr.filter(x => rejiArr.indexOf(x) == -1).concat(rejiArr.filter(x => nyukinkiRejiArr.indexOf(x) == -1));
                    let difference = rejiArr.filter(x => nyukinkiRejiArr.indexOf(x) == -1);
                    console.log(difference)
                    if (difference.length > 0) {
                        setIsCircularLoading(true)
                        let param = {
                            businessDay: storeData.nyukinki.reportData.businessDay,
                            storeId: localStorage.getItem(localConstants.StoreId),
                            rejinoList: difference
                        }
                        getOverviewEditDataAPI(param).then((res) => {
                            if (!isMounted()) return
                            let resData = res.data
                            if (res.status >= 400 && res.status < 500) {
                                alert(Error.E0003);
                            } else if (res.status == 200) {
                                if (resData?.editPart?.overviewData?.overViewTableTwo?.length > 0) {
                                    let tmpRejino = ""
                                    for (var k = 0; k < resData.editPart.overviewData.overViewTableTwo.length; k++) {
                                        tmpRejino = resData.editPart.overviewData.overViewTableTwo[k].rejino
                                        console.log(tmpRejino)
                                        const editOverviewData = storeData.editPart.overviewData
                                        for (var j = 0; j < editOverviewData.overViewTableTwo.length; j++) {
                                            if (tmpRejino == editOverviewData.overViewTableTwo[j].rejino) {
                                                editOverviewData.overViewTableTwo[j].creditCard = resData.editPart.overviewData.overViewTableTwo[k].creditCard
                                                editOverviewData.overViewTableTwo[j].income = resData.editPart.overviewData.overViewTableTwo[k].income
                                                editOverviewData.overViewTableTwo[j].koguchiReorient = resData.editPart.overviewData.overViewTableTwo[k].koguchiReorient
                                                editOverviewData.overViewTableTwo[j].offPayment = resData.editPart.overviewData.overViewTableTwo[k].offPayment
                                                setStoreData({
                                                    ...storeData,
                                                    editPart: { ...storeData.editPart, overviewData: editOverviewData }
                                                })
                                            }
                                        }
                                    }
                                }

                                storeData.pos.overviewData.overViewTableTwo.sort((a, b) => (parseInt(a.rejino) > parseInt(b.rejino)) ? 1 : -1)

                                setStoreData({
                                    ...storeData,
                                    nyukinki: {
                                        ...storeData.nyukinki,
                                        overviewData: JSON.parse(JSON.stringify(storeData?.editPart?.overviewData))
                                    },
                                    pos: { ...storeData.pos, overviewData: storeData.pos.overviewData }
                                })

                                //when nyukinki.length != pos.length
                                var matchingPos = JSON.parse(JSON.stringify(storeData?.pos));
                                var nyukinkiRejinoList = []
                                var posRejinoList = []
                                var tmpIdx = []
                                storeData?.editPart?.overviewData?.overViewTableTwo.map((nyukinkiItem, nyukinkiIndex) => {
                                    nyukinkiRejinoList.push((parseInt(nyukinkiItem.rejino)).toString())
                                })
                                matchingPos.overviewData?.overViewTableTwo.map((posItem, nyukinkiIndex) => {
                                    posRejinoList.push(posItem.rejino)
                                })
                                posRejinoList.map((rejino, index) => {
                                    if (nyukinkiRejinoList.indexOf(rejino) == -1) {
                                        tmpIdx.push(index)
                                    }
                                })
                                if (tmpIdx.length > 0) {
                                    tmpIdx.map((tpIdx, zindex) => {
                                        if (posRejinoList.length > tpIdx + 1) {
                                            moveArray(matchingPos.overviewData?.overViewTableTwo, tpIdx, matchingPos.overviewData?.overViewTableTwo.length)

                                            matchingPos.overviewData?.overViewTableTwo.splice(tpIdx, 1)
                                            setStoreData({
                                                ...storeData,
                                                nyukinki: {
                                                    ...storeData.nyukinki,
                                                    overviewData: JSON.parse(JSON.stringify(storeData?.editPart?.overviewData))
                                                },
                                                pos: { ...storeData.pos, overviewData: matchingPos.overviewData }
                                            })
                                        }
                                    })
                                }
                                function moveArray(arr, sourceIndex, targetIndex) {
                                    arr[sourceIndex] = arr.splice(targetIndex, 1, arr[sourceIndex])[0];
                                }
                            }
                            setIsCircularLoading(false)
                            setEditBtnFlg(false)
                        }).catch((err) => {
                            setIsCircularLoading(false)
                            if (!isMounted()) return
                        })
                    }
                    else {
                        setStoreData({
                            ...storeData,
                            nyukinki: {
                                ...storeData.nyukinki,
                                overviewData: JSON.parse(JSON.stringify(storeData?.editPart?.overviewData))
                            }
                        })
                        setEditBtnFlg(flg)
                    }
                }
            } else {
                setEditBtnFlg(flg)
            }
        } else {
            setStoreData({
                ...storeData,
                editPart: {
                    overviewData: JSON.parse(JSON.stringify(storeData?.nyukinki?.overviewData))
                }
            })
            setEditBtnFlg(flg)
        }

    }

    const [editBtnFlg, setEditBtnFlg] = useState(false);

    const [rejiErrFlg, setRejiErrFlg] = useState(false);

    // POS vs 入金機(画面表示値)1 vs 2:E0005
    const [compareE0005Flg, setCompareE0005Flg] = useState(true);

    // POS vs入金機（画面表示値）1 vs null:E0007
    const [compareE0007Flg, setCompareE0007Flg] = useState(true);

    const [compareE0008Flg, setCompareE0008Flg] = useState(true);

    const [compareE0042Flg, setCompareE0042Flg] = useState(true);


    const [compFlg, setCompFlg] = useState(false);



    // 画面初期化データ取得
    useEffect(() => {
        if (!storeData.nyukinki?.reportData) {
            navigate(RouterString.Report)
        }
        setSelectedTab(RouterString.Overview);
    }, [setSelectedTab]);
    useEffect(() => {
        setCompareE0007Flg(true)
        setCompareE0005Flg(true)
        setCompareE0008Flg(true)
        setCompareE0042Flg(true)
        setCompFlg(false)
        if (storeData.nyukinki?.overviewData?.overViewTableTwo.length == 0) {
            setCompareE0007Flg(false)
        }
        else {
            if (storeData.nyukinki?.overviewData?.overViewTableTwo.length < storeData.pos?.overviewData?.overViewTableTwo.length) {
                setCompareE0007Flg(false)
            }

            var arrRejiNyukinki = []
            var posRejinoList = []
            storeData?.pos?.overviewData?.overViewTableTwo.map((posItem, index) => {
                posRejinoList.push(posItem.rejino)
            })
            storeData.nyukinki?.overviewData?.overViewTableTwo?.forEach((row, index) => {
                setCompFlg(false)
                arrRejiNyukinki.push(row.rejino.toString())
                let newIndex = 0
                if (row.rejino != storeData?.pos?.overviewData?.overViewTableTwo[index]?.rejino) {
                    var findTmp = posRejinoList?.indexOf(row.rejino.toString())
                    if (findTmp > -1) {
                        newIndex = parseInt(findTmp)
                        setCompFlg(true)
                    }
                    else {
                        setCompareE0005Flg(false)
                        newIndex = index
                    }
                }
                else {
                    newIndex = index
                    setCompFlg(true)
                }
                if (storeData.pos?.overviewData?.overViewTableTwo[newIndex]?.cashTotal && (row.cashTotal == null)) {
                    setCompareE0007Flg(false)
                    // } else if (JSON.stringify(row.cashTotal) != JSON.stringify(storeData.pos?.overviewData?.overViewTableTwo[index]?.cashTotal)) {
                    //     setCompareE0005Flg(false)
                }
                if (storeData.pos?.overviewData?.overViewTableTwo[newIndex]?.kabusoku && (row.kabusoku == null)) {
                    setCompareE0007Flg(false)
                }
                var nyukinkiTotal = 0
                var posTotal = 0
                if (compFlg) {
                    row.genkingai[0]?.meisai?.forEach((item, yIndex) => {

                        if (item.key == "商品券 3千" || item.key == "商品券 2千" || item.key == "商品券 5百") {
                            nyukinkiTotal += parseInt(item.value)

                            posTotal += parseInt(storeData.pos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0].meisai[yIndex].value)
                        }
                        if (storeData.pos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0].meisai[yIndex].value && (item.value == null)) {
                            setCompareE0007Flg(false)
                        } else if (JSON.stringify(item.value) != JSON.stringify(storeData.pos?.overviewData?.overViewTableTwo[newIndex]?.genkingai[0].meisai[yIndex].value)) {
                            if (item.key != "商品券 3千" && item.key != "商品券 2千" && item.key != "商品券 5百" && item.key != "地域振興券") {

                                setCompareE0005Flg(false)
                            }
                        }
                    })
                    if (nyukinkiTotal != posTotal) {
                        setCompareE0005Flg(false)
                    }
                }

                if (row.offPayment?.totalSettlementAmount == null || row.offPayment.totalSettlementAmount == "0") {
                    let sumSettlementAmount = 0
                    if (row.offPayment?.rowsData != null) {
                        row.offPayment?.rowsData?.forEach((rTmp, rIndex) => {
                            if (rTmp?.tradingHour != null && rTmp?.settlementAmount != null && rTmp?.settlementAmount != 0) {
                                sumSettlementAmount += Number(rTmp.settlementAmount)
                            }
                        })
                        if (sumSettlementAmount != Number(row.offPayment.totalSettlementAmount)) {
                            setCompareE0008Flg(false)
                        }
                    }
                }
                else {
                    let trigger = 0
                    let sumSettlementAmount = 0
                    if (row.offPayment?.rowsData == null) {
                        setCompareE0008Flg(false)
                    } else {
                        row.offPayment?.rowsData?.forEach((rTmp, rIndex) => {
                            if (rTmp?.settlementAmount != null && rTmp?.settlementAmount != 0) {
                                sumSettlementAmount += Number(rTmp.settlementAmount)
                                trigger += 1
                            }
                        })
                        if (trigger == 0) {
                            setCompareE0008Flg(false)
                        }
                    }
                    if (sumSettlementAmount !== Number(row.offPayment.totalSettlementAmount)) {
                        setCompareE0008Flg(false)
                    }
                }
                if (row.creditCard?.totalSettlementAmount == null || row.creditCard.totalSettlementAmount == "0") {
                    let sumSettlementAmount = 0
                    if (row.creditCard?.rowsData != null) {
                        row.creditCard?.rowsData?.forEach((rTmp, rIndex) => {
                            if (rTmp?.tradingHour != null && rTmp?.settlementAmount != null && rTmp?.settlementAmount != 0) {
                                sumSettlementAmount += Number(rTmp.settlementAmount)
                            }
                        })
                        if (sumSettlementAmount != Number(row.creditCard.totalSettlementAmount)) {
                            setCompareE0042Flg(false)
                        }
                    }
                }
                // let sumCreditSettlementAmount = 0
                // row.creditCard?.rowsData?.forEach((rTmp, rIndex) => {
                //     if (rTmp?.tradingHour == null || rTmp?.settlementAmount == null || rTmp?.settlementAmount == "") {

                //     } else {
                //         sumCreditSettlementAmount += Number(rTmp.settlementAmount)
                //     }
                // })
                // if (sumCreditSettlementAmount > Number(row.creditCard.totalSettlementAmount)) {
                //     setCompareE0042Flg(false)
                // }

            })

            if (storeData.pos?.overviewData?.overViewTableTwo.length != 0) {
                storeData.pos?.overviewData?.overViewTableTwo?.forEach((posRow, index) => {
                    var findTmp = arrRejiNyukinki?.indexOf(posRow.rejino.toString())
                    if (findTmp == -1) {
                        setCompareE0007Flg(false)
                    }
                })
            }
        }

    }, [storeData])

    useEffect(() => {
        if (!editBtnFlg && storeData.nyukinki) {
            const overviewData = storeData.nyukinki.overviewData
            overviewData.overViewTableTwo.sort((a, b) => (parseInt(a.rejino) > parseInt(b.rejino)) ? 1 : -1)
            setStoreData({ ...storeData, nyukinki: { ...storeData.nyukinki, overviewData: overviewData } })
        }
    }, [editBtnFlg])

    const compareOption = (compareFlag, error) => {
        if (compareFlag) {
            if (storeData.nyukinki?.reportData?.errMsg?.length > 0) {
                if (storeData.nyukinki?.reportData.errMsg.includes(error)) {
                    let tempErr = storeData.nyukinki?.reportData.errMsg
                    tempErr.splice(storeData.nyukinki?.reportData.errMsg.indexOf(error), 1)
                    setStoreData({
                        ...storeData, nyukinki: {
                            ...storeData.nyukinki,
                            reportData: { ...storeData.nyukinki.reportData, errMsg: tempErr }
                        }
                    })
                    forceUpdate()
                }
            }
        }
        else {
            if (storeData.nyukinki?.reportData?.errMsg?.length > 0) {
                if (!storeData.nyukinki?.reportData.errMsg.includes(error)) {
                    let tempErr = storeData.nyukinki?.reportData.errMsg
                    tempErr.push(error)
                    setStoreData({
                        ...storeData, nyukinki: {
                            ...storeData.nyukinki,
                            reportData: { ...storeData.nyukinki.reportData, errMsg: tempErr }
                        }
                    })
                    forceUpdate()
                }
            } else {
                let tempErr = storeData.nyukinki?.reportData.errMsg
                tempErr.push(error)
                setStoreData({
                    ...storeData, nyukinki: {
                        ...storeData.nyukinki,
                        reportData: { ...storeData.nyukinki.reportData, errMsg: tempErr }
                    }
                })
                forceUpdate()
            }
        }
    }

    useEffect(() => {
        compareOption(compareE0008Flg, Error.E0008)
    }, [compareE0008Flg]);

    useEffect(() => {
        compareOption(compareE0042Flg, Error.E0042)
    }, [compareE0042Flg]);

    useEffect(() => {
        compareOption(compareE0007Flg, Error.E0007)
    }, [compareE0007Flg]);

    useEffect(() => {
        compareOption(compareE0005Flg, Error.E0005)
    }, [compareE0005Flg]);

    function getSumBusinessDayCash() {
        var sumBusinessDayCash = 0
        if (storeData.nyukinki?.overviewData != null) {
            if (storeData.nyukinki.overviewData.overViewTableOne != null) {
                sumBusinessDayCash += Number(storeData.nyukinki.overviewData.overViewTableOne.principalChange)
                // sumBusinessDayCash += Number(storeData.nyukinki.overviewData.overViewTableOne.cashAdjustment)
            }
            for (var idx = 0; idx < storeData.nyukinki.overviewData.overViewTableTwo.length; idx++) {
                if (storeData.nyukinki.overviewData.overViewTableTwo[idx].cashTotal != null) {
                    sumBusinessDayCash += Number(storeData.nyukinki.overviewData.overViewTableTwo[idx].cashTotal)
                }
                // if (storeData.nyukinki.overviewData.overViewTableTwo[idx].kabusoku != null) {
                //     sumBusinessDayCash += Number(storeData.nyukinki.overviewData.overViewTableTwo[idx].kabusoku)
                // }
            }
            sumBusinessDayCash -= Number(storeData.nyukinki.overviewData.originalAmountOfMoney)
            const overviewData = storeData.nyukinki.overviewData

            overviewData.overViewTableOne.businessDayCash = sumBusinessDayCash
            if (sumBusinessDayCash != storeData.nyukinki.overviewData.overViewTableOne.businessDayCash) {
                setStoreData({ ...storeData, nyukinki: { ...storeData.nyukinki, overviewData: overviewData } })
            }
        }
        return sumBusinessDayCash
    }

    function getDepositAmount() {
        var sumDepositAmount = 0
        if (storeData.nyukinki.overviewData != null) {
            var tmpBusinessDayCash = getSumBusinessDayCash()
            sumDepositAmount = tmpBusinessDayCash + Number(storeData.nyukinki.overviewData.overViewTableOne.cashAdjustment)
            // for (var idx = 0; idx < storeData.nyukinki.overviewData.overViewTableTwo.length; idx++) {
            //     if (storeData.nyukinki.overviewData.overViewTableTwo[idx].kabusoku != null) {
            //         sumDepositAmount += Number(storeData.nyukinki.overviewData.overViewTableTwo[idx].kabusoku)
            //     }
            // }
            const overviewData = storeData.nyukinki.overviewData
            overviewData.overViewTableOne.depositAmount = sumDepositAmount
            if (sumDepositAmount != storeData.nyukinki.overviewData.overViewTableOne.depositAmount) {
                setStoreData({ ...storeData, nyukinki: { ...storeData.nyukinki, overviewData: overviewData } })
            }
        }
        return sumDepositAmount
    }

    return (
        <Box>
            {isLoading && <BaseCircularProgress />}
            <Dialog
                open={dialogVisible}
                scroll="paper"
            >
                <DialogTitle>
                    <div className={classes.title}>データが破棄されます</div>
                    <IconButton
                        aria-label="close"
                        onClick={closeDialog}
                        size="large"
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    本当にデータを更新しますか？入力中のデータが破棄されます。
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={closeDialog}
                        color="orange"
                        style={{
                            fontSize: 14,
                            width: "112px",
                            marginLeft: "20px",
                            marginRight: "auto",
                        }}
                    >
                        キャンセル
                    </Button>
                    <Button
                        variant="contained"
                        onClick={refreshData}
                        style={{
                            fontSize: 14,
                            backgroundColor: "#EF7A00",
                            width: "112px",
                            marginRight: "15px",
                        }}
                    >
                        更新
                    </Button>
                </DialogActions>
                <div
                    style={{
                        height: "8px",
                    }}
                >
                </div>
            </Dialog>
            <div
                style={{
                    height: "5%",
                    marginLeft: "2.308%",
                    marginTop: "2.308%",
                    width: "5.713%",
                    fontFamily: "Noto Sans JP",
                    fontStyle: "Bold",
                    fontWeight: 700,
                    fontSize: "24px",
                    lineHeight: "150%",
                    color: "#162337",
                }}
            >
                概要
            </div>

            {
                storeData.nyukinki?.reportData?.errMsg?.length > 0 && (


                    < div

                        style={{
                            paddingTop: "1.539%",
                            paddingBottom: "1.539%",
                            marginLeft: "2.308%",
                            marginTop: "2.308%",

                            fontFamily: "Noto Sans JP",
                            fontStyle: "Bold",
                            fontWeight: 400,
                            fontSize: "16px",
                            lineHeight: "100%",
                            color: "#E60024",
                            backgroundColor: "#FACCD3",
                        }}
                    >
                        <div style={{ display: "flex", float: "left", marginRight: "16px" }}>
                            <InfoOutlinedIcon
                                style={{
                                    background: "#FACCD3",
                                    color: "#E60024",
                                    marginLeft: "16px",
                                    width: "19.3px",
                                    height: "19.3px",
                                }}
                            ></InfoOutlinedIcon>
                        </div>
                        {storeData.nyukinki?.reportData?.errMsg.map((eMsg, index) => (
                            <div style={{ marginLeft: "48px", }} key={index} >{eMsg}</div>
                        ))}
                    </div>

                )
            }

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "24px",
                }}
            >
                <div
                    style={{
                        width: "23.077%",
                        height: "3.571%",
                        marginLeft: "2.308%",
                    }}
                >
                    <div>
                        {/*  営業日現金 */}
                        <Typography className={classes.overviewContentFont}>
                            営業日現金
                        </Typography>
                    </div>
                    <div>
                        <Typography
                            className={classes.overviewContentFont}
                            style={{
                                fontFamily: "Noto Sans JP",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "28px",
                                lineHeight: "100%",
                                borderBottom: "1px solid #CCCCCC",
                                paddingBottom: "3px",
                                textAlign: "right",
                            }}

                        >
                            <NumberFormat
                                value={
                                    getSumBusinessDayCash()
                                }

                                displayType={"text"}
                                thousandSeparator={true}
                            />

                            {"\u00A0"}
                            <span
                                style={{
                                    fontSize: "16px",
                                }}
                            >
                                {" "}
                                円
                            </span>

                        </Typography>
                    </div>
                </div>
                <div
                    style={{
                        width: "23.077%",
                        height: "3.571%",
                        marginLeft: "2.308%",
                    }}
                >
                    <div>
                        {/*  入金額 */}
                        <Typography className={classes.overviewContentFont}>
                            入金額
                        </Typography>
                    </div>
                    <div>
                        <Typography
                            className={classes.overviewContentFont}
                            style={{
                                fontFamily: "Noto Sans JP",
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "28px",
                                lineHeight: "100%",
                                borderBottom: "1px solid #CCCCCC",
                                paddingBottom: "3px",
                                textAlign: "right",
                            }}
                        >
                            <NumberFormat
                                value={
                                    storeData.nyukinki?.overviewData != null
                                        ? getDepositAmount()
                                        : 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                            />
                            {"\u00A0"}
                            <span
                                style={{
                                    fontSize: "16px",
                                }}
                            >
                                {" "}
                                円
                            </span>
                        </Typography>
                    </div>
                </div>
                {storeData.nyukinki?.reportData?.approvalStatus != "2" &&
                    <div
                        style={{
                            width: "100%",
                            flex: 1,
                        }}
                    >
                        <Button
                            className={classes.update}
                            onClick={() => {
                                updateData();
                            }}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                float: "right",
                                marginRight: 8,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    float: "right",
                                    marginRight: 8,
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Refresh style={{ color: "#162337", width: 30, height: 30 }} />
                                更新
                            </div>
                        </Button>
                        <Button
                            className={classes.update}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                float: "right",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    float: "right",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                                onClick={() => {
                                    editData(editBtnFlg ? false : true);
                                }}
                            >
                                <EditIcon style={{ color: "#162337", width: 30, height: 30 }} />
                                {editBtnFlg ? "編集終了" : "編集開始"}
                            </div>
                        </Button>
                    </div>}
            </div>

            {/*  入金調整額テーブル１ */}
            {
                storeData.nyukinki?.overviewData ? (
                    <div
                        style={{
                            width: "95.385%",
                            height: "20.238%",
                            marginLeft: "2.308%",
                            marginTop: "24px",
                        }}
                    >
                        <OverviewTableOne
                            editBtnFlg={editBtnFlg}
                            storeData={
                                storeData
                            }
                            setStoreData={setStoreData}
                            submitDisabled={submitDisabled}
                            setSubmitDisabled={setSubmitDisabled}
                        ></OverviewTableOne>
                    </div>
                ) : null
            }

            {/*  入金調整額テーブル2 */}
            {
                storeData.nyukinki?.overviewData ? (
                    <div style={{ marginLeft: "2.308%", marginTop: "2.308%" }}>
                        <OverviewTableTwo
                            editBtnFlg={editBtnFlg}
                            setRejiErrFlg={setRejiErrFlg}
                            storeData={
                                storeData
                            }
                            setStoreData={setStoreData}
                            submitDisabled={submitDisabled}
                            setSubmitDisabled={setSubmitDisabled}
                            setIsCircularLoading={setIsCircularLoading}
                            isRefreshData={isRefreshData}

                        ></OverviewTableTwo>
                    </div>
                ) : null
            }
        </Box >
    );
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(Overview);
