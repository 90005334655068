import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withStyles } from "@mui/styles";
import NumberFormat from "react-number-format";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { localConstants } from "../../../global/common/constants";
import TenantCreditDialog from "../tenant-credit/TenantCreditDialog";
import OffCreditDialog from "../off-credit/OffCreditDialog";
import InsertDriveFile from "@mui/icons-material/InsertDriveFile";

// 画面スタイル
const styles = (theme) => ({
  firstHeadRow: {
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
    padding: "8px",
  },
  secondHeadRow: {
    width: "140px",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "700",
    padding: "8px",
  },
  cell: {
    width: "140px",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "8px",
  },
  endCell: {
    width: "140px",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "8px",
    backgroundColor: "#F4F4F4 !important",
  },
  iconClass: {
    marginBottom: "-7px",
    marginTop: "-2px",
    width: "24px",
    Color: "#162337",
    height: "24px",
    paddingTop: "0px",
    marginRight: "4%",
    float: "right",
    cursor: "pointer",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#162337",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    backgroundColor: "#FFFFFF",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

//　画面入り口
function DetailTableTwo(props) {
  const { classes, storeData, offCreditMaster, tenantCreditMaster } = props;
  const [dialogTCIndex, setDialogTCIndex] = useState({})
  const [dialogOFIndex, setDialogOFIndex] = useState({})
  const [dataSum, setDataSum] = useState({
    sumC0: "0",
    sumC1: "0",
    sumC2: "0",
    sumC3: "0",
    sumC4: "0",
    sumC4_1: [],
    sumC5: "0",
    sumC6: [],
    sumC7: "0",
    sumC8: [],
    sumC10: [],
    sumC11: "0",
  });


  useEffect(() => {
    dataSum.sumC4_1.length == 0 && storeData?.nyukinki?.overviewData?.overViewTableTwoSubHeader?.forEach((it, index) => {
      dataSum.sumC4_1.push({ value: "0" })
    })
    dataSum.sumC6.length == 0 && storeData?.nyukinki?.overviewData?.incomeHeader?.forEach((rw, index) => {
      dataSum.sumC6.push({ value: "0" })
    })
    storeData?.nyukinki?.overviewData?.overViewTableTwo?.forEach((row, index) => {
      dataSum.sumC0 = (Number(dataSum.sumC0) + Number(storeData?.nyukinki?.overviewData?.overViewTableTwo[index]?.offPayment.totalSettlementAmount ?? "0")).toString();
      dataSum.sumC1 = (Number(dataSum.sumC1) + Number(storeData?.nyukinki?.overviewData?.overViewTableTwo[index]?.creditCard.totalSettlementAmount ?? "0")).toString();
      dataSum.sumC2 = (Number(dataSum.sumC2) + Number(row.cashTotal)).toString();
      dataSum.sumC3 = (Number(dataSum.sumC3) + Number(row.kabusoku)).toString();
      dataSum.sumC4 = (
        Number(dataSum.sumC4) + Number(row.genkingai[0].goukei)
      ).toString();
      dataSum.sumC5 = (
        Number(dataSum.sumC5) + Number(row.income[0].goukei)
      ).toString();
      dataSum.sumC7 = (
        Number(dataSum.sumC7) + Number(row.koguchiReorient[0].goukei)
      ).toString();
      dataSum.sumC11 = (Number(dataSum.sumC2) + Number(dataSum.sumC3) + Number(dataSum.sumC4) + Number(dataSum.sumC5) + Number(dataSum.sumC7)).toString();
      row.genkingai[0].meisai.forEach((e, index) => {
        if (dataSum.sumC4_1[index]) {
          dataSum.sumC4_1[index].value = (
            Number(dataSum.sumC4_1[index].value) + Number(e.value)
          ).toString();
        } else {
          dataSum.sumC4_1[index] = { value: Number(e.value).toString() };
        }
      });
      row.income[0].meisai.forEach((e, index) => {
        if (dataSum.sumC6[index]) {
          dataSum.sumC6[index].value = (
            Number(dataSum.sumC6[index].value) + Number(e.value)
          ).toString();
        } else {
          dataSum.sumC6[index] = { value: Number(e.value).toString() };
        }
      });
      row.koguchiReorient[0].meisai.forEach((e, index) => {
        if (dataSum.sumC8[index]) {
          dataSum.sumC8[index].value = (
            Number(dataSum.sumC8[index].value) + Number(e.value)
          ).toString();
        } else {
          dataSum.sumC8[index] = { value: Number(e.value).toString() };
        }
      });
    });
    setDataSum({ ...dataSum });
  }, []);


  const [cashOutSalesFlg, setCashOutSalesFlg] = useState(true);
  const [miscellaneousIncomeFlg, setMiscellaneousIncomeFlg] = useState(true);
  const [smallAdvancePaymentFlg, setSmallAdvancePaymentFlg] = useState(true);
  const [advancePaymentFlg, setAdvancePaymentFlg] = useState(true);

  function computeWidthPercent() {
    let cashOutSalesWidth = cashOutSalesFlg
      ? (storeData?.nyukinki?.overviewData?.overViewTableTwoSubHeader?.length + 1) * 141
      : 141;
    let miscellaneousIncomeWidth = miscellaneousIncomeFlg
      ? (storeData?.nyukinki?.overviewData?.incomeHeader?.length + 1 + 1) * 141
      : 141;
    // let smallAdvancePaymentWidth = smallAdvancePaymentFlg
    //   ? (storeData?.nyukinki?.overviewData?.overViewTableTwo[0]?.koguchiReorient[0]?.meisai?.length + 1) * 141
    //   : 141;
    let smallAdvancePaymentWidth = 141;

    return (
      (
        ((cashOutSalesWidth +
          miscellaneousIncomeWidth +
          smallAdvancePaymentWidth +
          1600) /
          1040) *
        100
      ).toFixed(3) + "%"
    );
  }

  function computeOutsideCommoditySalesWidth() {
    let miscellaneousIncomeWidth = miscellaneousIncomeFlg
      ? storeData?.nyukinki?.overviewData?.overViewTableTwo?.length > 0 ? storeData?.nyukinki?.overviewData?.overViewTableTwo[0]?.income[0].meisai?.length + 1
        : storeData?.nyukinki?.overviewData?.incomeHeader?.length + 1 : 1;
    let smallAdvancePaymentWidth = smallAdvancePaymentFlg
      ? storeData?.nyukinki?.overviewData?.overViewTableTwo?.length > 0 ? storeData?.nyukinki?.overviewData?.overViewTableTwo[0]?.koguchiReorient[0].meisai?.length + 1
        : 1 : 1;
    return (
      miscellaneousIncomeWidth + smallAdvancePaymentWidth
    );
  }

  const [tcDialogVisible, setTCDialogVisible] = useState(false);
  const [readOnlyTCFlag, setReadOnlyTCFlag] = useState(true);
  function openTCDialog(index) {
    setDialogTCIndex(index);
    setTCDialogVisible(true);
  }
  function closeTCDialog() {
    setTCDialogVisible(false);
  }

  const [ofdialogVisible, setOFDialogVisible] = useState(false);
  const [readOnlyOFFlag, setReadOnlyOFFlag] = useState(true);
  function openOFDialog(index) {
    setDialogOFIndex(index);
    setOFDialogVisible(true);
  }
  function closeOFDialog() {
    setOFDialogVisible(false);
  }
  return (
    <>
      <OffCreditDialog
        dialogVisible={ofdialogVisible}
        closeDialog={closeOFDialog}
        dialogIndex={dialogOFIndex}
        storeData={storeData}
        offCreditMaster={offCreditMaster}
        readOnlyFlag={readOnlyOFFlag}
      />
      <TenantCreditDialog
        dialogVisible={tcDialogVisible}
        closeDialog={closeTCDialog}
        dialogIndex={dialogTCIndex}
        storeData={storeData}
        tenantCreditMaster={tenantCreditMaster}
        readOnlyTCFlag={readOnlyTCFlag}
      />
      <Table
        style={{
          width: computeWidthPercent(),
          tableLayout: "fixed",
        }}
      >
        <TableHead>
          <TableRow>
            <StyledTableCell
              align="center"
              style={{ width: "180px" }}
              className={classes.firstHeadRow}
            >
              レジNo.
            </StyledTableCell>
            <StyledTableCell align="left" className={classes.firstHeadRow} style={{ width: "180px" }}>
              オフ決済
            </StyledTableCell>
            <StyledTableCell align="left" className={classes.firstHeadRow} style={{ width: "180px" }}>
              テナントクレジット
            </StyledTableCell>
            <StyledTableCell
              colSpan={cashOutSalesFlg ? storeData?.nyukinki?.overviewData?.overViewTableTwoSubHeader?.length > 0 ? storeData?.nyukinki?.overviewData?.overViewTableTwoSubHeader?.length + 3 : 3 : 3}
              align="left"
              className={classes.firstHeadRow}
            >
              商品売上
            </StyledTableCell>
            <StyledTableCell
              colSpan={computeOutsideCommoditySalesWidth()}
              align="left"
              className={classes.firstHeadRow}
            >
              商品外売上
            </StyledTableCell>
            <StyledTableCell align="left" className={classes.firstHeadRow}>
              総売計
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell style={{ borderRight: "1px solid #CCCCCC", borderLeft: "1px solid #CCCCCC" }} />
            <StyledTableCell style={{ borderRight: "1px solid #CCCCCC" }} />
            <StyledTableCell style={{ borderRight: "1px solid #CCCCCC" }} />
            <StyledTableCell align="left" className={classes.secondHeadRow}>
              現金計
            </StyledTableCell>
            <StyledTableCell align="left" className={classes.secondHeadRow}>
              過不足
            </StyledTableCell>
            <StyledTableCell align="left" className={classes.secondHeadRow}>
              <span style={{ float: "left" }}>現金外売上</span>
              {cashOutSalesFlg ? (
                <IndeterminateCheckBoxIcon
                  className={classes.iconClass}
                  onClick={() => {
                    setCashOutSalesFlg(false);
                  }}
                ></IndeterminateCheckBoxIcon>
              ) : (
                <AddBoxIcon
                  className={classes.iconClass}
                  onClick={() => {
                    setCashOutSalesFlg(true);
                  }}
                ></AddBoxIcon>
              )}
            </StyledTableCell>
            {cashOutSalesFlg &&
              storeData?.nyukinki?.overviewData?.overViewTableTwoSubHeader?.map((items, index) => (
                <StyledTableCell
                  key={index}
                  align="left"
                  style={{ color: "#888888" }}
                  className={classes.secondHeadRow}
                >
                  {items}
                </StyledTableCell>
              ))}
            <StyledTableCell align="left" className={classes.secondHeadRow}>
              <span style={{ float: "left" }}>雑収入</span>
              {miscellaneousIncomeFlg ? (
                <IndeterminateCheckBoxIcon
                  className={classes.iconClass}
                  onClick={() => {
                    setMiscellaneousIncomeFlg(false);
                  }}
                ></IndeterminateCheckBoxIcon>
              ) : (
                <AddBoxIcon
                  className={classes.iconClass}
                  onClick={() => {
                    setMiscellaneousIncomeFlg(true);
                  }}
                ></AddBoxIcon>
              )}
            </StyledTableCell>
            {miscellaneousIncomeFlg &&
              storeData?.nyukinki?.overviewData?.incomeHeader?.map((items, index) => (
                <StyledTableCell
                  key={index}
                  align="left"
                  style={{ color: "#888888" }}
                  className={classes.secondHeadRow}
                >
                  {items}
                </StyledTableCell>
              ))}
            <StyledTableCell align="left" className={classes.secondHeadRow}>
              <span style={{ float: "left" }}>小口現金</span>
            </StyledTableCell>
            {smallAdvancePaymentFlg &&
              storeData?.nyukinki?.overviewData?.overViewTableTwo[0]?.koguchiReorient[0]?.meisai.map((items, index) => (
                <StyledTableCell
                  key={index}
                  align="left"
                  style={{ color: "#888888" }}
                  className={classes.secondHeadRow}
                >
                  {items.key}
                </StyledTableCell>
              ))}
            <StyledTableCell style={{ borderRight: "1px solid #CCCCCC" }} />
          </TableRow>
          {storeData?.nyukinki?.overviewData?.overViewTableTwo?.length > 0 && storeData?.nyukinki?.overviewData?.overViewTableTwo?.map((rows, index) => (
            <TableRow key={index}>
              <StyledTableCell
                align="center"
                style={{ fontWeight: "700", borderLeft: "1px solid #CCCCCC" }}
                className={classes.cell}
              >
                {rows.rejino}
              </StyledTableCell>
              <StyledTableCell align="right" className={classes.cell}>
                < InsertDriveFile
                  onClick={() => openOFDialog(index, true)}
                  style={{
                    color: "#AAAAAA",
                    cursor: "pointer",
                    float: "left",
                    display: Number(storeData?.nyukinki?.overviewData?.overViewTableTwo[index]?.offPayment?.totalSettlementAmount) == 0 ? "none" : "",
                  }}
                />
                <NumberFormat
                  value={storeData?.nyukinki?.overviewData?.overViewTableTwo[index]?.offPayment?.totalSettlementAmount ?? "0"}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </StyledTableCell>
              <StyledTableCell align="right" className={classes.cell}>
                < InsertDriveFile
                  onClick={() => openTCDialog(index, true)}
                  style={{
                    color: "#AAAAAA",
                    cursor: "pointer",
                    float: "left",
                    display: Number(storeData?.nyukinki?.overviewData?.overViewTableTwo[index]?.creditCard?.totalSettlementAmount) == 0 ? "none" : "",
                  }}
                />
                <NumberFormat
                  value={storeData?.nyukinki?.overviewData?.overViewTableTwo[index]?.creditCard?.totalSettlementAmount ?? "0"}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </StyledTableCell>
              <StyledTableCell align="right" className={classes.cell}>
                <NumberFormat
                  value={parseInt(rows.cashTotal)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </StyledTableCell>
              <StyledTableCell align="right" className={classes.cell}>
                <NumberFormat
                  value={parseInt(rows.kabusoku)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </StyledTableCell>
              <StyledTableCell align="right" className={classes.cell}>
                <NumberFormat
                  value={rows.genkingai[0].goukei}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </StyledTableCell>
              {cashOutSalesFlg &&
                storeData?.nyukinki?.overviewData?.overViewTableTwo[index]?.genkingai[0]?.meisai?.map((item, yIndex) => (
                  <StyledTableCell
                    key={yIndex}
                    align="right"
                    className={classes.cell}
                  >
                    <NumberFormat
                      value={item?.value ?? 0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </StyledTableCell>
                ))}
              <StyledTableCell align="right" className={classes.cell}>
                <NumberFormat
                  value={rows.income[0].goukei}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </StyledTableCell>
              {miscellaneousIncomeFlg &&
                storeData?.nyukinki?.overviewData?.overViewTableTwo[index]?.income[0]?.meisai?.map((item, index) => (
                  <StyledTableCell
                    key={index}
                    align="right"
                    className={classes.cell}
                  >
                    <NumberFormat
                      value={item?.value ?? 0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </StyledTableCell>
                ))}


              <StyledTableCell align="right" className={classes.cell}>
                <NumberFormat
                  value={rows.koguchiReorient[0].goukei}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </StyledTableCell>
              {smallAdvancePaymentFlg &&
                storeData?.nyukinki?.overviewData?.overViewTableTwo[index]?.koguchiReorient[0]?.meisai?.map((item, index) => (
                  <StyledTableCell
                    key={index}
                    align="right"
                    className={classes.cell}
                  >
                    <NumberFormat
                      value={item?.value ?? 0}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </StyledTableCell>
                ))}

              <StyledTableCell align="right" className={classes.cell}>
                <NumberFormat
                  value={((parseInt(rows.koguchiReorient[0].goukei) ?? 0) + (parseInt(rows.income[0].goukei) ?? 0) + (parseInt(rows.genkingai[0].goukei) ?? 0) + (parseInt(rows.kabusoku) ?? 0) + (parseInt(rows.cashTotal)) ?? 0)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </StyledTableCell>
            </TableRow>
          ))}

          <TableRow>
            <StyledTableCell
              align="center"
              style={{ fontWeight: "700", borderLeft: "1px solid #CCCCCC" }}
              className={classes.endCell}
            >
              合計
            </StyledTableCell>
            <StyledTableCell align="right" className={classes.endCell}>
              <NumberFormat
                value={dataSum.sumC0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </StyledTableCell>
            <StyledTableCell align="right" className={classes.endCell}>
              <NumberFormat
                value={dataSum.sumC1}
                displayType={"text"}
                thousandSeparator={true}
              />
            </StyledTableCell>
            <StyledTableCell align="right" className={classes.endCell}>
              <NumberFormat
                value={parseInt(dataSum.sumC2) ?? 0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </StyledTableCell>
            <StyledTableCell align="right" className={classes.endCell}>
              <NumberFormat
                value={parseInt(dataSum.sumC3) ?? 0}
                displayType={"text"}
                thousandSeparator={true}
              />
            </StyledTableCell>
            <StyledTableCell align="right" className={classes.endCell}>
              <NumberFormat
                value={dataSum.sumC4}
                displayType={"text"}
                thousandSeparator={true}
              />
            </StyledTableCell>
            {cashOutSalesFlg &&
              dataSum.sumC4_1.map((item, index) => (
                <StyledTableCell
                  key={index}
                  align="right"
                  className={classes.endCell}
                >
                  <NumberFormat
                    value={item?.value ?? "0"}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </StyledTableCell>
              ))}
            <StyledTableCell align="right" className={classes.endCell}>
              <NumberFormat
                value={dataSum.sumC5}
                displayType={"text"}
                thousandSeparator={true}
              />
            </StyledTableCell>
            {miscellaneousIncomeFlg &&
              dataSum.sumC6.map((item, index) => (
                <StyledTableCell
                  key={index}
                  align="right"
                  className={classes.endCell}
                >
                  <NumberFormat
                    value={item?.value ?? "0"}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </StyledTableCell>
              ))}
            <StyledTableCell align="right" className={classes.endCell}>
              <NumberFormat
                value={dataSum.sumC7}
                displayType={"text"}
                thousandSeparator={true}
              />
            </StyledTableCell>
            {smallAdvancePaymentFlg &&
              dataSum.sumC8.map((item, index) => (
                <StyledTableCell
                  key={index}
                  align="right"
                  className={classes.endCell}
                >
                  <NumberFormat
                    value={item.value}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </StyledTableCell>
              ))}
            {advancePaymentFlg &&
              dataSum.sumC10.map((item, index) => (
                <StyledTableCell
                  key={index}
                  align="right"
                  className={classes.endCell}
                >
                  <NumberFormat
                    value={item.value}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </StyledTableCell>
              ))}
            <StyledTableCell align="right" className={classes.endCell}>
              <NumberFormat
                value={dataSum.sumC11}
                displayType={"text"}
                thousandSeparator={true}
              />
            </StyledTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}


// 画面エクスポート
export default withStyles(styles, { withTheme: true })(DetailTableTwo);
