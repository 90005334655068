import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { RouterString } from "../../../global/common/constants";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NumberFormat from "react-number-format";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import { getUnAuthorizedStoresAPI, updateAuthorizeStatusListAPI } from "../../../network/api";
import { useMounted } from "../../../global/function/useMounted";
import BaseCircularProgress from "../../../global/component/basic/BaseCircularProgress";
import { useNavigate } from "react-router-dom";
import { localConstants, Error } from "../../../global/common/constants";

// 画面スタイル
const styles = (theme) => ({
  alert: {
    backgroundColor: "#C3E9F1",
    color: "#007A95",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "16px",
    // position: "fixed",
    // top: "48px",
    // height: "48px",
  },
  headRow: {
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "150%",
    padding: "8px",
  },
  cell: {
    width: "140px",
    borderRight: "1px solid #CCCCCC",
    fontFamily: "Noto Sans",
    fontStyle: "normal",
    color: "#162337",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "8px",
  },
  button: {
    height: "48px",
    width: "208px",
    margin: "16px",
    fontSize: "16px",
    fontWeight: "700",
    color: "#FFFFFF",
    backgroundColor: "#EF7A00",
    "&:enabled:hover": {
      backgroundColor: "#EF7A00",
      color: "#fff",
    },
  },
  blackUnderline: {
    color: "#162337",
    textDecoration: "underline",
    textDecorationColor: "#162337",
    cursor: "pointer",
  },
  redUnderline: {
    color: "#E60024",
    textDecoration: "underline",
    textDecorationColor: "#E60024",
    cursor: "pointer",
  },
  disabledButton: {
    height: "48px",
    width: "208px",
    margin: "16px",
    fontSize: "16px",
    fontWeight: "700",
    backgroundColor: "#CCCCCC",
    color: "#AAAAAA",
    "&:enabled:hover": {
      backgroundColor: "#CCCCCC",
      color: "#AAAAAA",
    },
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#162337",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "16px",
    backgroundColor: "#FFFFFF",
  },
}));
let timeout; //定时器

// 日報提出状況画面入り口
function UnauthorizedStores(props) {
  const { classes, setSelectedTab,
    accountingData,
    setAccountingData } = props;
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState();
  const isMounted = useMounted()
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  function handleCheckAll() {
    setData(
      data.map((e) => {
        e.checked = !checkAll;
        return e;
      })
    );
    setCheckAll(!checkAll);
  }

  function handleCheck(value, index) {
    data[index].checked = value;
    setData([...data]);
  }

  function toCompareBorrow(rows) {
    setAccountingData({
      ...accountingData,
      storeId: rows.storeId,
      storeName: rows.storeName,
      totalDebit: rows.totalDebit,
      totalCredit: rows.totalCredit,
      approvalStatus: rows.approvalStatus,
      errMsg: rows.errMsg,
      lenders: [],
      debits: [],
      nyukinki: null,
      pos: null,
      tempSalesRate: 0
    });
    navigate(RouterString.CompareBorrow);
  }

  function debounce() {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  }
  const getUnAuthorizedStoresData = () => {
    if (!localStorage.getItem(localConstants.BusinessDay)) {
      navigate(RouterString.ReportStatus)
      return
    }
    setIsLoading(true);
    let param = {
      businessDay: localStorage.getItem(localConstants.BusinessDay)
    }
    getUnAuthorizedStoresAPI(param).then((res) => {
      if (!isMounted()) return
      let resData = res.data
      if (res.status >= 400 && res.status < 500) {
        alert(Error.E0003);
      } else if (res.status == 200) {
        resData.forEach(item => {
          item["checked"] = false
        })
        resData.sort(up);
        setData(resData)
        if (resData?.length > 0) {
          setShowMessage(false)
        } else {
          setShowMessage(true)
          setMessage("未承認の店舗がありません。")
        }
      }
      setIsLoading(false)
    }).catch((err) => {
      if (!isMounted()) return
      setIsLoading(false)
    })
  }
  function up(a, b) {
    return a.storeId - b.storeId
  }
  const authorizeStores = () => {
    let param = {}
    let body = []
    data.forEach(item => {
      if (item.checked) {
        body.push({
          businessDay: localStorage.getItem(localConstants.BusinessDay),
          approvalStatus: "2",
          storeId: item.storeId,
          totalCredit: item.totalCredit ? item.totalCredit : 0,
          totalDebit: item.totalDebit ? item.totalDebit : 0,
          mail: localStorage.getItem(localConstants.Email)
        })
      }
    })
    if (body?.length == 0) {
      return
    }
    param["data"] = body
    console.log(param)
    setIsLoading(true);
    updateAuthorizeStatusListAPI(param).then((res) => {
      if (!isMounted()) return
      let resData = res.data
      if (res.status >= 400 && res.status < 500) {
        alert(Error.E0003);
      } else if (res.status == 200) {
        setMessage("チェックした店舗を承認しました。")
        setShowMessage(true);
        // debounce();
        getUnAuthorizedStoresData()
      }
      setIsLoading(false)
    }).catch((err) => {
      if (!isMounted()) return
      setIsLoading(false)
    })
  }
  useEffect(() => {
    if (!localStorage.getItem(localConstants.BusinessDay)) {
      navigate(RouterString.ReportStatus)
      return
    }
    setCheckAll(false)
    setSelectedTab(RouterString.UnauthorizedStores);
    getUnAuthorizedStoresData()
  }, [setSelectedTab]);
  return (
    <>
      {isLoading && <BaseCircularProgress />}
      <div
        style={{
          marginLeft: "25px",
          marginTop: "25px",
          fontFamily: "Noto Sans JP",
          fontStyle: "Bold",
          fontWeight: 700,
          fontSize: "24px",
          lineHeight: "150%",
          color: "#162337",
        }}
      >
        未承認店舗
      </div>
      {showMessage && (
        <Alert
          severity="info"
          className={classes.alert}
          style={{ width: "100%" }}
        >
          {message}
        </Alert>
      )}
      {data?.length > 0 && <Table style={{ margin: "24px", width: "calc(100% - 48px)" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell
              align="center"
              className={classes.headRow}
              style={{ width: "15%" }}
            >
              店舗コード
            </StyledTableCell>
            <StyledTableCell
              className={classes.headRow}
              align="center"
              style={{ width: "15%" }}
            >
              店舗名
            </StyledTableCell>
            <StyledTableCell
              className={classes.headRow}
              align="center"
              style={{ width: "15%" }}
            >
              借方合計
            </StyledTableCell>
            <StyledTableCell
              className={classes.headRow}
              align="center"
              style={{ width: "15%" }}
            >
              貸方合計
            </StyledTableCell>
            <StyledTableCell
              className={classes.headRow}
              align="center"
              style={{ width: "15%" }}
            >
              承認
            </StyledTableCell>
            <StyledTableCell
              className={classes.headRow}
              align="center"
              style={{ width: "25%" }}
            >
              エラー
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <StyledTableCell
              style={{
                borderRight: "1px solid #CCCCCC",
                borderLeft: "1px solid #CCCCCC",
              }}
            />
            <StyledTableCell style={{ borderRight: "1px solid #CCCCCC" }} />
            <StyledTableCell style={{ borderRight: "1px solid #CCCCCC" }} />
            <StyledTableCell style={{ borderRight: "1px solid #CCCCCC" }} />
            <StyledTableCell
              align="center"
              style={{
                borderRight: "1px solid #CCCCCC",
                padding: "8px",
                fontWeight: "400",
              }}
            >
              全選択
              <Checkbox
                checked={checkAll}
                onChange={handleCheckAll}
                color="orange"
                style={{ padding: "0", margin: "0 0 2px 5px" }}
              />
            </StyledTableCell>
            <StyledTableCell style={{ borderRight: "1px solid #CCCCCC" }} />
          </TableRow>
          {data?.length > 0 && data?.map((rows, index) => (
            <TableRow key={index}>
              <StyledTableCell
                align="left"
                className={classes.cell}
                style={{ borderLeft: "1px solid #CCCCCC" }}
              >
                {rows.storeId}
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                <span
                  className={rows.totalDebit != rows.totalCredit ? classes.redUnderline
                    : classes.blackUnderline}
                  onClick={() => {
                    toCompareBorrow(rows)
                  }}
                >
                  {rows.storeName}
                </span>
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                <NumberFormat
                  value={rows.totalDebit}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.cell}>
                <NumberFormat
                  value={rows.totalCredit}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.cell}>
                <Checkbox
                  checked={rows.checked}
                  onChange={(e, result) => handleCheck(result, index)}
                  color="orange"
                  style={{ padding: "0" }}
                />
              </StyledTableCell>
              <StyledTableCell
                align="left"
                className={classes.cell}
                style={{ color: "#E60024" }}
              >
                {rows.errMsg}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>}
      {
        data?.length > 0 && <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="orange"
            className={classes.button}
            onClick={() => {
              authorizeStores()
            }}
          >
            チェックした店舗を承認
          </Button>
        </div>
      }
    </>
  );
}

export default withStyles(styles, { withTheme: true })(UnauthorizedStores);
