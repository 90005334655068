import React, { useEffect, useState, Fragment } from "react";
import {
    Box,
    Typography
} from "@mui/material";
import { withStyles } from "@mui/styles";
import MessageFunction from "../mountedWindow/MessageFunction";
import { onEventListener, offEventListener, customEventName } from "../../global/function/customEvent";

import { useMounted } from "../../global/function/useMounted";


import AccountingRouters from "../../routes/AccountingRouters";
import AccountingMenu from "../../global/component/pages/AccountingMenu";
import Settings from '@mui/icons-material/Settings';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { useNavigate } from "react-router-dom";
import { RouterString } from "../../global/common/constants";
import util from "../../global/common/util"
import { localConstants, CognitoLoginUrl } from "../../global/common/constants";
import ValidatingCodeDialog from "../../global/component/pages/ValidatingCodeDialog";

const styles = (theme) => ({

});

// トップ画面入り口
function Accounting(props) {

    // 定数定義
    const {
        classes,
    } = props
    const isMounted = useMounted()
    const [accountingData, setAccountingData] = useState({})
    const [selectedTab, setSelectedTab] = useState("/accounting")
    const [messageOpen, setMessageOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [validatingCodeDialog, setValidatingCodeDialog] = useState(false);
    const [validatingCodeDialogVisiable, setValidatingCodeDialogVisiable] = useState(false);

    const navigate = useNavigate()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const logout = () => {
        localStorage.clear()
        setAnchorEl(null);
        window.location.href = CognitoLoginUrl.logoutUrl
        // navigate("/")
    };
    const transitToMaster = () => {
        // setValidatingCodeDialogVisiable(false);
        navigate(RouterString.Master)

    };
    const handleCloseSnackBar = () => {
        setMessageOpen(false)
    }
    // 画面初期化処理
    useEffect(() => {
        console.log(accountingData)
        if (util.getUrlParam("expiredTime")) {
            localStorage.setItem(localConstants.ExpiredTime, util.getUrlParam("expiredTime"))
            localStorage.setItem(localConstants.Email, util.getUrlParam("email"))
            localStorage.setItem(localConstants.UserAccess, util.getUrlParam("userAccess"))
            localStorage.setItem(localConstants.StoreId, util.getUrlParam("storeCode"))
            localStorage.setItem(localConstants.StoreName, util.getUrlParam("storeName"))
            localStorage.setItem(localConstants.LastName, util.getUrlParam("lastName"))
            localStorage.setItem(localConstants.FirstName, util.getUrlParam("firstName"))
            var url = window.location.href;
            navigate(RouterString.Accounting)
        }
        if (!localStorage.getItem(localConstants.ExpiredTime)) {
            navigate(RouterString.Login)
            return
        }
        if (!localStorage.getItem(localConstants.UserAccess)) {
            navigate(RouterString.Login)
            return
        }
        if (parseInt(localStorage.getItem(localConstants.ExpiredTime)) < parseInt(new Date().getTime() / 1000)) {
            navigate(RouterString.Login)
            return
        }
        if (parseInt(localStorage.getItem(localConstants.UserAccess)) == 0) {
            navigate(RouterString.Login)
            return
        }
        //  else if (parseInt(localStorage.getItem(localConstants.UserAccess)) == 2) {
        //     navigate(RouterString.Master)
        //     return
        // }
        const showMessage = ({ detail }) => {
            setMessage(detail.message)
            setMessageOpen(true)
        }
        onEventListener(customEventName.GlobalMessageTip, showMessage)
        return () => {
            offEventListener(customEventName.GlobalMessageTip, showMessage)
        }
    }, [accountingData])


    // 画面表示
    return (
        <Box style={{
            height: '100vh'
        }}>
            {/* <ValidatingCodeDialog validatingCodeDialogVisiable={validatingCodeDialogVisiable} closeValidatingCodeDialog={transitToMaster} setValidatingCodeDialogVisiable={setValidatingCodeDialogVisiable} /> */}
            <Box sx={{ display: 'flex' }}>

                {/* 左側メニュー表示 */}
                <AccountingMenu
                    selectedTab={selectedTab}
                    accountingData={accountingData}
                    setAccountingData={setAccountingData}
                />
                <div style={{
                    display: "absolute",
                    width: "100%",
                }}>

                    {/* タイトルバー */}
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right",
                        width: "100%",
                        height: "6.667%",
                        backgroundColor: "#FFFFFF"

                    }}>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: "#162337",
                                marginRight: "16px"
                            }}
                        >
                            {localStorage.getItem(localConstants.StoreName)}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: "#162337",
                                marginRight: "24px"
                            }}
                        >
                            {localStorage.getItem(localConstants.LastName) + " " + localStorage.getItem(localConstants.FirstName) + "さん"}
                        </Typography>
                        <Settings style={{ color: "#162337", width: 20, height: 20, marginRight: "24px", cursor: "pointer" }} onClick={handleClick} />
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            autoFocus={false}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    setAnchorEl(null);
                                    transitToMaster()
                                    // setValidatingCodeDialogVisiable(true)

                                }}>
                                <ArrowRight style={{ color: "#162337", width: 16, height: 16, marginRight: "8px", marginLeft: "8px" }} />
                                <Typography style={{
                                    color: "#162337", fontWeight: '400',
                                    fontSize: '14px', marginRight: "8px"
                                }} >
                                    マスター管理へ移動する
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={logout}>
                                <ArrowRight style={{ color: "#162337", width: 16, height: 16, marginRight: "8px", marginLeft: "8px" }} />
                                <Typography style={{
                                    color: "#162337", fontWeight: '400',
                                    fontSize: '14px', marginRight: "8px"
                                }} >
                                    ログアウト
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </div>

                    {/* 選択したメニューに従い右側メイン画面表示切替 */}
                    <AccountingRouters
                        setSelectedTab={setSelectedTab}
                        accountingData={accountingData}
                        setAccountingData={setAccountingData}
                    /></div>

            </Box>

            {/* グローバルメッセージ処理 */}
            <MessageFunction
                open={messageOpen}
                message={message}
                handleCloseSnackBar={handleCloseSnackBar}
            />
        </Box >
    )
}

// 画面エクスポート
export default withStyles(styles, { withTheme: true })(Accounting);