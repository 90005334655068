import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import ScreenAdapter from"./global/component/screenAdapter/ScreenAdapter";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

window.alert = function (str) {
  var alertFram = document.createElement("DIV");
  alertFram.id = "alertFram";
  alertFram.style.position = "absolute";
  alertFram.style.left = "50%";
  alertFram.style.top = "0";
  alertFram.style.marginLeft = "-235px";
  alertFram.style.width = "470px";
  alertFram.style.height = "115px";
  alertFram.style.textAlign = "center";
  alertFram.style.lineHeight = "115px";
  alertFram.style.zIndex = "3000";
  alertFram.style.fontFamily = "Noto Sans"
  let strHtml = '<div style="border-radius: 2px;background:#fff;border: 1px solid #AAAAAA;">\n';
  strHtml +=
    ' <div style="text-align:center;font-size:14px;height:70px;line-height:70px;">' +
    str +
    "</div>\n";
  strHtml +=
    ' <div style="display:flex;justify-content:end;line-height:0"><input type="button" value="OK" style="margin:0 10px 10px;background:#EF7A00;cursor: pointer;color: #fff;border-radius: 4px;border: 0px;width: 70px;height: 30px" id="confirmMessage"/></div>\n';
  strHtml += "</div>\n";
  alertFram.innerHTML = strHtml;
  document.body.appendChild(alertFram);
  document.getElementById("confirmMessage").addEventListener("click", function () {
    document.body.removeChild(alertFram)
  })
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
