import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { jaJP } from "@mui/material/locale";

// colors
const primary = "#162337";
const topBarBg = "#0052cc";
const menuGray = "#757575";
const gray54 = "#545454"
const gray9E = "#9E9E9E"
const grayEE = "#EEEEEE"
const grayBA = "#BABABA"
const grayF5 = "#F5F5F5"

const blue91 = "#91bdff"
const redEA = "#ea0000"
const redCC = "#CC0000"
const blue00 = "#0052cc"
const blueEE = "#EEF2FF"
const green13 = "#13a221"

const secondary = "#1964FE";
const orange = "#EF7A00";
const textBlack = "#162337";
const black = "#333333"// "#545454";
const darkBlack = "rgb(36, 40, 44)";
const background = "#F4F4F4";
const warningLight = "rgba(253, 200, 69, .3)";
const warningMain = "rgba(253, 200, 69, .5)";
const warningDark = "rgba(253, 200, 69, .7)";

// border
const borderWidth = 0;
const borderColor = "#C5C5C5";

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createTheme(
    {
        palette: {
            primary: { main: primary },
            secondary: { main: secondary },
            orange: { main: orange },
            topBarBg: { main: topBarBg },
            menuGray: { main: menuGray },
            white: "#FFFFFF",
            gray: {
                gray54: gray54,
                gray9E: gray9E,
                grayEE: grayEE,
                grayBA: grayBA,
                grayF5: grayF5
            },
            blue: {
                blue91: blue91,
                blue00: blue00,
                blueEE: blueEE
            },
            red: {
                redEA: redEA,
                redCC: redCC
            },
            green: {
                green13: green13,
            },
            common: {
                black,
                darkBlack,
            },
            text: {
                textBlack
            },
            skillIconColors: {
                default: "#e5e5e5",
                category: "#13a221",
                pictureInPictureAlt: "#e521af",
                dashboard: "#2f83ff",
                palette: "#BA2FFF",
            },
            warning: {
                light: warningLight,
                main: warningMain,
                dark: warningDark,
            },
            // Used to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,
            background: {
                default: background,
            },
            spacing,
        },
        breakpoints: {
            // Define custom breakpoint values.
            // These will apply to Material-UI components that use responsive
            // breakpoints, such as `Grid` and `Hidden`. You can also use the
            // theme breakpoint functions `up`, `down`, and `between` to create
            // media queries for these breakpoints
            values: {
                xl,
                lg,
                md,
                sm,
                xs,
            },
        },
        border: {
            borderColor: borderColor,
            borderWidth: borderWidth,
        },
        overrides: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        backgroundColor: "white",
                        fontWeight: 500
                    }
                }
            },
            MuiExpansionPanel: {
                root: {
                    position: "static",
                },
            },
            MuiTableCell: {
                root: {
                    paddingTop: 3,
                    paddingBottom: 3,
                    paddingLeft: spacing * 2,
                    paddingRight: spacing * 2,
                    [`@media (max-width:  ${sm}px)`]: {
                        paddingLeft: spacing,
                        paddingRight: spacing,
                    },
                    border: `${borderWidth}px solid ${borderColor}`,
                },
            },
            MuiDivider: {
                root: {
                    backgroundColor: borderColor,
                    height: borderWidth,
                },
            },
            MuiPrivateNotchedOutline: {
                root: {
                    borderWidth: borderWidth,
                },
            },
            MuiListItem: {
                divider: {
                    borderBottom: `${borderWidth}px solid ${borderColor}`,
                },
            },
            MuiDialog: {
                container: {
                    height: "auto",
                },
                paper: {
                    width: "100%",
                    maxWidth: 640,
                    marginLeft: spacing,
                    marginRight: spacing,
                },
            },
            MuiTooltip: {
                tooltip: {
                    backgroundColor: darkBlack,
                },
            },
            MuiExpansionPanelDetails: {
                root: {
                    [`@media (max-width:  ${sm}px)`]: {
                        paddingLeft: spacing,
                        paddingRight: spacing,
                    },
                },
            },
            MuiTextField: {
                root: {
                    marginTop: spacing,
                },
            },
            MuiInputBase: {
                root: {
                    fontFamily: ['Noto Sans JP', 'sans-serif'].join(),
                    fontWeight: 500
                }
            },
            MuiBackdrop: {
                root: {
                    backgroundColor: "rgba(0, 0, 0, 0.75)",
                },
            },
            MuiTableRow: {
                root: {
                    height: 34,
                },
            },
            MuiCheckbox: {
                root: {

                },

            }
        },
        typography: {
            useNextVariants: true,
            fontFamily: ['Noto Sans JP', 'sans-serif'].join(),  // `"Chilanka","Noto Sans JP", "Helvetica", "Arial", sans-serif`,
            fontSize: 12,
            body2: {
                fontWeight: 500,
                fontSize: "14px"
            },
            body1: {
                fontWeight: 500
            },
            subtitle1: {
                fontWeight: 400,
                fontSize: "16px"
            },
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
        },
    },
    jaJP
);

export default responsiveFontSizes(theme, "breakpoints");
